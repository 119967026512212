.classphoto {
  overflow-x: hidden;
  padding: 0;
  .visual {
    width: 100%;
    height: 536px;
    padding: 16px 0 0;
    background: url(../image/classphoto/visual.png) center center / cover no-repeat;
    .logo {
      margin: 0 0 50px;
    }
  }

  .banner-box {
    background: #ffd600;
    padding: 30px;
    .inner {
      background: #fff;
      padding: 30px 0;
      border-radius: 10px;
      text-align: center;
      position: relative;
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: -10px;
      }
      &::before {
        width: 131px;
        height: 84px;
        left: -36px;
        background: url(../image/classphoto/banner-illust01.png) center center / cover no-repeat;
      }
      &::after {
        width: 143px;
        height: 133px;
        right: -20px;
        background: url(../image/classphoto/banner-illust02.png) center center / cover no-repeat;
      }
    }
    &__title {
      @include font-size(3.7);
      color: #67391a;
      font-weight: bold;
    }
    &__text {
      margin: 0 0 24px;
      @include font-size(1.8);
      color: #67391a;
      font-weight: bold;
    }
    &__button {
      display: block;
      background: #67391a;
      width: 694px;
      height: 88px;
      margin: auto;
      border-radius: 88px;
      border-bottom: 4px solid #c87270;
      border-top: 0px solid #c87270;
      border-right: 4px solid #c87270;
      background: #ef9593;
      color: #fff;
      @include font-size(3.2);
      letter-spacing: 0.025em;
      line-height: 88px;
      font-weight: bold;
      span {
        @include font-size(2.4);
        font-weight: normal;
        letter-spacing: .1em;
      }
      &:hover {
        opacity: .75;
      }
    }
  }






  .trouble {
    position: relative;
    background: #fcf4c7;
    padding: 60px 0;
    &::before {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -225px;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 46px 225px 0 225px;
      border-color: #fcf4c7 transparent transparent transparent;
    }
    &__title {
      text-align: center;
      margin: 0 0 35px;
    }
    &__list {
      display: flex;
      display: -ms-flexbox;
      display: -webkit-box;
      display: -webkit-flex;
      justify-content: space-between;
      -ms-flex-pack: justify;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
    }
  }





  .point {
    margin: 90px 0 64px;
    &__title {
      text-align: center;
    }
    &__text {
      margin: 40px 0 0;
      text-align: center;
      @include font-size(2.4);
    }
    &__list {
      li {
        margin: 50px 0 0;
        overflow: hidden;
        z-index: 1;
        h3 {
          position: relative;
          display: block;
          z-index: -1;
          img {
            width: 100%;
          }
        }
        dl {
          width: 555px;
          float: left;
          margin: 32px 0 0;
          display: table;
          dt,
          dd {
            display: table-cell;
            vertical-align: top;
          }
          dt {
            width: 112px;
          }
          dd {
            p {
              b {
                color: #ec5c56;
              }
              &.comment {
                padding: 0 0 10px;
                margin: 0 0 10px;
                font-size:  16px;
                border-bottom: 1px solid #d8d8d8;
              }
              &.name {
                font-size: 14px;
              }
            }
          }
        }
        figure {
          display: block;
          width: 380px;
          z-index: 2;
          margin: -34px 12px 0 0;
          float: right;
        }
      }
    }
  }















  .faq {
    position: relative;
    padding: 68px 0 50px;
    &::before,
    &::after {
      position: absolute;
      content: "";
      display: block;
    }
    &__title,
    &__text {
      text-align: center;
      margin: 0 0 34px;
    }
    &__text {
      @include font-size(2.4);
    }
    &__list {
      li {
        overflow: hidden;
        padding: 34px 22px 40px;
        background: #fffae3;
        margin: 0 0 30px;
        border-radius: 10px;
        .image {
          width: 296px;
          float: right;
        }
        .text {
          float: left;
          width: 606px;
          h3 {
            position: relative;
            background: url(../image/classphoto/faq-icon-q.png) top left / 45px 45px no-repeat;
            font-size: 20px;
            line-height: 24px;
            font-weight: bold;
            color: #67391a;
            min-height: 68px;
            padding: 10px 0 22px 64px;
            &::before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 6px;
              background: url(../image/classphoto/faq-line.png) top left / auto 6px repeat-x;
            }
          }
          p {
            position: relative;
            text-align: left;
            background: url(../image/classphoto/faq-icon-a.png) top left / 45px 45px no-repeat;
            font-size: 16px;
            color: #67391a;
            min-height: 62px;
            padding: 0 0 0 64px;
            margin: 16px 0 0;
            b {
              font-weight: bold;
              display: block;
              color: #000;
              font-size: 18px;
              margin: 0 0 6px;
            }
          }
        }
      }
    }
  }
}
