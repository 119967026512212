.features {
	&__title {
		background-color: rgba($color-brown,0.3);
		background-image: url("../image/features/features_title_background.jpg");
		background-size: cover;
    text-align: center;
		background-position: center center;
		@include PC {
			height: 320px;
			padding: 75px 0;
		}
		@include SP {
			height: 180px;
			padding: 40px 15px;
		}

		&__text {
      white-space: normal;
      margin: 10px auto 30px;
      color: white;
      @include font-size(3.6);
      line-height: 1.4;
      small{
        @include font-size(2.2);
      }
      @include SP {
        @include font-size(2.1);
        small{
          @include font-size(1.6);
        }
      }
//			@include PC {
//				 @include retinaBg("../image/features/features_title_text@2x.png",1048,154);
//				margin: 0 auto 35px;
//			}
//			@include SP {
//				 @include retinaBg("../image/features/features_title_text_sp@2x.png",558,80);
//				margin: 0 auto 20px;
//			}
		}

		&__btn {
			position: relative;
			display: inline-block;
			margin-left: auto;
			margin-right: auto;
			padding: 20px 60px 20px 40px;
      white-space: normal;
			@include PC {
				&:hover{
					top: 3px;
				}
			}
		}
	}

	&__row {
		.inner {
			background-repeat: no-repeat;
			@include PC {
				width: 960px;
				padding: 60px 0;
				min-height: 500px;
				background-size: 356px 408px;
			}
			@include SP {
				padding: 30px 15px 5px;
				min-height: 300px;
				background-size: 190px 215px;
				background-position: calc(50% - 20px) 70px;
			}
		}

		h3 {
			text-align: center;
			font-weight: normal;
			color: $color-brown;
			@include PC {
				 @include line-height(3.2);
				@include font-size(3.2);
				margin-bottom: 50px;
			}
			@include SP {
				 @include line-height(2.2);
				@include font-size(2.2);
				margin-bottom: 30px;
			}

			i {
				display: inline-block;
				padding-right: 5px;
				font-size: 1.2em;
				margin-top: -6px;
			}
		}

		&:nth-of-type(2n) {
			@include PC {
				.inner {
					background-position: right bottom;
				}
			}
		}

		&:nth-of-type(2n+1) {
			background-color: #fff6ca;
			@include PC {
				.inner {
					background-position: left bottom;
				}

				.features__row__melit {
					margin-left: 420px;
				}
			}
		}

		&--attend {
			.inner {
				background-image: url("../image/features/features_attend_image@2x.png");
			}
		}

		&--report {
			.inner {
				background-image: url("../image/features/features_report_image@2x.png");
			}
		}

		&--photo {
			.inner {
				background-image: url("../image/features/features_photo_image@2x.png");
			}
		}

		&--communicate {
			.inner {
				background-image: url("../image/features/features_communicate_image@2x.png");
			}
		}

		&--announce {
			.inner {
				background-image: url("../image/features/features_announce_image@2x.png");
			}
		}

		&--calender {
			.inner {
				background-image: url("../image/features/features_calender_image@2x.png");
			}
		}

		&__point {
			position: relative;
			@include PC {
				padding-left: 100px;
				@include line-height(2.8);
				@include font-size(1.6);

				span.small {
					vertical-align: top;
					font-size: 10px;
				}
			}
			@include SP {
				margin-top: 232px;
				padding: 50px 0 25px;
				@include line-height(2.2);
				@include font-size(1.4);

				span.small {
					vertical-align: top;
					font-size: 10px;
					color: #888;
				}
			}

			&::after {
				content: "POINT!";
				position: absolute;
				left: 0;
				top: 0;
				background-color: #f08e8b;
				text-align: center;
				color: #FFF;
				@include PC {
					width: 80px;
					height: 80px;
					border-radius: 40px;
					line-height: 80px;
				}
				@include SP {
					width: 100%;
					height: 30px;
					border-radius: 3px;
					line-height: 30px;
				}
			}
		}

		&__melit {
			@include PC {
				height: 340px;
				width: 560px;
				margin-top: 60px;
			}
			@include SP {}

			h4 {
				font-weight: normal;
				color: #996039;
				@include PC {
					padding-bottom: 15px;
					@include line-height(2.8);
					@include font-size(2.8);
				}
				@include SP {
					padding-bottom: 15px;
					@include line-height(1.4);
					@include font-size(1.4);
					font-weight: bold;
				}
			}

			p {
				@include PC {
					margin-bottom: 55px;
					@include line-height(2.8);
					@include font-size(1.6);
				}
				@include SP {
					margin-bottom: 25px;
					@include line-height(2);
					@include font-size(1.2);
				}
			}

			span.kerning {
				display: inline-block;
				@include PC {
					margin-left: -0.6em;
				}
				@include SP {
					margin-left: -0.6em;
				}
			}
		}
	}
.features__title__btn .btn--conversion{
  width:510px !important;
}
.features__title__btn{
  padding:20px 60px 20px 40px;
  font-weight:bold;
}
}