@import "bootstrap/mixins";
@import "core/setting";
@import "core/utility";



.kidslydocuments {
  overflow-x: hidden;



	/* COMMON
	======================================================================*/
  article {
    z-index: 1;
    .inner {
      @include clearfix();
      @include SP {
        padding: 0 30px;
      }
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }

  .title01 {
    color: #956134;
    @include font-size(2.4);
    @include line-height(3.6);
    letter-spacing: .1em;
    @include SP {
      text-align: center;
      @include font-size(1.8);
      @include line-height(2.7);
    }
  }
  p {
    @include font-size(1.4);
    @include line-height(2.1);
    letter-spacing: .05em;
    b {
      color: #FF9999;
      font-weight: 600;
    }
  }

  .button-box {
    margin: 46px 0 0;
    text-align: center;
    @include SP {
      margin: 30px 0 0;
      width: 100%;
    }
    p {
      @include font-size(1.2);
      margin: 0 0 8px;
      color: #956134;
    }
    .radius-button {
      width: 100%;
      padding: 20px;
      white-space: nowrap;
      @include SP {
        padding: 12px;
      }
      &::after {
        display: none;
      }
      &--yellow {
        color: #956134;
        border-color: #956134;
      }
      &--white {
        @include transition(0);
        color: #956134;
        background: #fff;
        border: 1px solid #956134;
        border-bottom: 4px solid #956134;
        &:hover {
          top: 2px;
          margin-bottom: 3px;
          @include transition(0);
          border-bottom: 1px solid #956134;
        }
      }
    }
  }



	/* VISUAL
	======================================================================*/
  .mainvisual {
    background: url(../image/kidslydocuments/@2x/bg_FV@2x.png) center bottom / cover no-repeat;
    padding: 56px 0 74px;
    min-height: 490px;
    @include SP {
      min-height: auto;
      padding: 32px 0 0;
      background: url(../image/kidslydocuments/@2x/bg_FV@2x.png) center top / auto 640px no-repeat;
    }
    .text-box {
      text-align: center;
      color: #956134;
      @include PC {
        width: 43.816327%;
        float: left;
      }
      h1 {
        margin: 0 0 24px;
        img {
          height: 120px;
          @include SP {
            height: 106px;
          }
        }
      }
    }
    .image-box {
      padding: 25px 0 0;
      @include PC {
        width: 54.653061%;
        float: right;
      }
    }
    .button-box {
      a {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }



	/* MERIT
	======================================================================*/
  .merit {
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin-top: -74px;
    @include SP {
      margin: 86px 0 0;
    }
    .inner {
      .text-box {
        @include PC {
          padding: 160px 0 0;
          float: left;
          width: 43.816327%;
        }
        h1 {
          margin: 0 0 32px;
          @include SP {
            margin: 0 0 20px;
          }
        }
      }
      .image-box {
        position: relative;
        @include PC {
          float: right;
          width: 45.265306%;
        }
        @include SP {
          margin: 64px -30px 0;
        }
        img {
          @include PC {
            max-width: none;
            width: 758px;
          }
        }
        p {
          position: absolute;
          img {
            width: 240px;
            height: 120px;
            @include SP {
              width: 151px;
              height: 75px;
            }
          }
          &:nth-of-type(1) {
            top: 50px;
            left: 24px;
            @include SP {
              top: 0;
              left: 30px;
            }
          }
          &:nth-of-type(2) {
            bottom: 50px;
            left: 294px;
            @include SP {
              left: auto;
              bottom: 30px;
              right: 30px;
            }
          }
        }
      }
    }
  }



	/* POINT
	======================================================================*/
  .point {
    position: relative;
    background: #FFF9DB;
    z-index: 0;
    padding: 66px 0 104px;
    text-align: center;
    @include SP {
      margin: -44px 0 0;
      padding: 88px 0 40px;
    }
    &::before {
      content: '';
      width: 100%;
      background: url(../image/kidslydocuments/@2x/bg_header@2x.png) center bottom / 100% auto no-repeat;
      padding-top: 12.416667%;
      display: block;
      position: absolute;
      bottom: 100%;
      left: 0;
    }
    .title01 {
      display: inline-block;
      margin: 0 0 67px;
      padding: 0 0 8px;
      border-bottom: 4px solid #FED400;
      @include SP {
        @include font-size(2.4);
        @include line-height(3.6);
        border: 0;
        background: linear-gradient(transparent 90%, #FED400 0%);
        display: inline;
        padding: 0 2px 4px;
      }
    }
    &__section {
      position: relative;
      background: #fff;
      border-radius: 20px;
      padding: 16px 64px 24px;
      margin: 0 0 80px;
      @include clearfix();
      @include SP {
        padding: 0;
        margin: 16px 0 0;
        &:nth-of-type(1) {
          margin-top: 40px;
        }
      }
      .text-box {
        position: relative;
        @include PC {
          width: 40.816327%;
        }
        @include SP {
          padding: 48px 16px 0;
        }
        .num {
          position: absolute;
          @include PC {
            top: 50%;
            margin-top: -79px;
          }
          @include SP {
            top: 16px;
            left: 0;
          }
          img {
            width: auto;
            height: 158px;
            @include SP {
              height: 135px;
            }
          }
        }
        figure {
          display: block;
          text-align: center;
          img {
            height: 120px;
            width: auto;
            @include SP {
              height: 96px;
            }
          }
          figcaption {
            margin: 14px 0 0;
            @include font-size(1.4);
            @include line-height(2.1);
            font-weight: 600;
            color: #956134;
            letter-spacing: .1em;
            @include SP {
              margin: 6px 0 0;
            }
          }
        }
        h2 {
          margin: 30px 0 10px;
          @include font-size(2.0);
          color: #956134;
        }
      }
      .image-box {
        @include PC {
          position: absolute;
          top: 24px;
          width: 49.387755%;
        }
        @include SP {
          margin: 32px -30px 0;
        }
      }
      @include PC {
        &:nth-of-type(odd) {
          .text-box {
            float: left;
            .num {
              right: -70px;
            }
          }
          .image-box {
            right: 0;
          }
        }

        &:nth-of-type(even) {
          .text-box {
            float: right;
            .num {
              left: -70px;
            }
          }
          .image-box {
            left: 0;
          }
        }
      }
    }
    .button-box {
      display: inline-block;
    }
  }



	/* FLOW
	======================================================================*/
  .flow {
    z-index: 1;
    overflow: hidden;
    position: relative;
    text-align: center;
    padding: 100px 0 110px;
    @include SP {
      padding: 72px 0 150px;
    }
    &::before {
      z-index: -1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-width: 1200px;
      min-height: 836px;
      display: block;
      background: url(../image/kidslydocuments/@2x/bg_flow@2x.png) center bottom / 116.666667% auto no-repeat;
      @include SP {
        bottom: 0;
        height: auto;
        background: url(../image/kidslydocuments/@2x/bg_flow@2x.png) center bottom / auto 100% no-repeat;
        min-height: none;
        min-width: none;
      }
    }
    .title-box {
      max-width: 650px;
      margin: auto;
    }
    &__title {
      @include font-size(2.2);
      color: #956134;
      margin: 0 0 18px;
      @include SP {
        @include font-size(1.8);
      }
    }
    .image-box {
      margin: 32px 0 0;
    }
    .button-box {
      display: inline-block;
    }
  }



	/* PRICE
	======================================================================*/
  .price {
    padding: 48px 0 98px;
    text-align: center;
    @include SP {
      padding: 10px 0 72px;
    }
    &__title {
      display: inline-block;
      @include font-size(2.2);
      color: #956134;
      padding: 0 0 4px;
      margin: 0 0 16px;
      border-bottom: 4px solid #FED400;
      @include SP {
        @include font-size(1.8);
        margin: 0 0 24px;
      }
    }
    figure {
      img {
        width: auto;
        height: 90px;
        @include SP {
          height: 75px;
        }
      }
    }
    .catch {
      @include font-size(2.2);
      margin: 32px 0 0;
      font-weight: bold;
      color: #956134;
      @include SP {
        @include font-size(1.8);
        @include line-height(2.4);
        margin: 40px 0 18px;
      }
    }
    .button-box {
      display: inline-block;
    }
  }



}
