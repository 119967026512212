@font-face {
	font-family: "kidsly-font";
	src: url('../fonts/kidsly-font.eot');
	src: url('../fonts/kidsly-font.eot?#iefix') format('eot'),
		url('../fonts/kidsly-font.woff') format('woff'),
		url('../fonts/kidsly-font.ttf') format('truetype'),
		url('../fonts/kidsly-font.svg#kidsly-font') format('svg');
	font-weight: normal;
	font-style: normal;
}
.kf {
	display: inline-block;
	vertical-align: middle;
	font-family: "kidsly-font";
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.kf-lg {
	font-size: 1.3333333333333333em;
	line-height: 0.75em;
	vertical-align: -15%;
}
.kf-2x { font-size: 2em; }
.kf-3x { font-size: 3em; }
.kf-4x { font-size: 4em; }
.kf-5x { font-size: 5em; }
.kf-fw {
	width: 1.2857142857142858em;
	text-align: center;
}
.kf-alert:before { content: "\EA01" }
.kf-appicon:before { content: "\EA02" }
.kf-arrow_b:before { content: "\EA03" }
.kf-arrow_l:before { content: "\EA04" }
.kf-arrow_r:before { content: "\EA05" }
.kf-bed:before { content: "\EA06" }
.kf-board:before { content: "\EA07" }
.kf-book:before { content: "\EA08" }
.kf-bread:before { content: "\EA09" }
.kf-breast:before { content: "\EA0A" }
.kf-bulletin_board:before { content: "\EA0B" }
.kf-calender:before { content: "\EA0C" }
.kf-camera:before { content: "\EA0D" }
.kf-candy:before { content: "\EA0E" }
.kf-capsule:before { content: "\EA0F" }
.kf-check:before { content: "\EA10" }
.kf-check_l:before { content: "\EA11" }
.kf-child:before { content: "\EA12" }
.kf-circke_check:before { content: "\EA13" }
.kf-circle:before { content: "\EA14" }
.kf-clock:before { content: "\EA15" }
.kf-comment:before { content: "\EA16" }
.kf-comment_bg:before { content: "\EA17" }
.kf-comment_caution:before { content: "\EA18" }
.kf-comment_ellipsis:before { content: "\EA19" }
.kf-cross:before { content: "\EA1A" }
.kf-download:before { content: "\EA1B" }
.kf-edit:before { content: "\EA1C" }
.kf-essential:before { content: "\EA1D" }
.kf-exclamation:before { content: "\EA1E" }
.kf-eye:before { content: "\EA1F" }
.kf-feel_happy:before { content: "\EA20" }
.kf-feel_sad:before { content: "\EA21" }
.kf-feel_soso:before { content: "\EA22" }
.kf-flag:before { content: "\EA23" }
.kf-heart:before { content: "\EA24" }
.kf-home:before { content: "\EA25" }
.kf-hospital:before { content: "\EA26" }
.kf-kidpic:before { content: "\EA27" }
.kf-logout:before { content: "\EA28" }
.kf-mail:before { content: "\EA29" }
.kf-megaphone:before { content: "\EA2A" }
.kf-mic:before { content: "\EA2B" }
.kf-milk:before { content: "\EA2C" }
.kf-open_tab:before { content: "\EA2D" }
.kf-paper_plane:before { content: "\EA2E" }
.kf-pc:before { content: "\EA2F" }
.kf-pen:before { content: "\EA30" }
.kf-plus:before { content: "\EA31" }
.kf-potty:before { content: "\EA32" }
.kf-question:before { content: "\EA33" }
.kf-renrakucho:before { content: "\EA34" }
.kf-riceball:before { content: "\EA35" }
.kf-sand:before { content: "\EA36" }
.kf-school:before { content: "\EA37" }
.kf-sent:before { content: "\EA38" }
.kf-setting:before { content: "\EA39" }
.kf-sleep:before { content: "\EA3A" }
.kf-staff:before { content: "\EA3B" }
.kf-staff_sample:before { content: "\EA3C" }
.kf-storage:before { content: "\EA3D" }
.kf-tableware:before { content: "\EA3E" }
.kf-thermometer:before { content: "\EA3F" }
.kf-trash:before { content: "\EA40" }
.kf-upload_images:before { content: "\EA41" }
