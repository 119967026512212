.topics{
  @include SP {
    width: 100%;
  }
  @include PC {
    width: 940px;
    margin: 60px auto;
  }
  .topics__tabs{
    text-align: center;
    font-size: 0;
    @include SP {
      display: table;
      width: 100%;
      padding: 15px;
    }
    @include PC {
      margin-bottom: 25px;
    }
    .topics__tab{
      vertical-align: top;
      background-color: #f2f2f2;
      text-align: center;
      color: #999999;
      cursor: pointer;
      @include transition(0.1);
      @include SP {
        display: table-cell;
        vertical-align: middle;
        width: 25%;
        height: 60px;
        padding: 5px;
        font-size: 11px;
        border: 2px solid #FFF;
      }
      @include PC {
        display: inline-block;
        line-height: 40px;
        width: 232px;
        height: 40px;
        margin: 1px;
        font-size: 13px;
      }
      &:hover,&.on{
        background-color: #fce880;
        color: $color-brown;
        &[key='information']{
          background-color: #ff9999;
          color: #FFF;
        }
        &[key='media']{
          background-color: #8ed0cc;
          color: #FFF;
        }
        &[key='support']{
          background-color: #92cb86;
          color: #FFF;
        }
      }
    }
  }
  .topics__list{
    border-top: 1px solid #e8e8e8;
    opacity: 1;
    @include transition(0.3);
    @include SP {
      margin: 0 15px 30px;
    }
    &.hide{
      opacity: 0;
    }
  }
  .topics__paging{
    position: relative;
    height: 30px;
    margin: 10px 0;
  }
  .topics__page{
    position: absolute;
    top: 0;
    padding: 3px 7px;
    border-radius: 3px;
    background-color: #f2f2f2;
    font-size: 10px;
    color: #999999;
    cursor: pointer;
    @include transition(0.1);
    &:hover{
      background-color: #fce880;
      color: $color-brown;
    }
    &--prev{
      left: 0;
    }
    &--next{
      right: 0;
    }
  }
}
.topic{
  display: block;
  opacity: 1;
  border-bottom: 1px solid #e8e8e8;
  @include transition(0.1);
  @include SP {
    padding: 15px 2px;
  }
  @include PC {
    padding: 15px;
  }
  .topic__date{
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    color: $color-brown;
    @include SP {
      font-size: 13px;
    }
    @include PC {
      font-size: 16px;
      width: 120px;
    }
  }
  .topic__label{
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
    text-align: center;
    color: #FFF;
    @include SP {
      width: 110px;
      margin-left: 10px;
      height: 18px;
      line-height: 18px;
      font-size: 10px;
    }
    @include PC {
      width: 130px;
      margin-right: 20px;
      height: 25px;
      line-height: 25px;
      font-size: 12px;
    }
  }
  .topic__title{
    vertical-align: middle;
    color: $color-maintext;
    @include SP {
      margin-top: 5px;
      display: block;
    }
    @include PC {
      display: inline-block;
      width: 630px;
    }
  }
  &.hidden{
    display: none;
    opacity: 0;
  }
  &[href]:hover{
    background-color: #fff9da;
  }
  &[key='information']{
    .topic__label{
      background-color: #ff9999;
      &::after{
        content:"お知らせ・リリース";
      }
    }
  }
  &[key='media']{
    .topic__label{
      background-color: #8ed0cc;
      &::after{
        content:"メディア";
      }
    }
  }
  &[key='support']{
    .topic__label{
      background-color: #92cb86;
      &::after{
        content:"サポート情報";
      }
    }
  }
}
