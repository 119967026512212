// 非公開ページ用CSS
// /a/private/xxxxxxx.html
// Wordpressから使用

.private{
  background-color: #F4F4F4;
  padding-top: 90px; // private__headerのabsollute分
  @include SP {
    padding-top: 30px;
  }
}
.private__header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #FFF;
  padding: 0;
  text-align: center;
}
.private__header__logo{
  height: 60px;
  padding: 10px;
}

.private{
  .single__title{
    padding-bottom: 30px;
    border-bottom: 1px solid #DDD;
  }
}
.private__content{
  padding: 20px;
  width: 600px;
  margin: 0px auto 30px;
  background-color: #FFF;
  border-radius: 10px;
  @include SP {
    width: 100%;
    padding: 0px;
    margin: 10px auto 30px;
  }
  .single__wrap{
    width: 100%;
    margin: 10px auto;
  }
  .single__body a{
    color: $color-maintext;
    &:hover{
      color: $color-maintext;
      opacity: 0.8;
      text-decoration: underline;
    }
  }

}

.private__footer{
  background-color: #666;
  padding: 20px 0;
  @include SP {
    padding: 10px;
  }
  a{
    text-decoration: none;
  }
}
