.innovators__mainvisual{
  @include PC {
    height: 240px;
  }
  @include SP {
    height: 140px;
  };
  position: relative;
  width: 100%;
  background-color: #ffe982;
  text-align: center;
  img{
    @include PC {
      top: 40px;
      width: 564px;
      margin-left: -282px;
    }
    @include SP {
      top: 20px;
      width: 300px;
      margin-left: -150px;
    };
    position: absolute;
    left: 50%;
  }
  .innovators__mainvisual__sns{
    @include PC {
      top: 180px;
    }
    @include SP {
      top: 100px;
    };
    position: absolute;
    left: 0;
    width: 100%;
  }
}
.innovators__post{
  padding: 40px 0;
  border-bottom: 1px solid #DDD;
  &:nth-of-type(1){
    margin-top: 10px;
  }
  &:nth-last-of-type(1){
    border-bottom: 0;
    margin-bottom: 10px;
  }
  @include SP {
    padding: 20px;
  };
  &.pink{
    .innovators__post__image__label{
      background-color: #f89496;
    }
  }
  &.blue{
    .innovators__post__image__label{
      background-color: #8dceca;
    }
  }
  &.green{
    .innovators__post__image__label{
      background-color: #84b879;
    }
  }
}
.innovators__post__image{
  @include PC {
    width: 460px;
    height: 186px;
  }
  @include SP {
    width: 100%;
  };
  display: inline-block;
  vertical-align: top;
  position: relative;
  overflow: hidden;
  img{
    width: 100%;
    object-fit: cover;
  }
  &__label{
    @include SP {
      bottom: 10px;
      padding: 5px;
      width: 70px;
      font-size: 15px;
    };
    @include PC {
      top: 20px;
      padding: 8px;
      width: 100px;
    };
    display: block;
    position: absolute;
    left: 0px;
    text-align: center;
    background-color: #EFEFEF;
    color: #FFF;
  }
}
.innovators__post__body{
  @include PC {
    width: 460px;
    padding-left: 20px;
    font-size: 14px;
  }
  @include SP {
    width: 100%;
    font-size: 12px;
  };

  display: inline-block;
  vertical-align: top;
  position: relative;
  color: #333;
}
.innovators__post__title{
  @include PC {
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 20px;
  }
  @include SP {
    margin: 10px 0;
    font-size: 16px;
    line-height: 28px;
  };
  display: inline-block;
  text-decoration: underline;
  color: $color-brown;
  &:hover{
    color: $color-brown + #333;
  }
}
.innovators__post__group{
  @include PC {
    margin-bottom: 5px;
  }
  color: #999999;
}
.innovators__post__person{
  font-weight: bold;
}
.innovators__footer__list{
  font-size: 0;
  padding: 40px 20px;
  .innovators__post{
    display: inline-block;
    width: 300px;
    margin-right: 20px;
    padding: 0px;
    margin-bottom: 40px;
    border-bottom: none;
    @include SP {
      width: 100%;
      margin-bottom: 20px;
      margin-right: 0px;
    };
    &:nth-child(3n){
      margin-right: 0;
    }
  }
  .innovators__post__image{
    width: 300px;
    height: 120px;
    margin-bottom: 20px;
    @include SP {
      width: 100%;
      height: auto;
      margin-bottom: 0px;
    };
    img{
      object-fit: cover;
      width: 100%;
      @include PC {
        height: 100%;
      };
    }
    &__label{
      top: 10px;
      padding: 4px 8px;
      width: 80px;
      font-size: 12px;
    }
  }
  .innovators__post__body{
    width: 300px;
    padding-left:0;
    @include SP {
      width: 100%;
    };
  }
  .innovators__post__title{
    margin-bottom: 10px;
    line-height: 24px;
    text-decoration: none;
    font-size: 18px;
    @include SP{
      margin-bottom: 0px;
      font-size: 16px;
    };
  }
  .innovators__footer__list__head{
    color: $color-brown;
    font-weight: lighter;
    font-size: 20px;
    margin-bottom: 20px;
    @include SP{
      text-align: center;
    };
    .kf-mic{
      vertical-align: -25%;
    }
    br{
      display: none;
      @include SP{
        display: block;
      };
    }


  }
  .innovators__footer__list__btn{
    margin-top: 40px;
    text-align: center;
    @include SP{
      margin-top: 0px;
    };
    a{
      display: inline-block;
      height: 48px;
      min-width: 100px;
      padding: 0 30px;
      border: 1px solid;
      border-radius: 24px;
      text-decoration: none;
      line-height: 48px;
      text-align: center;
      font-size: 16px;
      color: $color-brown;
      &:hover{
        color: $color-brown+#333;
      }
    }
  }
}


// innovator
.innovator{
  &.pink{
    .innovator__mainvisual{background-color: #f89496;}
    .innovator__content h4{border-left-color: #f89496; color: #f89496;}
  }
  &.blue{
    .innovator__mainvisual{background-color: #8dceca;}
    .innovator__content h4{border-left-color: #8dceca; color: #8dceca;}
  }
  &.green{
    .innovator__mainvisual{background-color: #84b879;}
    .innovator__content h4{border-left-color: #84b879; color: #84b879;}
  }
}
.innovator__mainvisual{
  @include PC{
    width: 980px;
    height: 300px;
  }
  @include SP{
    width: 100%;
    // height: 240px;
  }
  position: relative;
}
.innovator__mainvisual__logo{
  @include PC{
    width: 240px;
    top: 0;
    left: 0;
  }
  @include SP{
    width: 280px;
    top: 15px;
    left: 50%;
    margin-left: -130px;
  }
  position: absolute;
  img{
    @include PC{
      margin-top: 50px;
      margin-left: 40px;
      width: 166px;
    }
    @include SP{
      width: 260px;
    }
  }
}
.innovator__mainvisual__image{
  @include PC{
    top: 0;
    right: 0;
    position: absolute;
  }
  @include SP{
    padding-top: 120px;
  }
  img{
    @include PC{
      width: 740px;
      height: 300px;
    }
    @include SP{
      width: 100%;
    }
    object-fit: cover;
  }
}
.innovator__mainvisual__vol{
  @include PC{
    top: 220px;
    left: 40px;
    font-size: 20px;
  }
  @include SP{
    top: 80px;
    left: 50%;
    margin-left: -50px;
    width: 100px;
    text-align: center;
    font-size: 14px;
  }
  position: absolute;
  font-weight: lighter;
  color: #FFF;
}
.innovator__sns{
  padding: 10px 0;
  @include PC{
    text-align: right;
  }
  @include SP{
    text-align: center;
  }
  &>*{
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.innovator__head{
  @include PC{
    padding: 0 0 40px;
    margin-bottom: 40px;
    text-align: center;
  }
  @include SP{
    padding: 20px;
    margin-bottom: 20px;
    text-align: left;
    border-top: 1px solid #e8e8e8;
  }
  border-bottom: 1px solid #e8e8e8;
  h3{
    @include PC{
      padding: 30px 0;
      line-height: 48px;
      font-size: 30px;
    }
    @include SP{
      line-height: 32px;
      font-size: 18px;
    }
    font-weight: lighter;
    color: $color-brown;
  }
  &__person{
    margin-top: 10px;
    h5{
      font-weight: bold;
      font-size: 14px;
    }
    h6{
      font-weight: lighter;
      font-size: 14px;
    }

  }
}
.innovator__content{
  @include SP{
    padding: 20px;
  }
  h4{
    @include PC{
      padding: 8px 10px;
      font-size: 24px;
    }
    @include SP{
      padding: 2px 10px;
      font-size: 22px;
      line-height: 32px;
    }
    margin-bottom: 30px;
    padding: 8px 10px;
    border-left: 5px solid #DDD;
    font-weight: lighter;
  }
  p{
    @include PC{
      line-height: 30px;
      font-size: 16px;
    }
    @include SP{
      line-height: 26px;
      font-size: 15px;
    }
    color: $color-maintext;
    word-wrap: break-word;
  }
  figure,.wp-caption{
    @include PC{
      display: inline-block;
      margin: 10px;
      &.left, &.alignleft{float: left;margin-left: 0px;margin-right: 20px;}
      &.right, &.alignright{float: right;margin-right: 0px;margin-left: 20px;}
      &.aligncenter{
        margin: 0 auto;
        display: block;
      }
    }
    @include SP{
      width: 100% !important;
      margin-bottom: 20px;
      text-align: center;
      img{
        display: block;
        max-width: 400px;
        height: auto !important;
        width: 100%;
        margin:  0 auto;
        // object-fit: cover;
        padding-bottom: 2px;
      }
    }
    figcaption,.wp-caption-text{
      margin-top: 10px;
      font-size: 10px;
      color: #999999;
      line-height: 16px;
    }
  }
  img{
    &.aligncenter{
      margin: 0 auto;
      display: block;
    }
    &.left, &.alignleft{float: left;margin-left: 0px;margin-right: 20px;}
    &.right, &.alignright{float: right;margin-right: 0px;margin-left: 20px;}
  }
  .clearfix.divider{clear: both;margin-bottom: 25px;} // TinyMCEのclearfixプラグインで自動挿入される
  .clear{clear: both;}
  &::after {
    content: "";
    clear: both;
    display: block;
  }
}
.innovator__profile{
  @include PC{
    padding: 40px 100px;
  }
  @include SP{
    padding: 20px;
  }
  margin-top: 20px;
  border-top: 1px solid #fff6ca;
  font-size: 0;
  &__image{
    @include PC{
      display: inline-block;
      vertical-align: top;
      width: 220px;
      height: 273px;
    }
    @include SP{
      margin: 0 auto;
      width: 184px;
      height: 229px;
    }
    img{
      @include PC{
        width: 220px;
        height: 273px;
      }
      @include SP{
        width: 184px;
        height: 229px;
      }
      object-fit: cover;
    }
  }
  &__text{
    @include PC{
      width: 540px;
      padding-left: 20px;
    }
    @include SP{
      padding: 20px 0;
    }
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    h6{
      font-weight: lighter;
      font-size: 13px;
      color: $color-brown;
    }
    h5{
      margin-top: 5px;
      margin-bottom: 20px;
      font-weight: bold;
      font-size: 15px;
      color: $color-brown;
      strong{
        padding: 10px;
        font-weight: bold;
        font-size: 16px;
      }
    }
    p{
      padding-bottom: 20px;
      line-height: 26px;
      font-size: 14px;
    }
  }
  &__link{
    // font-size: 11px;
  }
  .kf{
    @include SP{
      font-size: 14px;
    }
    @include PC{
      font-size: 13px;
    }
    padding-top: 2px;
    color: $color-brown;
  }
  a{
    @include SP{
      font-size: 14px;
    }
    @include PC{
      font-size: 13px;
    }
    padding: 5px;
    text-decoration: underline;
    color: $color-brown;
    &:hover{
      color: $color-brown + #333;
      text-decoration: none;
    }
  }
}
.innovator__column{
  @include PC{
    margin: 0px 100px;
    padding: 40px 0px;
  }
  @include SP{
    padding: 40px 20px;
  }
  position: relative;
  border-top: 1px solid $color-brown;
  font-size: 0;
}
.innovator__column__head{
  @include PC{
    font-size: 26px;
    margin-bottom: 30px;
  }
  @include SP{
    font-size: 18px;
    margin-bottom: 10px;
  }
  color: $color-brown;
}
.innovator__column__text{
  @include PC{
    display: inline-block;
    vertical-align: top;
    width: 560px;
    padding-right: 20px;
    line-height: 30px;
    font-size: 16px;
  }
  @include SP{
    margin-bottom: 10px;
    line-height: 26px;
    font-size: 15px;
  }
}
.innovator__column__image{
  @include PC{
    display: inline-block;
    vertical-align: top;
    width: 220px;
    height: 220px;
    img{
      width: 220px;
      height: 220px;
      object-fit: cover;
    }
  }
  @include SP{
    margin: 0 auto;
    width: 180px;
    height: 180px;
    img{
      width: 180px;
      height: 180px;
      object-fit: cover;
    }
  }
}
.innovator__column__animal{
  @include PC{
    width: 940px;
    margin-left: -470px;
  };
  @include SP{
    width: 280px;
    margin-left: -140px;
  };
  position: absolute;
  bottom: -2px;
  left: 50%;
  z-index: 3;
  img{
    width: 100%;
  }
}
