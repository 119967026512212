// 外部sassの読み込み
@import "bootstrap/mixins";
@import "core/setting";
@import "core/utility";

.mainvisual-ver2 {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  @include PC {
    background-image: url("../image/top/mainvisual_bg.jpg");
    height: 535px;
    padding-top: 50px;
  }
  @include SP {
    background-image: url("../image/top/background_cover.jpg");
    height: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  &__inner{
    position: relative;
    @include PC{
      width: 980px;
      margin: 0 auto;
    }
    @include SP{
      width: 100%;
    }
  }


  &__title{
    @include font-size(3.4);
    @include line-height(4.7);
    font-weight: bold;
    text-align: left;
    color: #fff;
    margin-bottom: 34px;
    @include SP{
      @include font-size(2);
      @include line-height(3);
      text-align: center;
    }
  }

  &__image{
    position: absolute;
    right: 0;
    top: 40px;
    text-align: right;
    figcaption{
      color: #fff;
      @include font-size(1.1);
    }
    @include SP{
      position: relative;
      top: -65px;
      left: -20px;
      img{
        width: 100%;
      }
    }
  }

  &__sub{
    color: #fff;
    text-align: left;
    @include SP{
      margin-top: -45px;
    }

    h3{
      @include font-size(2.5);
      font-weight: normal;
      margin-bottom: 4px;
      @include SP{
        text-align: center;
        @include font-size(1.9);
      }
    }

    ul{
      margin-bottom: 14px;
      @include SP{
        width: 85%;
        margin: 0 auto 14px;
      }
    }
    li{
      @include font-size(1.8);
      margin: 1px 0;
      @include SP{
        @include font-size(1.6);
      }
      &::before{
        content: url("../image/top/210316_Icon_feather-check.svg");
        margin-right: 15px;
        @include SP{
          margin-right: 10px;
        }
      }
    }

    &-kidsdesign{
      display: flex;
      @include SP{
        justify-content: center;
      }
      img{
        width: 157px;
        margin-right: 10px;
        @include SP{
          width: 140px;
          height: 34px;
        }
      }
    }
  }

  &__button{
    margin-top: 20px;
    @include SP{
      margin: 20px 20px 0;
    }
    a{
      font-size: 20px;
      color: #fff;
      background: $color-ver2-red;
      border: 3px solid #fff;
      @include transition(.3);
      &:hover{
        margin-top: 0;
        border: 3px solid #fff;
        background: #FF8B8B;
        &::after{
          transform: translateX(5px);
        }
      }
      @include SP{
        font-size: 18px;
        border: 2px solid #fff;
        &::after{
          top: 44%;
        }
      }
      &::after{
        color: #fff;
      }
    }
  }
  &__subtext{
    font-size: 11px;
    margin: 5px 0 0;
    text-align: center;
  }

}