// 外部sassの読み込み
@import "bootstrap/mixins";
@import "core/setting";
@import "core/utility";

.home {
	/* 共通スタイル
	======================================================================*/
	> article{
		> section{
			padding: 70px 0;
			@include SP{
				padding: 30px 0;
			}
			.inner{
				@include SP {
					padding: 0 10px;
				}
			}
			h2{
				@include font-size(3.2);
				color: #753c15;
				text-align: center;
				padding: 0 0 28px;
				margin: 0 0 30px;
				line-height: 1;
				position: relative;
				@include SP {
					@include font-size(1.8);
					line-height: 1.6;
					padding: 0 0 20px;
				}
				&::after{
					position: absolute;
					bottom: 0;
					left: 50%;
					content: "";
					display: block;
					width: 304px;
					height: 6px;
					margin-left: -152px;
					background: #fee14d;
					@include SP {
						border-width: 4px;
						width: 152px;
						margin-left: -76px;
					}
				}
				span{
					display: block;
					@include font-size(2.2);
					margin: 14px 0 0;
					@include SP{
						@include font-size(1.4);
						margin: 10px 0 0;
					}
				}
			}

		}
	}


	/* メインビジュアル
	======================================================================*/
	.mainvisual {
		position: relative;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		@include PC {
			background-image: url("../image/top/mainvisual_bg.jpg");
			height: 420px;
			padding-top: 50px;
		}
		@include SP {
			background-image: url("../image/top/background_cover.jpg");
			height: 360px;
			padding-top: 65px;
		}

		&__kidsdesign__100 {
			position: absolute;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			@include PC {
				background-image: url("../image/top/logo_kidsdesign_special@2x.png");
				width: 368 / 2 + px;
				height: 88 / 2 + px;
				right: 50%;
				top: 75px;
				margin-right: -460px;
			}
			@include SP {
				background-image: url("../image/top/logo_kidsdesign_special_sp@2x.png");
				right: 90px;
				top: 10px;
				width: 290 / 2 + px;
				height: 69 / 2 + px;
			}
		}

		&__kidsdesign {
			position: absolute;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			@include PC {
				background-image: url("../image/top/logo_kidsdesign@2x.png");
				width: 368 / 2 + px;
				height: 88 / 2 + px;
				right: 50%;
				top: 20px;
				margin-right: -460px;
			}
			@include SP {
				background-image: url("../image/top/logo_kidsdesign_sp@2x.png");
				right: 10px;
				top: 10px;
				width: 140 / 2 + px;
				height: 86 / 2 + px;
			}
		}

		&__text {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			//margin-top: 40px;
      margin: 10px auto 30px;
      color: white;
      @include font-size(3.6);
      line-height: 1.4;
      small{
        @include font-size(2.2);
      }
			@include PC {
				//background-image: url("../image/top/mainvisual_text@2x.png");
				
				//width: 1049 / 2 + px;
				//height: 263 / 2 + px;
				//background-size: 1049 / 2 + px 263 / 2 + px;
        
			}
			@include SP {
        @include font-size(2.1);
        small{
          @include font-size(1.6);
        }
//				background-image: url("../image/top/mainvisual_text_sp@2x.png");
//				margin: 0 auto 20px;
//				width: 552 / 2 + px;
//				height: 147 / 2 + px;
//				background-size: 552 / 2 + px 147 / 2 + px;
			}
		}

		&__btn {
			text-align: center;
			@include PC {
				margin: 0 auto 20px;
			}
			@include SP {
				max-width: 318px;
				margin: 0 auto 10px;
			}
      .radius-button{
        @include font-size(1.8);
        @include SP {
          @include font-size(1.4);
        }
      }
		}

		&__inquiry {
			display: inline-block;
			text-align: center;
			text-decoration: underline;
			color: #FFFFFF;

			&:hover {
				color: $color-yellow;
			}
			@include PC {
				margin-bottom: 5px;
				@include font-size(1.4);
				@include letter-spacing(0.1);
			}
			@include SP {
				margin-bottom: 15px;
				@include font-size(1.2);
				@include letter-spacing(0.1);
			}
		}

		&__appbtn {
			font-size: 0;

			a {
				@include PC {
					width: 140px;
					display: inline-block;
					margin: 15px;
				}
				@include SP {
					width: 110px;
					display: block;
					margin: 5px auto 10px;
				}

				img {
					width: 100%;
				}

				&:hover {
					opacity: 0.9;
				}
			}
		}
    &__label{
      position: absolute;
      top: 0;
      left: 50%;
      width: 170px;
      margin-left: -500px;
      @include SP {
        width: 100px;
        margin-left: -190px;
      }
      img{
        width: 100%;
        height: auto;
      }
    }
	}



	/* 緊急お知らせ
	======================================================================*/
	.notification {
		margin: 0 auto;
		padding: 25px 15px 10px;
		background: #f9f9f9;
		&__box {
			max-width:940px;
			padding: 20px;
			border: 1px solid #946038;
			color: #946038;
			margin: 0 auto;
			font-size: 90%;
		}
		bold{
			font-weight:bold;
		}
	}


	/* What's New
	======================================================================*/
	.news {
		background: #f9f9f9;
		@include PC {
			padding: 25px 0;
		}
		@include SP {
			padding: 15px;
		}

		&__whats {
			border: 2px solid #eaeaea;
			@include PC {
				display: table;
				margin: 0 auto;
				width: 940px;
				line-height: 1.6;
			}

			dt {
				background-color: #f9d428;
				text-align: center;
				color: #996039;
				padding: 12px 20px;
				line-height: 1;
				@include PC {
					display: table-cell;
				}
				@include SP {
					padding: 7px 15px;
				}
			}
			dd {
				background-color: #fff;
				padding: 10px 16px;
				@include PC {
					display: table-cell;
					span::after {
						content: " ";
					}
				}
				@include SP {
					padding: 10px 15px;
					span {
						display: block;
					}
				}
				a {
					color: #9b6135;
					text-decoration: underline;
					&:hover{
						text-decoration: none;
					}
				}
			}
		}

		&__announce {
			border: 1px solid #996039;
			@include PC {
				margin: 0 auto;
				padding: 23px;
				width: 940px;
			}
			@include SP {
				padding: 15px;
			}

			h5 {
				text-align: center;
				font-weight: bold;
				color: #996039;
				@include PC {
					 @include font-size(1.4);
					margin-bottom: 20px;
				}
				@include SP {
					 @include font-size(1.2);
					margin-bottom: 10px;

					span {
						display: block;
					}
				}
			}

			p {
				@include PC {
					margin: 0 auto;
					width: 800px;
					line-height: 1.6;
				}
				@include SP {}
			}
		}
	}
	@include PC {
		.news__check dd span::after {
			content: " ";
		}
	}



	/* kidsly（キッズリー）とは？
	======================================================================*/
	.about{
		position: relative;
		width: 100%;
		background: #fef9de;
		// 雲がある場合の色
		// background: #9fd7d4;
		z-index: 1;
		&::before{
			position: absolute;
			bottom: 0;
			z-index: -1;
			content: "";
			display: block;
			width: 100%;
			padding-top: 24%;
			// background: url("../image/top/about_cloud@2x.png") no-repeat center center;
			background-size: cover;
			@include SP{
				// background: url("../image/top/about_cloud-sp@2x.png") no-repeat center center;
				background-size: cover;
				padding-top: 77.419355%;
			};
		}
		&__text {
			width: 800px;
			margin: auto;
			@include clearfix;
			@include SP {
				width: 100%;
			}
			figure{
				display: block;
				float: left;
				@include SP {
					display: block;
					float: none;
					text-align: center;
				}
				img{
					width: 130px;
					@include SP {
						width: 110px;
					}
				}
			}
			p{
				width: 670px;
				float: right;
				padding: 18px 0 0 30px;
				@include font-size(1.8);
				@include SP {
					margin: 20px 0 0;
					padding: 0;
					width: 100%;
					@include font-size(1.4);
				}
				span{
					display: block;
					margin: 12px 0 0;
					&:first-child{
						margin-top: 0;
					}
					em{
						font-style: normal;
						font-weight: bold;
						color: #753c15;
					}
				}
			}
		}
		&__button{
			text-align: center;
			margin: 30px 0 0;
		}
		&__movie{
			width: 100%;
			margin: 60px 0 0;
			text-align: center;
			@include SP{
				margin: 40px 0 0;
				padding-top: 56.538462%;
				position: relative;
			}
			iframe{
				width: 590px;
				height: 345px;
				border: 14px solid #fff;
				border-radius: 10px;
				@include SP{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border-width: 7px;
				};
			}
		}
	}



	/* キッズリーのサービス紹介
	======================================================================*/
	.service {
		position: relative;
		background-color: #fff;
		padding-bottom: 0;
		@include SP{
			padding-bottom: 0;
		}
		&__app,
		&__attendance,
		&__kidslycare,
		&__kidslydocuments,
    &__kidslyprint {
			position: relative;
			@include clearfix;
			padding: 70px 0;
			@include SP{
				padding: 28px 0;
			}
			&::before{
				position: absolute;
				top: 0;
				width: 100%;
				height: 6px;
				background: url(../image/common/border@2x.png) repeat-x;
				background-size: auto 6px;
			}
			&__text{
				float: left;
				@include SP{
					float: none;
				}
				h3{
					margin: 0 0 30px;
					white-space: nowrap;
					@include SP{
						white-space: normal;
						text-align: center;
						margin: 0 0 26px;
					}
					i{
						width: 106px;
						display: inline-block;
						vertical-align: middle;
						@include SP{
							width: 80px;
							margin: 0 auto 18px;
							display: block;
						}
						img{
							width: 100%;
						}
					}
					span{
						display: inline-block;
						margin: 8px 0 0 20px;
						vertical-align: middle;
						@include font-size(2.0);
						color: #753c15;
						@include SP{
							@include font-size(1.4);
						}
						em{
							display: block;
							@include font-size(3.7);
							font-style: normal;
							@include SP{
								@include font-size(2.4);
							}
						}
					}
				}
				p{
					em{
						font-style: normal;
            color: #ff9999;
						font-weight: bold;
					}
				}
        ul{
          display: flex;
          display: -ms-flexbox; /* IE10 */
          display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
          display: -webkit-flex; /* Safari6.1以降 */
          justify-content: space-between;
          -ms-flex-pack: justify; /*IE10*/
          -webkit-box-pack: justify; /* Android4.3以下、Safari3.1〜6.0 */
          -webkit-justify-content: space-between; /* Safari6.1以降 */
          flex-wrap: wrap;
          -ms-flex-wrap: wrap; /*IE10*/
          -webkit-flex-wrap: wrap; /* Safari6.1以降 */
          margin-top: 30px;
          li{
            width: 30%;
            text-align: center;
            @include font-size(1.2);
            color: $color-brown;
            margin-bottom: 20px;
            @include SP{
              @include font-size(1.1);
            }
            i{
              @include font-size(3.6);
            }
          }
        }
			}
			&__button{
				margin: 30px 0 0;
				float: left;
				@include SP{
					float: none;
					text-align: center;
				};
			}
			&__image{
				float: right;
				@include SP{
					float: none;
				}
				img{
					max-width: 100%;
				}
			}
		}
		&__app {
			@include SP{
				padding-top: 0;
			}
			&::before{
				display: none;
			}
			&__text {
				width: 460px;
				@include SP{
					width: 100%;
				};
				p{
					white-space: nowrap;
					@include SP{
						white-space: normal;
					};
				}
			}
			&__download{
				width: 460px;
				float: left;
				margin: 90px 0 0;
				background: #f6f6f6;
				padding: 28px 0;
				border-radius: 10px;
				text-align: center;
				font-size: 0;
				@include SP{
					width: 100%;
          float: none;
					margin: 28px 0 30px;
				}
				h4{
					@include font-size(1.6);
					font-weight: normal;
					margin: 0 0 20px;
					line-height: 1;
					@include SP{
						@include font-size(1.4);
					}
				}
				a{
					display: inline-block;
					margin: 0 5px;
					@include transition(.3);
					@include SP{
						display: block;
						line-height: 1;
						margin: 0 0 10px;
						&:last-child{
							margin-bottom: 0;
						}
					}
					&:hover{
						opacity: .8;
					}
					img{
						width: auto;
						height: 46px;
						@include SP{
							height: 38px;
						}
					}
				}
			}
			&__button {
				position: absolute;
				bottom: 230px;
				@include SP{
					bottom: 10px;
          width: 100%;
				}
			}
			&__image {
				position: relative;
				width: 520px;
				z-index: 1;
				@include SP{
					margin: 28px 0 0 12%;
					width: 76.666667%;
				};
				&__phone{
					width: 520px;
					z-index: 2;
					@include SP{
						width: 100%;
					};
					img{
						width: 100%;
					}
				}

				&__screens {
					position: absolute;
					z-index: -1;
					top: 80px;
					right: 80px;
					display: block;
					white-space: nowrap;
					font-size: 0;
					width: 240px;
					overflow: hidden;
					@include SP {
						top: 14.423077%;
						right: 15.652174%;
						width: 45.652174%;
					}
				}

				&__screen {
					display: inline-block;
					@include transition(0.8);
					width: 240px;
					@include SP {
						width: 100%;
					}
					&.screen-out {
						margin-left: -240px;
						@include SP {
							margin-left: -100%;
						}
					}
				}
			}
		}
		&__attendance {
			&__text {
				width: 600px;
				@include SP{
					width: 100%;
				};
			}
			&__image {
				width: 375px;
				margin: 92px 0 0;
				@include SP{
					width: 84%;
					margin: 25px auto 35px;
				};
			}
		}
		&__kidslycare {
			&__text {
				width: 563px;
				@include SP{
					width: 100%;
				};
			}
			&__image {
				width: 417px;
				margin: 70px 0 0;
				@include SP{
					width: 91.666667%;
					margin: 30px auto 0;
				};
			}
		}
		&__kidslydocuments {
			&__text {
				width: 482px;
				@include SP{
					width: 100%;
				};
			}
			&__image {
				width: 400px;
				margin: 70px 0 0;
				@include SP{
					width: 91.666667%;
					margin: 30px auto 0;
				};
			}
		}
		&__kidslyprint {
			&__text {
				width: 482px;
				@include SP{
					width: 100%;
				};
			}
			&__image {
				width: 358px;
				margin: 70px 0 0;
				@include SP{
					width: 91.666667%;
					margin: 30px auto 0;
				};
			}
		}
    &__kidslysubsidy {
      border-radius: 10px;
      padding: 40px;
      margin: 0 0 80px;
      min-height: 350px;
      background: url(../image/top/service-kidslysubsidy-icon@2x.png) center left 16px / 248px 248px no-repeat, #FFF9DB;
      @include SP {
        padding: 30px 20px;
        min-height: 0;
        background: #FFF9DB;
      }
      h3 {
        text-align: center;
        position: relative;
        @include font-size(3.7);
        padding: 0 0 20px;
        line-height: 1em;
        color: #753C15;
        @include SP {
          @include font-size(2.4);
          padding: 0 0 10px;
          line-height: 1.4;
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -150px;
          width: 300px;
          height: 6px;
          background: #FED400;
          @include SP {
            width: 150px;
            margin-left: -75px;
            height: 3px;
          }
        }
      }
      p {
        max-width: 648px;
        margin: 32px auto 0;
        @include font-size(1.6);
        line-height: 1.5em;
        text-align: center;
        @include SP {
          margin: 20px 0 0;
          @include font-size(1.4);
        }
      }
      &__button {
        margin: 32px 0 0;
        text-align: center;
        @include SP {
          margin: 20px 0 0;
        }
      }
    }
	}



	/* キッズリーをご利用いただいたユーザーの声
	======================================================================*/
  .kidslycare-page{
    .voice{
      padding: 30px 10px;
      .common-title{
        @include font-size(1.6);
        margin-bottom: 0;
      }
    }
  }
//	.voice {
//		background-color: #fff6ca;
//		@include PC {
//			padding: 60px 0;
//		}
//		@include SP {
//			padding: 30px 0;
//		}
//		&__rows{
//			margin: 54px 0 60px;
//			@include SP{
//				margin: 30px 0 25px;
//			}
//		}
//		&__row {
//			margin-top: 48px;
//			@include SP{
//				margin-top: 30px;
//			}
//			&:first-child{
//				margin-top: 0;
//				@include SP{
//					margin-top: 0;
//				}
//			}
//
//			&__text {
//				position: relative;
//				display: block;
//				border-radius: 10px;
//				text-align: left;
//				background-color: #fff;
//				@include PC {
//					float: right;
//					width: 760px;
//					padding: 25px;
//					&::after {
//						content: "";
//						position: absolute;
//						top: 80px;
//						display: block;
//						width: 0;
//						height: 0;
//						border-style: solid;
//					}
//				}
//				@include SP {
//					margin-top: 20px;
//					padding: 15px;
//					&::after {
//						content: "";
//						position: absolute;
//						display: block;
//						top: -16px;
//						left: 50%;
//						margin-left: -10px;
//						width: 0;
//						height: 0;
//						border-style: solid;
//						border-width: 0 10px 16px 10px;
//						border-color: transparent transparent #fffcef transparent;
//					}
//				}
//
//				h3 {
//					color: $color-brown;
//					margin: 30px 0 10px;
//					&:first-child{
//						margin-top: 0;
//					}
//					@include PC {
//						font-weight: normal;
//						@include font-size(1.8);
//						font-weight: bold;
//					}
//					@include SP {
//						text-align: left;
//						font-weight: bold;
//						@include font-size(1.4);
//					}
//				}
//
//				p {
//					margin-bottom: 10px;
//					color: $color-maintext;
//					@include SP{
//					}
//				}
//
//				h4 {
//					font-weight: bold;
//					color: $color-brown;
//				}
//			}
//
//			&__image {
//				display: block;
//				overflow: hidden;
//				background-color: #dcdcdc;
//				@include PC {
//					float: left;
//					width: 160px;
//					border: 6px solid #dcdcdc;
//					border-radius: 80px;
//				}
//				@include SP {
//					width: 100px;
//					margin: 0 auto;
//					border: 3px solid #dcdcdc;
//					border-radius: 50px;
//				}
//
//				img {
//					width: 100%;
//				}
//			}
//			@include PC {
//				&:nth-child(2n+1) {
//					// 吹き出し
//					.voice__row__text::after {
//						left: -20px;
//						border-width: 10px 20px 10px 0;
//						border-color: transparent #fffcef transparent transparent;
//					}
//				}
//
//				&:nth-child(2n) {
//					.voice__row__text {
//						float: left;
//					}
//
//					.voice__row__text::after {
//						right: -20px;
//						border-width: 10px 0 10px 20px;
//						border-color: transparent transparent transparent #fffcef;
//					}
//
//					.voice__row__image {
//						float: right;
//					}
//				}
//
//				&::after {
//					content: "";
//					display: block;
//					clear: both;
//				}
//			}
//		}
//		&__button{
//			text-align: center;
//		}
//	}



	/* よくある質問
	======================================================================*/
	.qa {
		@include PC {
			padding: 60px 0 70px;
		}
		@include SP {
			padding: 30px 0;
		}
		h4 {
			text-align: center;
			color: #753c15;
			@include PC {
				margin-bottom: 40px;
				@include font-size(3.2);
				font-weight: normal;
			}
			@include SP {
				margin-bottom: 30px;
				@include font-size(1.8);
				font-weight: bold;
			}

			span {
				display: block;
				@include PC {
					 @include font-size(2);
				}
				@include SP {
					 @include font-size(1.4);
				}
			}
		}
		&__rows {
			margin: 64px 0 0;
			@include SP {
				margin: 32px 0 0;
			}
		}
		&__row {
			border-radius: 10px;
			overflow: hidden;
			background: #fff6cc;
			margin: 0 0 40px;
			@include SP{
				margin: 0 0 15px;
			}

			&__question {
				position: relative;
				color: $color-brown;
				@include PC {
					padding: 28px 25px 25px 65px;
					@include font-size(1.8);
				}
				@include SP {
					padding: 18px 10px 10px 40px;
					font-weight: bold;
					@include font-size(1.4);
				}

				&::after {
					content: "";
					position: absolute;
					display: block;
					background-image: url("../image/top/qa_icon_q@2x.png");
					background-repeat: no-repeat;
					@include PC {
						top: 20px;
						left: 20px;
						width: 34px;
						height: 34px;
						background-size: 34px;
					}
					@include SP {
						top: 14px;
						left: 10px;
						width: 22px;
						height: 22px;
						background-size: 22px;
					}
				}
			}

			&__answer {
				position: relative;
				color: $color-maintext;
				@include PC {
					padding: 28px 25px 25px 65px;
				}
				@include SP {
					padding: 20px 10px 15px 40px;
				}
				&::before{
					position: absolute;
					top: 0;
					left: 20px;
					right: 20px;
					content: "";
					width: auto;
					height: 6px;
					display: block;
					background: url("../image/common/border2@2x.png") repeat-x center top;
					background-size: auto 6px;
					@include SP {
						left: 10px;
						right: 10px;
					}
				}
				&::after {
					content: "";
					position: absolute;
					display: block;
					background-image: url("../image/top/qa_icon_a@2x.png");
					background-repeat: no-repeat;
					@include PC {
						top: 24px;
						left: 20px;
						width: 34px;
						height: 34px;
						background-size: 34px;
					}
					@include SP {
						top: 18px;
						left: 10px;
						width: 22px;
						height: 22px;
						background-size: 22px;
					}
				}
				a{
					color: #9b6236;
					text-decoration: underline;
					&[target="_blank"]{
						&::after{
							display: inline-block;
							vertical-align: middle;
							margin: 0 0 0 10px;
							width: 17px;
							height: 15px;
							background: url(../image/common/external-link-icon@2x.png) no-repeat center center;
							background-size: 17px 15px;
							content: "";
						}
					}
					&:hover{
						text-decoration: none;
					}
				}
			}
		}
		&__button {
			text-align: center;
			@include SP{
				margin: 20px 0 0;
			}
			a{
				min-width: 350px;
				@include SP{
					min-width: 190px;
				}
			}
		}
	}


	/* コンバージョンエリア
	======================================================================*/
	.cv-area{
		background: #ffd337;
		padding: 56px;
		@include SP{
			padding: 25px 10px;
		}
		.inner{
			width: 100%;
			position: relative;
			padding: 40px 0 30px;
			background: #fff;
			border-radius: 10px;
			text-align: center;
			min-height: 216px;
			@include SP{
				min-height: 0;
				padding: 18px 20px 36px;
			}
			p{
				color: #67391b;
				margin: 0 0 30px;
				@include SP{
					margin: 0 0 14px;
					text-align: center;
				}
			}
			a{
				@include SP{
					max-width: 240px;
					z-index: 1;
				};
			}
			&::before,
			&::after{
				position: absolute;
				bottom: 0;
				content: "";
			}
			&::before{
				left: 20px;
				display: block;
				width: 291px;
				height: 185px;
				background: url(../image/top/cv-area-bg-kirin@2x.png) no-repeat center center;
				background-size: cover;
				@include SP{
					left: 10px;
					width: 122px;
					height: 78px;
					z-index: 2;
				}
			}
			&::after{
				right: 12px;
				display: block;
				width: 230px;
				height: 101px;
				background: url(../image/top/cv-area-bg-kangaroo@2x.png) no-repeat center center;
				background-size: cover;
				@include SP{
					right: 6px;
					width: 95px;
					height: 42px;
					z-index: 2;
				}
			}
		}
	}

	.top-pr{
		@include clearfix();
		&__facebook{
			max-width: 500px;
			// width: 500px;
			// float: left;
	    margin: 0 auto;
			& > .fb-page {
				width: 100%;
			}
			& > .fb-page > span,
			iframe {
			    width: 100% !important;
			}
			@include SP{
				width: 100%;
				float: none;
			}
		}
		&__banner{
			width: 448px;
			float: right;
			@include SP{
        float: none;
        margin: 20px 0 0;
        width: 100%;
			}
			ul{
				li{
					margin: 34px 0 0;
					&:first-child{
						margin: 0;
					}
					a{
						display: block;
						margin: 0 0 10px;
            img {
              width: 100%;
            }
						.hover{
							display: none;
						}
						&:hover{
							.default{
								display: none
							}
							.hover{
								display: block;
							}
						}
					}
					p{
						@include font-size(1.4);
					}
				}
			}
		}
	}
}

body.home {
	article {
		@include transition(0.6,all,$easeOutQuad,0.3s);
		margin-top: -20px;
		opacity: 0;
	}

	.mainvisual__kidsdesign {
		@include transition(0.8,all,$easeOutQuad,1.6s);
		transform: scale(1.1);
		opacity: 0;
	}

	.mainvisual__kidsdesign__100 {
		@include transition(0.8,all,$easeOutQuad,1.6s);
		transform: scale(1.1);
		opacity: 0;
	}

	.mainvisual__text {
		@include transition(0.6,all,$easeOutQuad,0.7s);
		margin-top: 30px;
		opacity: 0;
	}

	.mainvisual__appbtn,
	.mainvisual__btn,
	.mainvisual__inquiry {
		@include transition(0.4,opacity,$easeOutQuad,1.2s);
		opacity: 0;
	}
}

body.animate-after {
	article {
		margin-top: 0;
		opacity: 1;
	}

	.mainvisual__kidsdesign {
		transform: scale(1);
		opacity: 1;
	}

	.mainvisual__kidsdesign__100 {
		transform: scale(1);
		opacity: 1;
	}

	.mainvisual__text {
		opacity: 1;
		@include SP{
		}
	}

	.mainvisual__appbtn,
	.mainvisual__btn,
	.mainvisual__inquiry {
		opacity: 1;
	}
}



/* トピックス
======================================================================*/
.topics-top {
	&__button{
		text-align: center;
	}
}



/* 保育のイノベーターインタビュー
======================================================================*/
.top-innovator {
	background-color: #fff2cd;
	@include PC {
		padding: 60px 0;
	}
	@include SP {
		padding: 30px 0;
		text-align: center;
	}

	.innovators__post {
		@include PC {
			display: inline-block;
			width: 49%;
		};
		@include SP {
			width: 100%;
		};
		border-bottom: none;
		vertical-align: top;

		&:nth-of-type(1) {
			margin-top: 0;
		}

		&.comming_soon {
			.innovators__post__image {
				position: relative;
				background-color: #e6d2c4;

				&::after {
					@include PC {
						font-size: 30px;
					}
					@include SP {
						font-size: 16px;
					}
					content: "Comming Soon!";
					color: #FFF;
					position: absolute;
					width: 100%;
					left: 0;
					top: 50%;
					text-align: center;
					transform: translateY(-50%);
				}
			}
		}
	}

	.innovators__post__image {
		@include PC {
			height: 230px;

			img {
				height: 230px;
			}
		};
		@include SP {
			min-height: 113px;

			img {
				height: 113px;
			}
		};
	}

	.innovators__post__title {
		@include PC {
			margin-top: 20px;
			font-size: 18px;
			line-height: 1.6;
		}
		@include SP {
			margin-top: 15px;
			font-size: 16px;
			line-height: 24px;
		}
	}

	.innovators__post__body {
		padding: 0 !important;
	}

	.top-innovator__btn {
		text-align: center;

		.btn--secondary {
			display: inline-block;
		}
	}
	&__button{
		text-align: center;
	}
}

/* はじめの一歩キャンペーン
======================================================================*/
.kidsly-support-campaign{
	padding: 40px 0;
	@include SP {
		margin: 10px;
		padding: 10px 0;
	}
	a{
		@include transition(.3);
		&:hover{
			opacity: .6;
		}
	}
	img{
		width: 100%;
	}
}