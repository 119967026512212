// 外部sassの読み込み
@import "bootstrap/mixins";
@import "core/setting";
@import "core/utility";

.attendance-page {
	article{
		.inner{
			@include SP {
				padding: 0 15px;
				box-sizing: border-box;
			}
		}
	}
	.Left {
		float: left;
	}

	.Right {
		float: right;
	}

	section {
		padding: 70px 0;
		display: block;
		overflow: hidden;
	}

	.about__text {
		padding-bottom: 15px;
		text-align: left;
		line-height: 1.8;
		font-size: 15px;
		color: #333333;
	}


	.cv-btn {
		@include SP{
			text-align: center;
		}
		a {
			position: relative;
			margin: 10px auto;
			border-radius: 50px;
			font-size: 23px;
      padding: 23px 64px 23px 23px;
      line-height: 1.4em;
			display: inline-block;
      border-bottom: 2px solid #d98b8a;
      background-color: #ff9999;
			text-align: center;
			text-decoration: none;
			color: #fff;
			box-sizing: border-box;
			@include SP{
				margin: 10px auto 0;
				width: auto;
				max-width: 100%;
				font-size: 16px;
				display: inline-block;
				padding: 18px 3em 18px 1.5em;
			}
			&:hover {
				background: #f47b7b;
			}
			&::after{
        text-align: right;
				position: absolute;
				content: url("../image/attendance/cv_icon.png");
				top: 50%;
				right: 30px;
        margin-top: -16px;
				width: 50px;
				@include SP {
          margin-top: -10px;
				}
			}
		}
	}



	/* --------------------------
	メインビジュアル
	* -------------------------- */
	section.mv {
		text-align: center;
		background: url("../image/attendance/main_img_bg.png");
		background-size: cover;
		background-color: #eff0f2;
		background-position: center top;
    padding: 70px 0 40px;
		@include SP{
			height: auto;
			padding: 40px 0;
			border-top: 2px solid #956134;
		}
		h1 {
      margin: 40px auto 60px;
			img {
				height: 120px;
			}
			@include SP{
				margin: 20px 0 24px;
				img{
					height: 100px;
					width: auto;
				}
			}
		}
		.mv__catch{
			p img{
        width: auto;
        height: 81px;
				@include SP{
					height: 37px;
					width: auto;
				}
			}
		}
	}



	/* --------------------------
	タイトル
	* -------------------------- */
	.common-title {
		display: block;
		text-align: center;
		font-size: 28px;
		color: #67391a;
		font-weight: bold;
		margin-bottom: 40px;
		@include SP{
			font-size: 18px;
			line-height: 32px;
			margin-bottom: 30px;
		}
	}



	/* --------------------------
	キッズリー登降園管理システムとは？
	* -------------------------- */
	section.about {
		background: #fff;
		.common-title {
			text-align: left;
		}
		ul {
			li{
				color: #67391a;
				font-weight: bold;
				line-height: 1.8;
				font-size: 15px;
				span{
					color: #d9cbbc;
				}
			}
		}
		.Left {
			width: 520px;
			@include SP{
				width: 100%;
				.br{
					display: none;
				}
			}
		}
		.Right {
			margin-top: 30px;
			width: 440px;
			@include SP{
				width: 100%;
				text-align: center;
				margin: 20px 0 0;
				img{
					max-width: 100%;
          height: auto;
				}
			}
		}
	}



	/* --------------------------
	キッズリー登降園管理システムの特長
	* -------------------------- */
	section.point {
		background: #fff6ca;
		ul li {
			float: left;
			margin-right: 50px;
			width: 293px;
      text-align: center;
			@include SP{
				width: 100%;
        max-width: 293px;
				float: none;
				text-align: center;
				margin: 40px auto 0;
			}
			&:nth-child(1) {
				@include SP{
					margin-top: 0;
				}
			}
			&:nth-child(3) {
        @include PC {
  				margin-right: 0;
        }
			}
      img {
        width: 100%;
        height: auto;
      }
      h3 {
        @include font-size(1.6);
        color: #67391b;
        margin: 20px 0 12px;
        padding: 0 0 10px;
        border-bottom: 4px solid #fed400;
      }
      p {
        @include font-size(1.3);
        color: #646464;
        line-height: 1.78571429em;
      }
		}
	}



	/* --------------------------
	CVエリア
	* -------------------------- */
	section.cv-area {
		background: #fdd000;
		@include SP{
			padding: 30px 15px;
		}
		.inner {
      text-align: center;
			background: #fff url("../image/attendance/cv_bgimg.png");
			background-position: bottom;
			background-repeat: no-repeat;
			border-radius: 10px;
			width: 980px;
			padding: 55px 0;
			@include SP{
				width: 100%;
				background: #fff;
				padding: 36px 24px;
			}
		}
		h1 {
			font-size: 40px;
			margin-bottom: 20px;
			@include SP{
				font-size: 20px;
				line-height: 32px;
			}
		}
		p{
			text-align: center;
			@include SP{
				font-size: 12px;
				line-height: 20px;
			}
		}
		span {
			font-weight: bold;
		}
	}



	/* --------------------------
	園の声
	* -------------------------- */
	section.voice {
		background: #fff;
		ul {
			li {
				width: 980px;
				margin: 0 0 40px 0;
				clear: both;
				@include SP{
					width: 100%;
				}
				dl {
					dt{
						width: 227px;
						height: 326px;
						@include SP{
							margin: auto;
							max-width: 100%;
							height: auto;
							float: none;
						}
					}
					dd {
						position: relative;
						width: 680px;
						float: right;
						background: #fff;
						padding: 30px 20px 20px 20px;
						font-size: 15px;
						border-radius: 10px;
						line-height: 1.8;
						border: 3px solid #f0efef;
						margin-bottom: 60px;
						@include SP {
							width: 100%;
							float: none;
							box-sizing: border-box;
							margin: 20px 0 0;
							padding: 20px;
						}
						&::before {
							content: url("../image/attendance/voice_fukidashi_left.png");
							position: absolute;
							top: 15px;
							width: 20px;
							@include SP{
								display: none;
							}
						}
						p {
							margin-bottom: 10px;
						}
					}
				}
				&.teacher1 {
					dl {
						dt {
							float: left;
							@include SP{
								float: none;
							}
						}
						dd {
							&::before {
								content: url("../image/attendance/voice_fukidashi_left.png");
								left: -20px;
							}
						}
					}
				}
				&.teacher2 {
					dl {
						dt {
							float: right;
							@include SP{
								float: none;
							}
						}
						dd{
							float: left;
							@include SP {
								padding-top: 20px;
							}
							&::before{
								content: url("../image/attendance/voice_fukidashi_right.png");
								right: -20px;
							}
						}
					}
				}
			}
		}
		span {
			color: #67391a;
		}
	}



	/* --------------------------
	よくあるご質問
	* -------------------------- */
	section.faq {
		background: #fff6ca;
		ul{
			li{
				background: #fff;
				padding: 15px 30px 30px 30px;
				border-radius: 10px;
				line-height: 1.8;
				margin-bottom: 30px;
				@include SP{
					width: 100%;
					padding: 20px;
					box-sizing: border-box;
				}
			}
		}
		dl{
			 dt{
		 		color: #67391a;
		 		font-weight: bold;
		 		margin-bottom: 10px;
				@include SP{
					position: relative;
					font-size: 14px;
					line-height: 24px;
					min-height: 48px;
					padding: 6px 0 14px 60px;
					border-bottom: 2px dotted #ddd;
				}
				&::before {
					content: url("../image/attendance/faq_qimg.png");
					position: relative;
					top: 15px;
					width: 60px;
					margin-right: 20px;
					@include SP{
						position: absolute;
						margin: 0;
						left: 0;
						top: 0;
						width: 45px;
					}
				}
				&::after {
					content: url("../image/attendance/faq_line.png");
					position: relative;
					top: 15px;
					@include SP{
						display: none;
					}
				}
			 }
			 dd {
		 		padding-left: 65px;
		 		text-indent: -65px;
				@include SP{
					position: relative;
					padding-left: 60px;
					text-indent: 0;
					font-size: 14px;
					min-height: 50px;
				}
				&::before {
					content: url("../image/attendance/faq_aimg.png");
					position: relative;
					top: 15px;
					width: 60px;
					height: 100px;
					margin-right: 20px;
					@include SP{
						position: absolute;
						left: 0;
						top: 0;
						width: 45px;
						margin: 0;
					}
				}
		 	}
		}
		.button{
			text-align: center;
			margin: 40px 0 0;
			@include SP{
				margin: 20px 0 0;
			}
			a{
				min-width: 350px;
				box-sizing: border-box;
  			@include SP{
  				min-width: 0;
  			}
			}
		}
	}



	/* --------------------------
	ご利用料金
	* -------------------------- */

	section.payment {
		background: #fff;
		.center {
			text-align: center;
			margin-bottom: 30px;
			@include SP{
				img{
					height: 54px;
				}
			}
		}
		.payment-text {
			display: block;
			text-align: center;
			font-size: 28px;
			color: #67391a;
			font-weight: bold;
			margin-bottom: 40px;
			@include SP{
				font-size: 15px;
				line-height: 28px;
			}
			span {
				font-weight: normal;
				font-size: 15px;
				@include SP{
					font-size: 12px;
					line-height: 28px;
				}
			}
		}
		.payment-details {
			background: #fffadf;
			border-radius: 10px;
			padding: 30px;
			margin: 0 auto;
			overflow: hidden;
			@include SP{
				width: auto;
				// margin: 0 15px;
				padding: 45px 30px;
			}
			.Right {
				width: 350px;
				@include SP{
					width: 100%;
					margin: 40px 0 0;
					text-align: center;
					img{
						max-width: 100%;
					}
				}
			}
			.Left {
				width: 550px;
				@include SP{
					width: 100%;
				}
				> ul {
					width: 550px;
					@include SP{
						width: 100%;
					}
					> li {
						margin-top: 10px;
						margin-bottom: 30px;
						padding-bottom: 30px;
						color: #67391b;
						line-height: 1.8;
						font-size: 16px;
						background: url("../image/attendance/faq_line.png");
						background-position: bottom;
						background-repeat: no-repeat;
						@include SP{
							font-size: 13px;
							line-height: 23px;
							padding-bottom: 20px;
							margin: 0 0 20px;
						}
						.pc{
							display: none;
						}
						&:first-child {
							margin-top: 0;
							padding-top: 0;
						}
						&:last-child {
							background: none;
							margin-bottom: 0;
							padding-bottom: 0;
						}
						> ul > li{
							text-indent: -1em;
							padding-left: 1em;
						}
					}
				}
			}
			p {
				margin-top: 8px;
				font-size: 13px;
				line-height: 1.6 !important;
			}
		}
	}



	/* --------------------------
	キッズリーの紹介
	* -------------------------- */

	.kidsly-introduce {
		background-image: url("../image/attendance/footblock_bg.jpg");
		position: relative;
		background-size: cover;
		background-color: #eff0f2;
		background-position: center top;
		min-height: 466px;
		text-align: center;
		@include SP{
			min-height: 0;
			padding: 40px 0 22px;
		}
		p{
			margin-bottom: 20px;
			&:first-child {
				margin-bottom: 50px;
				@include SP{
					margin-bottom: 26px;
					img{
						height: 30px;
					}
				}
			}
			&:nth-child(2) {
				margin-bottom: 50px;
				@include SP{
					margin-bottom: 26px;
					img{
						height: 56px;
					}
				}
			}
			&:nth-child(3) {
				margin-bottom: 0px;
			}
		}
	}



	/* --------------------------
	フッター
	* -------------------------- */

	.footer{
		padding: 30px 0 40px;
		background-color: #dbdbdb;
		@include SP{
			background-color: #956134;
			padding: 24px 24px 36px;
			box-sizing: border-box;
		}
		.inner {
			padding: 0;
			display: table;
			@include SP{
				display: block;
			}
			> * {
				display: table-cell;
				vertical-align: middle;
				@include SP{
					display: block;
				}
			}
		}
		a {
			color: #333333;
			font-size: 12px;
			line-height: 12px;
			@include SP{
				color: #fff;
			}
			&:hover {
				text-decoration: underline;
			}
			&.logo {
				display: block;
				margin-bottom: 15px;
				border: none;
				@include SP{
					margin-bottom: 6px;
				}
				img{
					width: 344px;
					@include SP{
						width: 265px;
					}
				}
			}
		}

		.links {
			text-align: right;
			letter-spacing: -4px;
			@include SP {
				margin: 10px 0 0;
				text-align: left;
			}

			li {
				display: inline-block;
				padding-right: 10px;
				margin-right: 10px;
				letter-spacing: .6px;
				border-right: 1px solid #333333;
				line-height: 12px;
				@include SP {
					border-color: #fff;
				}
			}
		}
	}

}
