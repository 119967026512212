.pagenation{
  margin: 30px 0;
  text-align: center;
  font-size: 14px;
  a{
    @include transition(0.1);
    display: inline-block;
    margin: 0 5px;
    vertical-align: top;
    padding: 10px;
    min-width: 40px;
    border: 1px solid $color-brown;
    background-color: #FFF;
    line-height: 20px;
    text-align: center;
    color: $color-brown;
    &.active, &:hover{
      background-color: $color-brown;
      color: #FFF;
    }
  }
}
