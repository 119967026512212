// 外部sassの読み込み
@import "bootstrap/mixins";
@import "core/setting";
@import "core/utility";

.form-page {
  // overflow-y: hidden; //
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", "sans-serif";
  margin: 0;
  padding: 0;
  width: 100% !important;
  .form-header{
    text-align: center;
    height: auto;
    padding: 20px 0;
    position: relative;
    opacity: 1;
    .logo {
      width: 200px;
      height: auto;
      display: inline-block;
      @include SP {
        width: 140px;
      }
    }
  }
  .form-content {
    padding: 0;
    margin: 0px auto;
    .kidslyform {
      position:absolute;
      width:100%;
      height:0;
      padding-top:115%;
      @include SP {
        padding-top:150%;
      }
      iframe {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        // height:100%; //
        min-height:3200px !important;
      }
    }
  }
  .freebirdFormviewerViewFormBanner {
    @include SP {
      height: 118px;
    }
  }
  .form-title {
    text-align: center;
    font-size: 120%;
    font-weight: bold;
    color: #956134;
    position:absolute;
    @include font-size(2.0);
    margin: 0 0 0 0;
  }
  .form-text-box {
    position:relative;
    left: 0px; top: 3200px;
    width:100%;
    display:block;
    z-index:10;
    text-align: center;
    color: #666;
    margin: 0 auto;
    padding-top:30px;
    @include font-size(1.4);
    @include SP {
      padding: 0 20px;
    }
    a {
      color: #956134;
    }
  }
  .form-text {
    text-align: center;
    color: #666;
    margin: 0 auto;
    @include font-size(1.6);
    @include SP {
      padding:20px 0;
    }
    a {
      color: #956134;
      text-decolation:underline;
  }
}


	.form-text-thanks{
		text-align: center;
		font-weight: bold;
		font-size: 130%;
		line-height: 1.6;
		color:#ff9999;
		padding: 30px;
		display: block;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
	}

  // #satori__creative_container{
  	// max-width:700px;
    // padding: 20px 0;
    // margin:0 auto;
    // display: block;
    // border-top: 1px solid #ccc;
    // border-bottom: 1px solid #ccc;
  //}



footer{
    position:absolute;
    z-index:20;
    @include SP {
      padding:20px;
      height:4em;
    }
}
  .form-footer{
    position:relative;
    left: 0px; top: 3230px;
    display:block;
    background-color: #956134;
    color: #333;
    text-align: center;
    padding: 20px;
    margin: 0 auto 0 auto 0;
    img {
      width: 294px;
    }
  }
}
