// 外部sassの読み込み
@import "bootstrap/mixins";
@import "core/setting";
@import "core/utility";

.faq-page {


	/* メインビジュアル
	======================================================================*/
	.mainvisual {
		position: relative;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		background: #fff6ca;
		border-bottom: 3px solid #fed400;
		height: 214px;
		padding-top: 38px;
		@include SP {
			height: 114px;
			padding-top: 24px;
		}

		&__text {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			background-image: url("../image/faq/mainvisual_text@2x.png");
			margin: 0 auto 45px;
			width: 877 / 2 + px;
			height: 250 / 2 + px;
			background-size: 877 / 2 + px 250 / 2 + px;
			@include SP {
				margin: 0 auto 20px;
				width: 877 / 4 + px;
				height: 250 / 4 + px;
				background-size: 877 / 4 + px 250 / 4 + px;
			}
		}

		&__btn {
			margin: 0 auto 20px;
			@include SP {
				display: none;
			}
		}
	}


	.pnav{
		width: 980px;
		margin: 54px auto 0;
		padding: 25px;
		border: 3px solid #f6f3e6;
		overflow: auto;
		@include SP{
			margin: 30px 10px 10px;
			padding: 5px 15px 15px;
			width: auto;
		}
		ul{
			width: 50%;
			float: left;
			@include SP{
				width: 100%;
			}
			li{
				margin: 16px 0 0;
				@include SP{
					margin: 10px 0 0;
				}
				&:first-child{
					@include PC{
						margin: 0;
					}
				}
				a{
					position: relative;
					display: block;
					@include font-size(1.6);
					color: #67391a;
					padding: 5px 0 0 40px;
					min-height: 32px;
					@include SP{
						@include font-size(1.4);
						min-height: 22px;
						padding-top: 0;
						padding-left: 34px;
					}
					&::before{
						position: absolute;
						left: 0;
						top: 0;
						background: url("../image/faq/pnav_icon_arrow@2x.png") no-repeat center center;
						content: "";
						display: inline-block;
						width: 32px;
						height: 32px;
						background-size: cover;
						@include SP {
							width: 22px;
							height: 22px;
						}
					}
				}
			}
		}
	}



	/* よくある質問
	======================================================================*/
	.faq {
		margin: 75px 0 50px;
		@include SP {
			margin: 0;
		}
		&__title {
			@include font-size(2.5);
			@include line-height(4.8);
			margin-bottom: 10px;
			text-align: center;
			color: #67391a;
			@include SP{
				padding: 50px 10px 0;
				margin-bottom: 20px;
				@include font-size(1.8);
				@include line-height(2.8);
			}
		}
		&__row {
			&__question {
				@include font-size(1.8);
				@include line-height(2.5);
				background: #f8edb8;
				color: #67391a;
				position: relative;
				font-weight: normal;
				padding: 29px 90px 29px 76px;
				border-bottom: 1px solid #cfc5bf;
				@include SP{
					@include font-size(1.5);
					padding: 15px 45px 15px 50px;
				}
				&::before,
				&::after {
					position: absolute;
					top: 50%;
					content: "";
					display: block;
					background-size: cover;
					background-position: center center;
					background-repeat: no-repeat;
				}
				&::before {
					width: 90 /2 + px;
					height: 90 / 2 + px;
					left: 18px;
					margin-top: - + 90 / 4 + px;
					background-image: url(../image/faq/faq_icon_q@2x.png);
					@include SP{
						width: 90 / 3 + px;
						height: 90 / 3 + px;
						left: 10px;
						margin-top: - + 90 / 6 + px;
					}
				}
				&::after {
					width: 52 /2 + px;
					height: 28 / 2 + px;
					right: 20px;
					margin-top: - + 28 / 4 + px;
					background-image: url(../image/faq/faq_icon_arrow@2x.png);
					@include transition(.4s);
					-webkit-transform: rotate(0deg);
					-moz-transform: rotate(0deg);
					-ms-transform: rotate(0deg);
					transform: rotate(0deg);
					@include SP{
						width: 52 / 3 + px;
						height: 28 / 3 + px;
						right: 14px;
						margin-top: - + 28 / 6 + px;
					}
				}
				&.is-active{
					&::after {
						-webkit-transform: rotate(180deg);
						-moz-transform: rotate(180deg);
						-ms-transform: rotate(180deg);
						transform: rotate(180deg);
					}
				}
			}
			&__answer {
				padding: 25px 20px 30px;
				display: none;
				p{
					@include font-size(1.6);
					line-height: 1.6;
					@include SP{
						@include font-size(1.4);
					}
					a{
						color: #9b6236;
						text-decoration: underline;
						&:hover{
							opacity: .75;
						}
					}
					em{
						color: #DC3023;
						font-style: normal;
					}
				}
			}
		}
	}

	.inquiry{
		text-align: center;
		background: #f8edb8;
		padding: 46px;
		@include SP {
			background: #fff;
			padding: 0 10px 30px;
		}
		h2{
			@include font-size(2.5);
			@include line-height(4.8);
			margin-bottom: 10px;
			text-align: center;
			color: #67391a;
			@include SP{
				@include font-size(1.8);
				@include line-height(2.8);
			}
		}
		p {
			@include font-size(1.8);
			color: #67391a;
			@include SP{
				@include font-size(1.4);
			}
		}
		.box{
			background: #fff;
			margin: 45px 0 0;
			padding: 45px 0;
			border-radius: 10px;
			text-align: center;
			@include SP {
				padding: 0;
				margin: 20px 0 0;
			}
			.radius-button{
				margin: 24px auto 0;
				@include PC {
					width: 500px;
				}
				@include SP{
					margin: 10px auto 0;
				}
				&:hover{
					margin-top: 27px;
				}
				&:nth-of-type(1){
					margin-top: 0;
					&:hover{
						margin-top: 3px;
					}
				}
				span{
					text-decoration: underline;
				}
			}
		}
	}



	/* よくある質問／登校園管理システム
	======================================================================*/
	&.attendance{
		.mainvisual {
			height: 214px;
			padding-top: 38px;
			@include SP {
				height: 114px;
				padding-top: 24px;
			}

			&__text {
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				background-image: url("../image/support/faq/attendance/mainvisual_text@2x.png");
				margin: 0 auto 45px;
				width: 941 / 2 + px;
				height: 250 / 2 + px;
				background-size: 941 / 2 + px 250 / 2 + px;
				@include SP {
					margin: 0 auto 20px;
					width: 941 / 4 + px;
					height: 250 / 4 + px;
					background-size: 941 / 4 + px 250 / 4 + px;
				}
			}

			&__btn {
				margin: 0 auto 20px;
				@include SP {
					display: none;
				}
			}
		}
	}
}
