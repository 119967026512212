@import "bootstrap/mixins";
@import "core/setting";
@import "core/utility";



.kidslysubsidy {



	/* COMMON
	======================================================================*/
  article {
    z-index: 1;
    .inner {
      @include clearfix();
      @include SP {
        padding: 0 30px;
      }
    }
  }
  img {
    max-width: 100%;
  }

  .pc {
    @include SP {
      display: none !important;
    }
  }
  .sp {
    @include PC {
      display: none !important;
    }
  }

  .title01 {
    color: #956134;
    @include font-size(2.4);
    @include line-height(3.6);
    letter-spacing: .1em;
    @include SP {
      text-align: center;
      @include font-size(1.8);
      @include line-height(2.7);
    }
  }
  p {
    @include font-size(1.4);
    @include line-height(2.1);
    letter-spacing: .05em;
    b {
      color: #FF9999;
      font-weight: 600;
    }
  }

  .button-box {
    margin: 64px 0 0;
    text-align: center;
    p {
      @include font-size(1.4);
      white-space: nowrap;
      margin: 0 0 8px;
      color: #333;
      @include SP {
        @include font-size(1.2);
      }
    }
  }



	/* VISUAL
	======================================================================*/
  .visual {
    min-height: 320px;
    padding: 40px 0 58px;
    position: relative;
    z-index: 1;
    @include SP {
      padding: 0;
      .inner {
        padding: 0;
      }
    }
    &__image-box {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: auto;
      height: auto;
      background: url(../image/kidslysubsidy/@2x/bg_kv@2x.png) center bottom / cover no-repeat;
      z-index: -1;
      @include SP {
        width: 100%;
        height: 180px;
        position: relative;
        background: url(../image/kidslysubsidy/@2x/bg_kv_sp@2x.png) center center / cover no-repeat;
      }
    }
    &__text-box {
      display: inline-block;
      color: #956134;
      z-index: 1;
      @include SP {
        width: 100%;
        padding: 50px 32px;
      }
      h2 {
        @include font-size(3.2);
        letter-spacing: .1em;
        margin: 0 0 34px;
        @include SP {
          @include font-size(2.2);
          margin-bottom: 20px;
          text-align: center;
        }
      }
      p {
        @include font-size(1.6);
        margin: 0 0 16px;
        color: #333;
        letter-spacing: .05em;
        text-align: center;
        @include SP {
          @include font-size(1.2);
        }
      }
    }
  }



	/* ABOUT
	======================================================================*/
  .about {
    padding: 0 0 64px;
    &__intro {
      position: relative;
      padding: 48px 0 60px;
      overflow: hidden;
      z-index: 1;
      @include SP {
        padding: 0;
      }
      .inner {
        @include SP {
          padding: 0;
          text-align: center;
        }
      }
      &__image-box {
        width: 63.5%;
        height: 390px;
        position: absolute;
        bottom: 0;
        background: url(../image/kidslysubsidy/@2x/bg_subsidy@2x.png) center right / cover no-repeat;
        z-index: -1;
        @include SP {
          position: relative;
          left: auto;
          width: 100%;
          height: 180px;
          background: url(../image/kidslysubsidy/@2x/bg_subsidy@2x.png) center right / cover no-repeat;
        }
      }
      &__text-box {
        background: #FFF9DB;
        padding: 30px 24px 34px;
        @include PC {
          width: 49.183673%;
          float: right;
        }
        @include SP {
          padding: 40px 30px;
        }
        figure {
          text-align: center;
          img {
            height: 100px;
            @include SP {
              height: 60px;
            }
          }
        }
        h2 {
          @include font-size(2.6);
          color: #956134;
          margin: 32px 0 0;
          @include SP {
            @include font-size(2);
            margin: 24px 0 0;
          }
        }
        p {
          @include font-size(2.0);
          line-height: 1.5em;
          margin: 24px 0 0;
          @include SP {
            margin: 16px 0 0;
            @include font-size(1.4);
          }
        }
      }
    }

    &__section-group {
      margin: 64px 0 0;
      @include SP {
        margin: 48px 0 0;
      }
    }
    &__section {
      border-radius: 10px;
      border: 2px solid #956134;
      overflow: hidden;
      margin: 24px 0 0;
      &:first-child {
        margin-top: 0;
      }
      &__title-box {
        background: #956134;
        color: #fff;
        padding: 12px 24px;
        letter-spacing: .05em;
        @include PC {
          white-space: nowrap;
        }
        @include SP {
          padding: 15px 15px 12px;
          text-align: center;
        }
        span {
          display: inline-block;
          vertical-align: middle;
          &:nth-of-type(1) {
            @include font-size(1.8);
            background: #fff;
            text-align: center;
            color: #956134;
            padding: 8px 20px;
            border-radius: 100px;
            line-height: 1em;
            margin: 0 16px 0 0;
            @include SP {
              @include font-size(1.2);
              padding: 6px 45px;
              margin: 0 0 6px;
            }
          }
          &:nth-of-type(2) {
            @include font-size(2.3);
            @include SP {
              display: block;
              @include font-size(2);
            }
          }
        }
      }
      &__contents-box {
        padding: 40px 80px;
        @include SP {
          padding: 20px 15px;
        }
        p {
          @include font-size(2);
          line-height: 1.5em;
          letter-spacing: .05em;
          @include SP {
            @include font-size(1.4);
            line-height: 2em;
          }
          b {
            font-weight: bold;
            color: #956134;
          }
          small {
            @include font-size(1.2);
            line-height: 1.5em;
            display: block;
            margin: 10px 0 0;
          }
        }
        table {
          margin: 24px -16px 0;
          @include PC {
            border-collapse: separate;
            border-spacing: 16px 8px;
          }
          @include SP {
            margin: 24px 0 0;
            display: block;
          }
          tbody {
            @include SP {
              display: block;
            }
          }
          tr {
            @include SP {
              display: block;
            }
            th, td {
              @include SP {
                display: block;
                line-height: 2em;
              }
            }
            th {
              background: #FFF9DB;
              @include font-size(2);
              padding: 12px 12px 10px;
              text-align: center;
              line-height: 1em;
              font-weight: bold;
              color: #956134;
              @include PC {
                width: 236px;
              }
              @include SP {
                @include font-size(1.4);
                padding: 8px 0 6px;
              }
            }
            td {
              @include font-size(1.4);
              line-height: 2em;
              padding: 12px 16px 10px;
              @include PC {
                border: 1px solid #FED400;
              }
              @include SP {
                padding: 8px 0 6px;
                @include font-size(1.2);
              }
              .price {
                @include font-size(2);
                font-weight: bold;
                letter-spacing: .06em;
              }
              ul {
                &.list-style-decimal {
                  li {
                    text-indent: -1.1em;
                    padding-left: 1.1em;
                    list-style: decimal inside;
                  }
                }
                &.list-style-lower-alpha {
                  li {
                    text-indent: -1.1em;
                    padding-left: 1.1em;
                    list-style-type: lower-alpha;
                    list-style-position: inside;
                  }
                }
              }
            }
          }
          table {
            border-collapse: collapse;
            margin: 10px 0;
            width: 100%;
            display: table !important;
            tbody {
              display: table-row-group !important;
            }
            tr {
              display: table-row !important;
              th, td {
                display: table-cell !important;
                padding: 10px;
                border: 1px solid #966337;
                @include SP {
                  padding: 10px 5px;
                }
                &:nth-of-type(1) {
                  width: 100%;
                }
                &:nth-of-type(2) {
                  text-align: center;
                }
                &:nth-of-type(3) {
                  text-align: center;
                }
              }
              th {
                white-space: nowrap;
                @include font-size(1.4);
                color: #956134;
                background: #ede3d9;
                @include SP {
                  @include font-size(1.2);
                }
                br {
                  @include PC {
                    display: none;
                  }
                }
              }
              td {
                @include SP {
                  padding: 10px;
                }
              }
            }
          }
        }
      }
    }
  }





	/* STEP
	======================================================================*/
  .step {
    background: #FFF9DB;
    padding: 86px 0 72px;
    @include SP {
      padding: 40px 0 0;
    }
    h2 {
      @include font-size(2.6);
      text-align: center;
      color: #956134;
      letter-spacing: .1em;
      @include SP {
        @include font-size(2);
      }
    }
    &__list {
      @include PC {
        margin: 40px 0 0;
        overflow: hidden;
      }
      li {
        @include PC {
          width: 23.77551%;
          float: left;
          margin-left: 1.63265333%;
          &:first-child {
            margin-left: 0;
          }
        }
        @include SP {
          margin: 40px 0 0;
          text-align: center;
        }
        h3 {
          @include font-size(2.4);
          background: #956134;
          border-radius: 9px;
          color: #fff;
          padding: 8px;
          text-align: center;
          line-height: 1em;
          letter-spacing: .05em;
          margin: 0 0 10px;
          @include SP {
            padding: 4px;
            @include font-size(2.6);
          }
        }
        p {
          @include font-size(1.6);
          margin: 0 0 26px;
          @include SP {
            margin-bottom: 16px;
          }
        }
        figure {
          @include SP {
            width: 73.968254%;
            margin: auto;
          }
          img {
            width: 100%;
          }
        }
      }
    }

    &__sales {
      overflow: hidden;
      position: relative;
      margin: 96px 0 0;
      padding: 64px 0 0;
      position: relative;
      z-index: 1;
      @include SP {
        background: #fff;
        margin: 48px 0 0;
        padding: 0;
      }
      &__image-box {
        @include PC {
          width: 63.5%;
          height: 440px;
          position: absolute;
          top: 0;
          right: 0;
          background: url(../image/kidslysubsidy/@2x/bg_sales@2x.png) center left / cover no-repeat;
          z-index: -1;
        }
        @include SP {
          position: relative;
          width: 100%;
          height: 180px;
          background: url(../image/kidslysubsidy/@2x/bg_sales@2x.png) top left / cover no-repeat;
        }
      }
      &__text-box {
        @include PC {
          width: 49.183673%;
          background: #fff;
          padding: 30px 24px 34px;
        }
        @include SP {
          text-align: center;
          padding: 48px 0;
        }
        figure {
          text-align: center;
          img {
            height: 110px;
            @include SP {
              height: 55px;
            }
          }
        }
        h2 {
          @include font-size(2.6);
          color: #956134;
          margin: 32px 0 24px;
          text-align: left;
          @include SP {
            margin: 24px 0 0;
            text-align: center;
            @include font-size(1.8);
          }
        }
        p {
          @include font-size(2.0);
          line-height: 1.5em;
          margin: 10px 0 0;
          .border-bottom {
            display: inline;
            border-bottom: 3px solid #FED400;
          }
          @include SP {
            @include font-size(1.4);
          }
        }
      }
    }
  }





	/* POINT
	======================================================================*/
  .point {
    background: url(../image/kidslysubsidy/@2x/bg_point@2x.png) center center / cover no-repeat;
    padding: 110px 0 98px;
    @include SP {
      padding: 64px 0 50px;
        background: url(../image/kidslysubsidy/@2x/bg_point@2x.png) center left / cover no-repeat;
    }
    h2 {
      @include font-size(2.4);
      color: #956134;
      text-align: center;
      letter-spacing: .1em;
      @include SP {
        @include font-size(2);
      }
    }
    &__list {
      @include PC {
        margin: 46px 0 0;
        display: flex;
        display: -ms-flexbox; /* IE10 */
        display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
        display: -webkit-flex; /* Safari6.1以降 */
        justify-content: space-between;
        -ms-flex-pack: justify; /*IE10*/
        -webkit-box-pack: justify; /* Android4.3以下、Safari3.1〜6.0 */
        -webkit-justify-content: space-between; /* Safari6.1以降 */
      }
      @include SP {
        margin: 28px 0 0;
      }
      li {
        background: #fff;
        padding: 16px 20px 26px;
        border-radius: 20px;
        counter-increment: point-list;
        @include PC {
          width: 32.244898%;
          text-align: center;
          &:first-child {
            margin-left: 0;
          }
        }
        @include SP {
          margin: 16px 0 0;
          border-radius: 10px;
          text-align: left;
        }
        h3 {
          @include font-size(2);
          color: #956134;
          letter-spacing: .05em;
          margin: 0 0 10px;
          @include SP {
            display: flex;
            display: -ms-flexbox; /* IE10 */
            display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
            display: -webkit-flex; /* Safari6.1以降 */
            @include font-size(1.6);
          }
          &::before {
            display: block;
            background: #956134;
            color: #fff;
            width: 66px;
            text-align: center;
            height: 66px;
            line-height: 66px;
            border-radius: 40px;
            @include font-size(4);
            content: counter(point-list);
            margin: 0 auto 16px;
            font-family: 'Ropa Sans', sans-serif;
            @include SP {
              width: 40px;
              height: 40px;
              line-height: 40px;
              margin: 0 8px 0 0;
              @include font-size(2.4);
            }
          }
        }
        p {
          @include font-size(1.6);
          line-height: 1.5em;
          @include SP {
            @include font-size(1.4);
          }
        }
      }
    }
    .button-box {
      margin: 24px 0 0;
    }
  }




  .function {
    padding: 60px 0 64px;
    background: #FFF9DB;
    @include SP {
      padding: 48px 0 38px;
    }
    h2 {
      text-align: center;
      margin: 0 0 32px;
      @include SP {
        margin: 0 0 16px;
      }
      span {
        display: block;
        &:nth-of-type(1) {
          margin: 0 0 8px;
          img {
            height: 62px;
            @include SP {
              height: 46px;
            }
          }
        }
        &:nth-of-type(2) {
          @include font-size(2.6);
          letter-spacing: .1em;
          color: #956134;
          @include SP {
            @include font-size(2);
          }
        }
      }
    }
    h2 + p {
      text-align: center;
      @include font-size(2);
      letter-spacing: .05em;
      @include SP {
        @include font-size(1.4);
      }
      small {
        display: block;
        color: #959595;
        margin: 16px 0 0;
        @include font-size(1.4);
        @include SP {
          margin: 8px 0 0;
          @include font-size(1.2);
        }
      }
    }
    &__list {
      @include PC {
        margin: 60px 0 0;
        overflow: hidden;
      }
      li {
        text-align: center;
        @include PC {
          width: 32.244898%;
          float: left;
          margin-left: 1.632653%;
        }
        @include SP {
          margin: 40px 0 0;
        }
        &:first-child {
          margin-left: 0;
        }
        h3 {
          margin: 30px 0 0;
          @include font-size(2);
          color: #956134;
          letter-spacing: .05em;
          @include SP {
            @include font-size(1.8);
            margin: 14px 0 0;
          }
        }
        p {
          margin: 10px 0 20px;
          @include font-size(1.4);
          @include SP {
            margin: 8px 0 16px;
          }
        }
        .radius-button {
          display: inline-block;
          min-width: auto;
          @include font-size(1.6);
          padding: 10px 60px 10px 40px;
          border-width: 1px;
          border-bottom-width: 3px;
          color: #956134;
          @include transition(0);
          @include SP {
            padding: 10px 40px 10px 40px;
            width: auto;
            &::after {
              margin-top: -.8em;
            }
          }
          &:hover {
            margin-top: 2px;
            background: #fff;
            border-bottom-width: 1px;
          }
        }
      }
    }
  }


}
