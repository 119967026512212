// 外部sassの読み込み
@import "bootstrap/mixins";
@import "core/setting";
@import "core/utility";

.voice-page {
	header .header__navigation {
		padding-left: 25px;
		&:nth-of-type(1) {
			padding-left: 50px;
		}
	}



	/* メインビジュアル
	======================================================================*/
	.mainvisual {
		position: relative;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		background-image: url("../image/voice/mainvisual.png");
		background-size: cover;
		height: 320px;
		padding-top: 75px;
		@include SP {
			height: 114px;
			padding-top: 24px;
		}

		&__text {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			background-image: url("../image/voice/mainvisual_text@2x.png");
			margin: 0 auto 45px;
			width: 744 / 2 + px;
			height: 143 / 2 + px;
			background-size: 744 / 2 + px 143 / 2 + px;
			@include SP {
				background-image: url("../image/voice/mainvisual_text_sp@2x.png");
				margin: 0 auto 20px;
				width: 424 / 2 + px;
				height: 99 / 2 + px;
				background-size: 424 / 2 + px 99 / 2 + px;
			}
		}

		&__btn {
			margin: 0 auto 20px;
			@include SP {
				display: none;
			}
		}
	}
	&.kidslycare{
		.mainvisual{
			background-image: url("../image/kidslycare/voice/mainvisual.png");
			@include SP{
				padding-top: 40px;
			}
			&__text {
				background-image: url("../image/kidslycare/voice/mainvisual_text@2x.png");
				width: 996 / 2 + px;
				height: 143 / 2 + px;
				background-size: 996 / 2 + px 143 / 2 + px;
				@include SP {
					width: 996 / 4 + px;
					height: 143 / 4 + px;
					background-size: 996 / 4 + px 143 / 4 + px;
				}
			}
		}
	}



	/* Intro
	======================================================================*/
	.intro {
		border-bottom: 2px solid #ddd;
		@include SP {
			border-bottom: 0;
		}
		.inner{
			padding: 46px 178px 96px 0;
			position: relative;
			@include SP {
				padding: 20px 15px;
			}
			&::after {
				display: block;
				position: absolute;
				top: 34px;
				right: 0;
				content: "";
				width: 356 / 2 + px;
				height: 240 / 2 + px;
				background: url("../image/voice/intro_image@2x.png") no-repeat center center;
				background-size: 356 / 2 + px 240 / 2 + px;
				background-position: right;
				@include SP {
					top: auto;
					right: auto;
					position: relative;
					margin: 10px auto 0;
					width: 230 / 2 + px;
					height: 155 / 2 + px;
					background-size: 230 / 2 + px 155 / 2 + px;
				}
			}
		}
		p {
			@include font-size(1.6);
			line-height: 1.6;
			position: relative;
			@include SP {
				padding: 0;
				@include font-size(1.4);
			}
		}
	}
	&.kidslycare{
		.intro {
			.inner{
				padding: 64px 250px 74px 0;
				@include SP{
					padding: 20px 15px;
				}
				&::after {
					width: 428 / 2 + px;
					height: 258 / 2 + px;
					background: url("../image/kidslycare/voice/intro_image@2x.png") no-repeat center center;
					background-size: 428 / 2 + px 258 / 2 + px;
					background-position: right;
					@include SP {
						top: auto;
						right: auto;
						position: relative;
						margin: 10px auto 0;
						width: 428 / 3 + px;
						height: 258 / 3 + px;
						background-size: 428 / 3 + px 258 / 3 + px;
					}
				}
			}
		}
	}



	/* キッズリーをご利用いただいたユーザーの声
	======================================================================*/
	.voice {
		background-color: #fff6ca;
		padding: 0 0 90px;
		@include SP{
			padding: 30px 10px;
		}
		&__title{
			padding: 50px 0;
			background: #fff;
			color: #753c15;
			border-bottom: 2px solid #fed400;
			@include font-size(2.6);
			@include line-height(3.7);
			font-weight: normal;
			text-align: center;
			@include SP{
				padding: 0;
				@include font-size(1.5);
				@include line-height(2.4);
				font-weight: bold;
				background: none;
				border: 0;
			}

			&::before{
				display: block;
				margin: 0 auto 18px;
				content: "";
				width: 116 / 2 + px;
				height: 64 /2 + px;
				background: url("../image/voice/title_icon.png") no-repeat center;
				background-size: cover;
				@include SP{
					display: none;
				}
			}
			&::after{
				@include SP{
					display: block;
					content: "";
					width: 152px;
					height: 4px;
					margin: 13px auto 0;
					background: #fed400;
				}
			}
		}
		&__row {
			margin-top: 50px;
			@include SP{
				margin-top: 30px;
			}

			&__text {
				position: relative;
				display: block;
				border-radius: 10px;
				text-align: left;
				background-color: #fffcef;
				@include PC {
					float: right;
					width: 760px;
					padding: 25px;
					&::after {
						content: "";
						position: absolute;
						top: 80px;
						display: block;
						width: 0;
						height: 0;
						border-style: solid;
					}
				}
				@include SP {
					margin-top: 20px;
					padding: 15px;
					&::after {
						content: "";
						position: absolute;
						display: block;
						top: -16px;
						left: 50%;
						margin-left: -10px;
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 10px 16px 10px;
						border-color: transparent transparent #fffcef transparent;
					}
				}

				h3 {
					color: $color-brown;
					margin: 30px 0 10px;
					&:first-child{
						margin-top: 0;
					}
					@include PC {
						font-weight: normal;
						@include line-height(3);
						@include font-size(1.8);
						font-weight: bold;
					}
					@include SP {
						text-align: left;
						font-weight: bold;
						@include font-size(1.4);
						line-height: 1.25;
					}
				}

				p {
					margin-bottom: 10px;
					@include font-size(1.6);
					line-height: 1.6;
					color: $color-maintext;
					@include SP{
						@include font-size(1.4);
					}
				}

				h4 {
					@include font-size(1.6);
					@include line-height(3);
					font-weight: bold;
					color: $color-brown;
					@include SP{
						@include font-size(1.4);
						line-height: 1.45;
					}
				}
			}

			&__image {
				display: block;
				overflow: hidden;
				background-color: #dcdcdc;
				@include PC {
					float: left;
					width: 160px;
					border: 6px solid #dcdcdc;
					border-radius: 80px;
				}
				@include SP {
					width: 100px;
					margin: 0 auto;
					border: 3px solid #dcdcdc;
					border-radius: 50px;
				}

				img {
					width: 100%;
				}
			}
			@include PC {
				&:nth-child(2n+1) {
					// 吹き出し
					.voice__row__text::after {
						left: -20px;
						border-width: 10px 20px 10px 0;
						border-color: transparent #fffcef transparent transparent;
					}
				}

				&:nth-child(2n) {
					.voice__row__text {
						float: left;
					}

					.voice__row__text::after {
						right: -20px;
						border-width: 10px 0 10px 20px;
						border-color: transparent transparent transparent #fffcef;
					}

					.voice__row__image {
						float: right;
					}
				}

				&::after {
					content: "";
					display: block;
					clear: both;
				}
			}
		}
		&__button{
			text-align: center;
		}
	}


	section.cv-area {
		background: #fdd000;
		padding: 70px 0;
		display: block;
		overflow: hidden;
		@include SP{
			padding: 30px 15px;
		}
		.inner {
			background: #fff url("../image/kidslycare/cv_bgimg.png");
			background-position: bottom;
			background-repeat: no-repeat;
			border-radius: 10px;
			width: 980px;
			padding: 55px 0;
			@include SP{
				width: 100%;
				background: #fff;
				padding: 36px 24px;
			}
		}
		h1 {
			font-size: 40px;
			margin-bottom: 20px;
			display: block;
			text-align: center;
			font-size: 28px;
			color: #67391a;
			font-weight: bold;
			margin-bottom: 40px;
			@include SP{
				margin-bottom: 30px;
				font-size: 20px;
				line-height: 32px;
			}
		}
		p{
			text-align: center;
			@include SP{
				font-size: 12px;
				line-height: 20px;
			}
		}
		span {
			font-weight: bold;
		}
		.cv-btn {
      text-align: center;
			a {
				position: relative;
				margin: 10px auto;
        line-height: 1.2em;
        padding: 25px 70px 25px 40px;
				border-radius: 50px;
        line-height: 1.2em;
				font-size: 23px;
				display: inline-block;
        border-bottom: 2px solid #d98b8a;
        background-color: #ff9999;
				text-align: center;
				text-decoration: none;
				color: #fff;
				box-sizing: border-box;
				@include SP{
					margin: 10px auto 0;
					width: auto;
					max-width: 100%;
					font-size: 16px;
					display: inline-block;
					padding: 20px 3em 20px 1.5em;
				}
				&:hover {
  				background: #f47b7b;
				}
				&::after{
          content: "";
					position: absolute;
          width: 9px;
          height: 14px;
          background: url("../image/kidslycare/cv_icon.png") center center / cover no-repeat;
					top: 50%;
					right: 40px;
          margin-top: -7px;
					@include SP {
						left: auto;
						right: 20px;
					}
				}
			}
		}
	}
}
