// 外部sassの読み込み
@import "bootstrap/mixins";
@import "core/setting";
@import "core/utility";


.supports {
	/* 共通スタイル
	======================================================================*/
	> article{
		> section{
			padding: 50px 0 70px;
			@include SP{
				padding: 35px 0;
			}
			.inner{
				@include SP {
					padding: 0 10px;
				}
			}
			h2{
				@include font-size(3.2);
				color: #753c15;
				text-align: center;
				padding: 0 0 28px;
				margin: 0 0 56px;
				line-height: 1;
				position: relative;
				@include SP {
					@include font-size(1.8);
					line-height: 1.6;
					padding: 0 0 12px;
					margin: 0 0 24px;
				}
				&::after{
					position: absolute;
					bottom: 0;
					left: 50%;
					content: "";
					display: block;
					width: 304px;
					height: 6px;
					margin-left: -152px;
					background: #fee14d;
					@include SP {
						border-width: 3px;
						width: 152px;
						margin-left: -76px;
					}
				}
				span{
					display: block;
					@include font-size(2.2);
					margin: 14px 0 0;
					@include SP{
						@include font-size(1.4);
						margin: 10px 0 0;
					}
				}
			}

		}
	}
	/* メインビジュアル
	======================================================================*/
	.mainvisual {
		position: relative;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		background: #fff6ca;
		border-bottom: 3px solid #fed400;
		height: 214px;
		padding-top: 38px;
		@include SP {
			height: 150px;
			padding-top: 24px;
		}
		&__text {
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			background-image: url("../image/support/mainvisual_text@2x.png");
			margin: 0 auto 45px;
			width: 1308 / 2 + px;
			height: 248 / 2 + px;
			background-size: 1308 / 2 + px 248 / 2 + px;
			@include SP {
				margin: 0 auto 20px;
				background-image: url("../image/support/mainvisual_text_sp@2x.png");
				width: 871 / 4 + px;
				height: 411 / 4 + px;
				background-size: 871 / 4 + px 411 / 4 + px;
			}
		}
		&__btn {
			margin: 0 auto 20px;
			@include SP {
				display: none;
			}
		}
	}


	/* よくある質問
	======================================================================*/
	.faq {
		@include SP {
			margin: 0;
		}
		&__rows {
			position: relative;
			@include PC{
				background: url("../image/support/faq_illust@2x.png") no-repeat center center;
				background-size: 408 / 2 + px 484 / 2 + px;
				background-position: top right 16px;
				padding-right: 170px;
			}
		}
		&__row {
			@include PC{
				margin: 30px 0 0;
			}
			@include SP{
				margin: 18px 0 0;
			}
			&:first-child{
				margin-top: 0;
			}
			&__question {
				@include font-size(1.8);
				color: #67391a;
				position: relative;
				font-weight: normal;
				padding: 8px 90px 0 76px;
				a{
					color: #67391a;
				}
				@include SP{
					@include font-size(1.4);
					padding: 4px 0 0 50px;
				}
				&::before{
					position: absolute;
					content: "";
					display: block;
					background-size: cover;
					background-position: center center;
					background-repeat: no-repeat;
				}
				&::before {
					width: 90 /2 + px;
					height: 90 / 2 + px;
					top: 0;
					left: 18px;
					background-image: url(../image/faq/faq_icon_q@2x.png);
					@include SP{
						width: 90 / 3 + px;
						height: 90 / 3 + px;
						top: 0;
						left: 10px;
					}
				}
			}
		}
		&__button{
			text-align: center;
			margin: 44px 0 0;
			@include SP{
				margin: 20px 0 0;
				a{
					max-width: 200px;
				}
			}
		}
	}
	/* お問い合わせ
	======================================================================*/
	.inquiry{
		text-align: center;
		background: #f8edb8;
		&__box{
			width: 100%;
			margin: 20px 0 0;
			-webkit-border-radius: 10px;
			-moz-border-radius: 10px;
			-ms-border-radius: 10px;
			border-radius: 10px;
			overflow: hidden;
			@include PC{
				display: table;
			}
			@include SP{
				-webkit-border-radius: 6px;
				-moz-border-radius: 6px;
				-ms-border-radius: 6px;
				border-radius: 6px;
			}
			&:first-child{
				margin-top: 0;
			}
			&__title,
			&__content{
				box-sizing: border-box;
				@include PC{
					padding: 30px;
					display: table-cell;
					vertical-align: middle;
				}
			}
			&__title{
				text-align: center;
				line-height: 0;
				@include PC{
					width: 232px;
					-webkit-border-radius: 10px 0 0 10px;
					-moz-border-radius: 10px 0 0 10px;
					-ms-border-radius: 10px 0 0 10px;
					border-radius: 10px 0 0 10px;
				}
				@include SP{
					padding: 18px 0;
				}
				img{
					width: auto;
					height: 74px;
					@include SP{
						height: 16px;
					}
				}
			}
			&__content{
				background: #fff;
				@include PC{
					text-align: left;
					-webkit-border-radius: 0 10px 10px 0;
					-moz-border-radius: 0 10px 10px 0;
					-ms-border-radius: 0 10px 10px 0;
					border-radius: 0 10px 10px 0;
				}
				@include SP{
					padding: 18px 18px 24px;
				}
				&__button{
					position: relative;
					font-size: 0;
					@include PC {
						margin: 32px 0 0;
						height: 60px;
					}
					@include SP{
						margin: 10px 0 0;
						a{
							max-width: 200px;
						}
					}
					.radius-button{
						position: relative;
						min-width: 0;
						@include PC{
              width: 450px;
              margin-bottom: 20px;
							float: left;
						}
						@include SP{
							margin: 10px 0 0;
							line-height: 1.6;
						}
						&:last-child{
							margin-bottom: 0;
						}
						span{
							text-decoration: underline;
						}
					}
				}
			}
			&:nth-of-type(1){
				.inquiry__box__title{
					background: #fd9a9b;
				}
			}
			&:nth-of-type(2){
				.inquiry__box__title{
					background: #90d0cc;
				}
			}
      &--nursery {
        .inquiry__box__content__button {
          margin-top: 0;
        }
      }
		}
	}
}







.contact .iframeBx {
	padding: 55px 0 100px;
	background-color: #F4F4F4
}

.contact .iframeBx h2 {
	text-align: center;
	font-size: 20px;
	font-size: 2rem
}

.contact .iframeBx .inner {
	display: block;
	max-width: 600px;
	width: 100%;
	margin: 40px auto 0;
	padding: 30px 0 30px 30px;
	background-color: #fff;
	-moz-border-radius: 15px;
	-webkit-border-radius: 15px;
	border-radius: 15px
}

.contact .iframeBx .inner iframe {
	width: 100%;
	height: 1030px
}

@media only screen and (max-width:767px) {
	.contact .iframeBx {
		padding: 40px 0 100px
	}
	.contact .iframeBx .inner {
		width: -moz-calc(100% - 12px);
		width: -o-calc(100% - 12px);
		width: -webkit-calc(100% - 12px);
		width: calc(100% - 12px);
		padding-left: 0;
		margin: 25px auto 0;
		-moz-border-radius: 10px;
		-webkit-border-radius: 10px;
		border-radius: 10px
	}
	.contact .iframeBx .inner iframe {
		height: 1600px
	}
}




.support {
	width: 100%;
	background-color: #F4F4F4;

	.wrap_support {
		height: auto !important;
		height: 100%;
		min-height: 100%;
		position: relative;
		width: 100%;
	}

	.wrapper {
		padding: 40px 0 100px;
		@include SP {
			padding-left: 6px;
			padding-right: 6px;
		}
	}

	.hero {
		padding-top: 55px!important;
	}

	.contents {
		font-size: 13px;
		font-size: 1.3rem;
		line-height: 22px;
		line-height: 2.2rem;
		text-align: left;
		color: #777;
		max-width: 960px;
		margin-left: auto;
		margin-right: auto;
		@include SP {
			max-width: 100%;
			font-size: 12px;
			font-size: 1.2rem;
			line-height: 18px;
			line-height: 1.8rem;
		}
		.wrap {
			max-width: 960px;
			margin: 0 auto;
			padding: 40px;
			-moz-border-radius: 15px;
			-webkit-border-radius: 15px;
			border-radius: 15px;
			background-color: #fff;
			@include SP {
				padding: 20px 15px;
			}
		}

		& > * {
			margin-bottom: 15px;
		}
		& > :last-child {
			margin-bottom: 0;
		}

		h2 {
			font-size: 20px;
			font-size: 2rem;
			font-weight: 700;
			text-align: center;
			margin-bottom: 40px;
			color: #333;
			@include SP {
				font-size: 18px;
				font-size: 1.8rem;
				margin-bottom: 20px;
			}
		}

		h3 {
			font-size: 13px;
			font-size: 1.3rem;
			margin-top: 15px;
			margin-bottom: 5px;
			color: #777;
			font-weight: 700;
			@include SP {
				font-size: 12px;
				font-size: 1.2rem;
			}
		}
		a {
			text-decoration: underline;
			color: #777;
		}
	}
	.indent-4 > li {
		padding-left: 4em;
		text-indent: -4em;
	}

	.indent-1 > li {
		padding-left: 1em;
		text-indent: -1em;
	}

	.indent-1_5 > li {
		padding-left: 1.9em;
		text-indent: -1.9em;
	}

	.indent-2_5 > li {
		padding-left: 2.9em;
		text-indent: -2.9em;
	}

	.right {
		text-align: right;
	}
}



.common_header {
	padding: 8px 0 6px;
	background-color: #fff
}

.common_header img {
	display: block;
	margin: 0 auto
}

.common_footer {
	position: absolute;
	width: 100%;
	bottom: 0;
	min-width: 960px;
	background: none;
	margin: 0;
	padding: 30px 15px 20px;
	overflow: hidden;
	*zoom: 1;
}

@media only screen and (max-width:768px) {
	.common_footer {
		min-width: 100%;
	}
}

.common_footer .left {
	float: left
}

.common_footer .left img {
	margin-right: 10px;
	vertical-align: baseline;
}

.common_footer .left span {
	font-size: 10px;
	font-size: 1rem;
   vertical-align: baseline;
}

.common_footer .right {
	float: right
}

.common_footer .right li {
	display: inline-block;
	margin-left: 4px;
	padding-right: 6px;
	border-right: 1px solid #2F302F;
	line-height: 14px;
	line-height: 1.4rem
}

.common_footer .right li a {
	display: inline-block;
	font-size: 12px;
	font-size: 1.2rem;
	color: #000
}

.common_footer .right li a:hover {
	border-bottom: 1px solid #000
}

.common_footer .right li:last-child {
	border-right: none;
	padding-right: 0
}

/* プライバシーポリシー用 */
#privacy_wapper {
	h2 {
		border-bottom: 1px solid #999;
		font-size: 16px;
		margin-bottom: 5px;
		text-align: left;
	}
}

.privacy_signature p {
	text-align: right;
}

.privacy_customer p {
	background-color: #EFEFEF;
	text-align: center;
}