.conversion {
	@include PC {
		background-image: url("../image/common/footer_conversion_bg@2x.png");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		padding: 60px 0;
		text-align: center;
	}
	@include SP {
		background-image: url("../image/top/background_cover.jpg");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 180%;
		padding: 30px 0;
		text-align: center;
	}
	.inner{
		@include SP{
			padding: 0 10px;
		}
	}

	&__copy {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		@include PC {
			width: 931 / 2 + px;
			height: 206 / 2 + px;
			margin: 0 auto 20px;
			background-image: url("../image/top/conversion_copy@2x.png");
		}
		@include SP {
			width: 523 / 2 + px;
			height: 176 / 2 + px;
			margin: 0 auto 15px;
			background-image: url("../image/top/conversion_copy_sp@2x.png");
			background-position: 70%;
		}
	}
}
