html {
	color: $color-maintext;
}
body {
	@include font-size(1.6);
	line-height: 1.6;
	height:100%;
	@include TB {
		min-width: 980px;
	}
	@include SP {
		min-width: 0;
		@include font-size(1.4);
	}
}


header{
	width: 100%;
	background-color: #fff;
	text-align: left;
	z-index: 100;
	@include PC {
		height: 96px;
		padding: 20px 0;
	}
	@include SP {
		position: fixed;
		height:  48px;
		top:0;
		left: 0;
		opacity: 0.95;
	}
	.logo{
		line-height: 1;
		@include PC {
			display: table-cell;
			vertical-align: middle;
			height:56px;
			width:165px;
			img{
				width: 100%;
			}
		}
		@include SP {
			height:(56/2) + px;
			width:(165/2) + px;
			img{
				width: 100%;
			}
		}
		a{
			display: inline-block;
			line-height: 0 !important;
      position: relative;
      span{
        font-size: 10px;
        position: absolute;
        right: 0;
        bottom: 4px;
        color: #753c15;
        font-weight: bold;
        letter-spacing: .05em;
        @include SP {
          white-space: nowrap;
          right: -25px;
          bottom: 0;
        }
      }
		}
	}
	.inner{
		display: table;
		background-color: #fff;
		@include SP {
			padding: 10px;
			border-bottom: 1px solid $color-brown;
		}
	}
	.inner>* {
		display: table-cell;
	}
	.left{
		vertical-align: middle;
	}
	.right{
		text-align: right;
		vertical-align: middle;
	}
	.header__btn{
		vertical-align: middle;
		@include PC {
			display: inline-block;
			color: $color-brown;
			text-align: center;
		}
		@include SP { //SP時は画像化した背景で出します
			display: inline-block;
			width: 28px;
			height: 28px;
			margin-left: 10px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
		}
		&--login{
			@include font-size(1.3);
			border: 1px solid $color-brown;
			border-radius: 5px;
			padding: 0 10px;
			@include transition(.3);
			@include PC {
				margin-right: 25px;
				i.kf{
					display: inline-block;
					margin-right: 5px;
					padding-bottom: 4px;
					@include font-size(3.2);
				}
				&:hover{
					opacity: .6;
				}
			}
			@include SP { //SP時は画像化した背景で出します
				background-image: url(../image/common/header_login@2x.png);
				border: none;
			}
		}
		&--conv{
			@include font-size(1.3);
			display: inline-block;
			color: #fff;
			border-radius: 5px;
			padding: 9px 10px;
			margin-right: 10px;
			background: $color-ver2-red;
			@include transition(.3);
			&:hover{
				opacity: .6;
			}
			@include SP{
				@include font-size(1.1);
				width: auto;
				height: auto;
				margin-right: 0;
				padding: 6px 10px;
			}
		}
		&--conversion{ // PCのみ
			width: 186px;
			height: 36px;
			line-height: 36px;
			border-radius: 18px;
			border: 1px solid $color-brown;
			@include transition(0.2);
			@include font-size(1.3);
			&:hover{
				background: $color-brown;
				color: #FFFFFF;
			}
		}
		&--menu{ // SPのみ
			@include SP { //SP時は画像化した背景で出します
				background-image: url(../image/common/header_menu@2x.png);
				background-position: top;
				background-size: 28px 56px;
			}
		}
	}
	.pulldown{
		@include transition(1.4,all,$easeOutQuart);
		position: absolute;
		overflow: auto;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		max-height: 60vh;
		margin-top: -60vh;
		background-color: #fff;
		&__content{
			@include transition(0.6,all,$easeOutQuart,0.5s);
			opacity: 0;
		}
		&__list{
			text-align: center;
			>a{
				display: block;
				padding: 13px;
				border-bottom: 1px solid #DDD;
				text-decoration: none;
				font-size: 16px;
				color: $color-maintext;
				&:active,&:focus{
					background-color: $color-brown;
					color: #FFF;
				}
			}
		}
		&__conversion{
			display: block;
			width: 300px;
			padding: 13px;
			margin: 20px auto;
			border-radius: 35px;
			border: 1px solid $color-brown;
			text-align: center;
			font-size: 16px;
			color: $color-brown;
			&:active,&:focus{
				background-color: $color-brown;
				color: #FFF;
			}
		}
		&__close{
			// position: absolute;
			// left: 0;
			// bottom: 0;
			width: 100%;
			padding: 13px;
			background-color: #EEE;
			text-align: center;
			font-size: 16px;
			color: #8e9090;
			span{
				color: #a7a8a8;
				font-size: 20px;
				font-family: monospace;
				vertical-align: middle;
			}
		}
	}

	// SP版メニュー表示時
	@include SP { //SP時は画像化した背景で出します
		&.open{
			opacity: 1;
			.header__btn--menu{
				background-position: bottom;
			}
			.pulldown{
				padding-top: 48px;
				margin-top: 0px !important;
				@include transition(0.4,all,$easeOutQuart);
				&__content{
					opacity: 1;
				}
				&__list{
				}
			}
		}
	}

	.header__navigation{
		display: table-cell;
		vertical-align: middle;
		padding-left: 30px;
		a{
			display: inline-block;
			padding-bottom: 8px;
			line-height: 20px;
			font-size: 13px;
			color: #777;
			&::after{
				content:"";
				display: block;
				margin-left: 50%;
				width: 0%;
				height: 1px;
				background-color: $color-brown;
			}
			&:hover{
				@include transition(0.2);
				&::after{
					@include transition(0.2,all,$easeOutQuart);
				}
			}
			&:hover,&.selected{
				color: $color-brown;
				&::after{
					margin-left: 0%;
					width: 100%;
				}
			}
		}
	}
}

@include PC {
	// ヘッダーフェードイン用
	body.fixed_header{
		padding-top: 96px;
		header{
			position: fixed;
			top:-96px;
			left: 0;
			opacity: 0;
		}
		.header-in{
			@include transition(0.3);
			top:0;
			opacity: 0.95;
		}
	}
}
body{
	@include SP {
		padding-top: 48px;
	}
}



footer{
	padding: 30px 0 40px;
	background-color: $color-brown;
	@include SP {
		padding: 15px;
	}
	a {
		color: #fff;
		font-size: 12px;
		line-height: 12px;
		text-decoration: underline;
		&:hover{
			color: $color-yellow;
		}
		@include SP {
			font-size: 11px;
		}
	}
	a.logo {
		display: block;
		margin-bottom: 15px;
		border: none;
		@include SP {
			margin-bottom: 0px;
		}
		img{
			width:294px;
			@include SP {
				width:215px;
			}
		}
		&+a{
			@include SP {
				font-size: 9px;
			}
		}
	}
	.inner{
		display: table;
		@include SP {
			display: block;
			width: auto;
		}
	}
	.inner>* {
		display: table-cell;
		vertical-align: middle;
		@include SP {
			display: block;
		}
	}
	.copyright{
		@include SP {
			margin-bottom: 10px;
		}
	}
	.links {
		text-align: right;
		letter-spacing: -4px;
		@include SP {
			text-align: left;
			letter-spacing: -4px;
			margin-bottom: 10px;
		}
	}
	.links li {
		display: inline-block;
		padding-right: 10px;
		margin-right: 10px;
		letter-spacing: .6px;
		border-right: 1px solid #fff;
		line-height: 12px;
		@include SP {
			padding-right: 7px;
			margin-right: 7px;
			.links li:last-child {
				border-right: none;
			}
		}
	}
}
.footer{
	&__conversion{
		position: relative;
		text-align: center;
		background-repeat: no-repeat;
		overflow: hidden;
		@include PC {
			padding: 60px 0;
			height: 340px;
			background-image: url(../image/common/footer_conversion_animals@2x.png);
			background-size:1012px 395px;
			background-position: center bottom;
		}
		@include SP {
			padding: 45px 10px;
			height: 310px;
			background-image: url(../image/common/footer_conversion_animals_sp@2x.png);
			background-size:377px 325px;
			background-position: center bottom;
		}
		&.bg-yellow, .bg-yellow &{
			background-color: #fff6ca;
		}

		&__text{
			margin: 0 auto 30px;
			@include PC {
				@include retinaBg("../image/common/footer_conversion_text@2x.png",1168,204);
			}
			@include SP {
				@include retinaBg("../image/common/footer_conversion_text_sp@2x.png",523,176);
			}
		}
		&__glass{
			position: absolute;
			bottom: -5px;
			left: 50%;
			@include PC {
				margin-left: -300px;
				width: 809px;
			}
			@include SP {
				margin-left: -240px;
				width: 480px;
			}
		}
		&__btn{
			margin: 0 auto;
		}
	}
}
.footer-unifa{
  padding: 25px 0;
  ul{
    text-align: center;
    li{
      display: inline;
      font-size: 13px;
      color: $color-brown;
      &:after{
        content: "｜";
      }
      &:last-child{
        &:after{
          content: "";
        }
      }
      a{
        padding: 0 10px;
        color: $color-brown;
      }
    }
  }
  .produce-by{
    text-align: center;
    padding: 30px 0 10px 0;
    color: gray;
    .label{
      margin-top: 0px;
      margin-right: 0;
      white-space: nowrap;
      letter-spacing: .1rem;
    }
    a{
      margin-left: 17px;
    }
  }
}


.btn--secondary{
	display: block;
	border: 2px solid #9a6139;
	background-color: #FFF;
	text-align: center;
	color: #753c15;
	@include PC {
		// width: 360px; //
		height: 60px;
		border-radius: 30px;
		line-height: 60px;
		font-size: 18px;
		@include transition(0.1);
		&:hover{
			color: #FFF;
			background-color: $color-brown;
		}
	};
	@include SP {
		width: 280px;
		height: 36px;
		border-radius: 18px;
		line-height: 36px;
		font-size: 12px;
	};
}

.btn--conversion{
	display: inline-block;
	border-bottom: 2px solid #9a6139;
	background-color: $color-yellow;
	text-align: center;
	color: #753c15;
	@include PC {
		// width: 360px; //
		height: 60px;
		border-radius: 30px;
		line-height: 60px;
		font-size: 18px;
		padding:0 60px 20px 40px;
		font-weight:bold;
		@include transition(0.1);
		&:hover{
			background-color: $color-yellow + #000D4c;
		}
	};
	@include SP {
		width: 280px;
		height: 36px;
		border-radius: 18px;
		line-height: 36px;
		font-size: 12px;
	};
}

.breadcrumb{
	@include PC {
		padding: 15px 0;
	}
	@include SP {
		padding: 15px;
	};
	font-size: 11px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	a{
		text-decoration: underline;
		color: $color-brown;
		&:hover{
			color: $color-brown + #333;
		}
	}
	li{
		display: inline-block;
		vertical-align: top;
		&::after{
			content:'>';
			padding: 0 4px;
		}
		&:last-child::after{
			content: '';
		}
	}
}

.goTop{ //SPのみ
	padding: 20px 0;
	background-color: #eeeeee;
	&__btn{
		display: block;
		width: 180px;
		height: 30px;
		margin: 0 auto;
		border-radius: 5px;
		border: 1px solid $color-brown;
		background-color: #fff;
		text-align: center;
		line-height: 30px;
		color: $color-brown;
		@include font-size(1);
		i{
			display: inline-block;
			margin-right: 2px;
			width: 10px;
			height: 10px;
			border-radius: 5px;
			background-color: $color-brown;
			line-height: 8px;
			color: #FFF;
		}
	}
}




.breadcrumb{
	border-top: 1px solid #d2d2d2;
	@include SP{
		display: none;
	}
	a,
	span{
		display: inline-block;
		text-decoration: none;
		@include font-size(1.2);
		@include line-height(1.7);
	}
	a{
		position: relative;
		margin-right: 30px;
		&:hover{
			text-decoration: underline;
		}
		&::after{
			content: "\f054";
			display: inline-block;
			font-family: "FontAwesome";
			@include font-size(1.0);
			color: #d5c5b8;
			position: absolute;
			left: 100%;
			top: 0;
			margin: 0 0 0 10px;
		}
	}
}

.pc{
	@include SP{
		display: none;
	}
}
.sp{
	@include PC{
		display: none;
	}
}
.radius-button{
	position: relative;
	display: inline-block;
	color: #753c15;
	background: #fff;
	border: 2px solid #9a6135;
	@include font-size(1.6);
	border-radius: 100px;
	// white-space: nowrap;
	font-weight: bold;
	line-height: 1em;
	text-align: center;
	padding: 20px 60px 20px 40px;
	letter-spacing: 0.075em;
	@include transition(.3);
	@include PC{
		min-width: 360px;
	}
	@include SP {
		width: 100%;
		@include font-size(1.2);
		padding: 12px 24px 12px 12px;
	}
	&::after{
		vertical-align: middle;
		margin-top: 0px;
		font-family: "FontAwesome";
		content: "\f054";
		@include font-size(1.4);
		color: #cdb4a3;
		@include transition(.3);
		@include PC{
			position: absolute;
			right: 30px;
		}
		@include SP {
			position: absolute;
			@include font-size(1);
			top: 50%;
			right: 10px;
			margin-top: -.5em;
		}
	}
	@include PC{
		&:hover{
			color: #fff;
			background: #996039;
		}
	}
	&--brown{
		background: #67391a;
		color: #fff;
		font-weight: normal;
		border: 0;
		border-bottom: 3px solid #c29a78;
		@include transition(0);
		@include PC{
			&:hover{
				background: #67391a;
				color: #fff;
				@include PC{
					margin-top: 3px;
					border: none;
				}
			}
		}
	}
	&--yellow{
		background: #fed400;
		color: #753c15;
		border: 0;
		border-bottom: 3px solid #9c613a;
		@include transition(0);
		&::after{
			color: #763d15;
			@include transition(0);
		}
		@include PC{
			&:hover{
				background: #fed400;
				margin-top: 3px;
				border: none;
				color: #763d15;
			}
		}
	}
	&--red {
		background: #f85a5a;
		color: #fff;
		border: 0;
		border-bottom: 3px solid #bf5955;
		@include transition(0);
		::after {
			color: #fff;
		}
		&:hover{
			background: #f85a5a;
			color: #fff;
			@include PC{
				margin-top: 3px;
				border: none;
			}
		}
	}
	&--green {
		background: #5dc484;
		color: #fff;
		border: 0;
		border-bottom: 3px solid #669c6e;
		@include transition(0);
		::after {
			color: #fff;
		}
		&:hover{
			background: #5dc484;
			color: #fff;
			@include PC{
				margin-top: 3px;
				border: none;
			}
		}
	}
}



.kidsly-introduce {
  background-image: url("/c/assets/image/kidslycare/footblock_bg.jpg");
  position: relative;
  background-size: cover;
  background-color: #eff0f2;
  background-position: center top;
  min-height: 466px;
  text-align: center;
  padding: 70px 0;
  @include SP{
    min-height: 0;
    padding: 40px 0 22px;
  }
  p{
    margin-bottom: 20px;
    &:first-child {
      margin-bottom: 50px;
      @include SP{
        margin-bottom: 26px;
        img{
          height: 30px;
        }
      }
    }
    &:nth-child(2) {
      margin-bottom: 50px;
      @include SP{
        margin-bottom: 26px;
        img{
          height: 56px;
        }
      }
    }
    &:nth-child(3) {
      margin-bottom: 0px;
    }
  }
}
