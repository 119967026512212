// 外部sassの読み込み
@import "bootstrap/mixins";
@import "core/setting";
@import "core/utility";

.kidslycare-page {

	header .header__navigation {
		padding-left: 25px;
		&:nth-of-type(1) {
			padding-left: 50px;
		}
	}
	article{
		.inner{
			@include SP {
				padding: 0 15px;
				box-sizing: border-box;
			}
		}
	}
	.Left {
		float: left;
	}

	.Right {
		float: right;
	}

	section {
		padding: 70px 0;
		display: block;
		overflow: hidden;
	}

	.about__text {
		padding-bottom: 15px;
		text-align: left;
		line-height: 1.8;
		font-size: 15px;
		color: #333333;
	}


	.cv-btn {
		@include SP{
			text-align: center;
		}
		a {
			position: relative;
			margin: 10px auto;
			border-radius: 50px;
			font-size: 23px;
      padding: 23px 64px 23px 23px;
      line-height: 1.4em;
			display: inline-block;
      border-bottom: 2px solid #d98b8a;
      background-color: #ff9999;
			text-align: center;
			text-decoration: none;
			color: #fff;
			box-sizing: border-box;
			@include SP{
				margin: 10px auto 0;
				width: auto;
				max-width: 100%;
				font-size: 16px;
				display: inline-block;
				padding: 18px 3em 18px 1.5em;
			}
			&:hover {
				background: #f47b7b;
			}
			&::after{
        text-align: right;
				position: absolute;
				content: url("../image/attendance/cv_icon.png");
				top: 50%;
				right: 30px;
        margin-top: -16px;
				width: 50px;
				@include SP {
          margin-top: -10px;
				}
			}
		}
	}



	/* --------------------------
	メインビジュアル
	* -------------------------- */
	section.mv {
		text-align: center;
		background: url("/c/assets/image/kidslycare/main_img_bg.png");
		background-size: cover;
		background-color: #fff6ca;
		background-position: center top;
		position: relative;
		@include SP{
			height: auto;
			padding: 60px 0 40px;
			border-top: 2px solid #956134;
		}
		.mv__bnr {
			position: absolute;
			right: 10px;
			top: 12px;
			@include SP{
				right: 8px;
		    top: 8px;
			}
			&__kidsdesign {
				width: 236px;
				height: 53px;
				background-image: url(../image/kidslycare/logo_kidsdesign@2x.png);
				background-size: 100%;
				float: left;
				@include SP{
					width: 73px;
			    height: 46px;
					background-image: url(../image/kidslycare/logo_kidsdesign_sp@2x.png);
					margin-left: 10px;
				}
				&__100 {
					width: 236px;
					height: 53px;
					background-image: url(../image/kidslycare/logo_kidsdesign_special@2x.png);
					background-size: 100%;
					float: left;
					margin-left: 10px;
					@include SP{
						width: 131px;
				    height: 46px;
						background-image: url(../image/kidslycare/logo_kidsdesign_special_sp@2x.png);
						margin-top: 0;
					}
				}
			}
		}
		.inner {
			position: relative;
		}
		h1 {
			margin: 35px auto;
			@include SP{
				margin: 20px 0 24px;
				img{
          height: 85px;
					width: auto;
				}
			}
		}
		.mv__catch{
			p img{
				@include SP{
					height: 54px;
					width: auto;
				}
			}
		}
	}



	/* --------------------------
	タイトル
	* -------------------------- */
	.common-title {
		display: block;
		text-align: center;
		font-size: 28px;
		color: #67391a;
		font-weight: bold;
		margin-bottom: 40px;
		@include SP{
			font-size: 18px;
			line-height: 28px;
			margin-bottom: 30px;
		}
	}



	/* --------------------------
	キッズリー登降園管理システムとは？
	* -------------------------- */
	section.about {
		background: #fff;
		.common-title {
			text-align: left;
		}
		ul {
			li{
				color: #67391a;
				font-weight: bold;
				line-height: 1.8;
				font-size: 15px;
				span{
					color: #d9cbbc;
				}
			}
		}
		.Left {
			width: 485px;
			@include SP{
				width: 100%;
				.br{
					display: none;
				}
			}
		}
		.Right {
			width: 475px;
			@include SP{
				width: 100%;
				text-align: center;
				margin: 20px 0 0;
				img{
					max-width: 100%;
          height: auto;
				}
			}
		}
	}



	/* --------------------------
	キッズリー登降園管理システムの特長
	* -------------------------- */
	section.point {
		background: #fff6ca;
		@include SP {
			padding-bottom: 80px;
		}
		ul li {
      text-align: center;
			float: left;
			margin-right: 42px;
			@include SP{
				width: 100% !important;
        max-width: 293px;
				float: none;
				text-align: center;
				margin: 40px auto 0;
			}
      h3 {
        @include font-size(1.5);
        white-space: nowrap;
        color: #67391b;
        margin: 20px 0 12px;
        padding: 0 0 10px;
        border-bottom: 4px solid #fed400;
      }
      p {
        @include font-size(1.3);
        color: #646464;
        line-height: 1.78571429em;
      }
			&:nth-child(1) {
				width: 202px;
			}

			&:nth-child(2) {
				width: 212px;
			}

			&:nth-child(3) {
				width: 212px;
			}

			&:nth-child(4) {
				width: 201px;
        @include PC {
  				margin-right: 0;
        }
        h3 {
        }
			}

		}
	}



	/* --------------------------
	CVエリア
	* -------------------------- */
	section.cv-area {
		background: #fdd000;
		@include SP{
			padding: 30px 15px;
		}
		.inner {
			background: #fff url("/c/assets/image/kidslycare/cv_bgimg.png");
			background-position: bottom;
			background-repeat: no-repeat;
			border-radius: 10px;
			width: 980px;
			padding: 55px 0;
      text-align: center;
			@include SP{
				width: 100%;
				background: #fff;
				padding: 36px 24px;
			}
		}
		h1 {
			font-size: 40px;
			margin-bottom: 20px;
			@include SP{
				font-size: 20px;
				line-height: 32px;
			}
		}
		p{
			text-align: center;
			@include SP{
				font-size: 12px;
				line-height: 20px;
			}
		}
		span {
			font-weight: bold;
		}
	}



	/* --------------------------
		キッズリー保育者ケアでできる事
	 * -------------------------- */

	section.function {
		background: #fff;
		.Left {
			width: 544px;
			@include SP{
				width: 100%;
			}
			.br{
				display: none;
				@include SP{
					display: block;
				}
			}
		}
		.Right {
			width: 285px;
			@include SP{
				width: 100%;
				text-align: center;
			}
		}
		h3 {
			display: block;
			text-align: center;
			font-size: 28px;
			color: #67391a;
			font-weight: bold;
			margin-bottom: 40px;
			background: #fdd000;
			padding: 16px;
			@include SP{
				font-size: 15px;
				line-height: 24px;
			}
		}
    table {
      position: relative;
      margin: 66px 0 70px;
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      &::before,
      &::after {
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -21px;
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
      }
      &::before {
        border-style: solid;
        border-width: 26px 21px 0 21px;
        border-color: #67391a transparent transparent transparent;
      }
      &::after {
        border-style: solid;
        border-width: 26px 21px 0 21px;
        margin-top: -3px;
        border-color: #fff transparent transparent transparent;
      }
      caption {
        @include font-size(2.6);
        font-weight: bold;
        padding: 40px 0 30px;
        white-space: nowrap;
        line-height: 1em;
        border-collapse: separate;
        border-spacing: 0;
        border: 2px solid #67391a;
        border-radius: 10px 10px 0 0;
        color: #67391a;
        position: relative;
        box-sizing: border-box;
        @include SP {
          @include font-size(1.8);
          padding: 34px 10px 14px;
          line-height: 1.75em;
        }
        br {
          @include PC {
            display: none;
          }
        }
        &::before {
          position: absolute;
          top: -27px;
          left: 50%;
          margin-left: -50px;
          display: block;
          content: "";
          width: 100px;
          height: 52px;
          background: url("/c/assets/image/kidslycare/function-icon-nursery.png") center / cover no-repeat, rgba(0,0,0,0);
          @include SP {
            top: -30px;
          }
        }
      }
      tbody {
        display: table;
        width: 100%;
        overflow: hidden;
        border-radius: 0 0 10px 10px;
        border: 2px solid #67391a;
        border-top: 0;
        @include SP {
          display: block;
        }
        tr {
          box-sizing: border-box;
          @include SP {
            display: block;
          }
          th, td {
            position: relative;
            padding: 28px 0;
            box-sizing: border-box;
            @include SP {
              display: block;
              padding: 14px 0;
            }
            &::after {
              position: absolute;
              content: "";
              display: block;
              width: auto;
              height: 1px;
              top: 0;
              left: 0;
              right: 0;
              background: #67391a;
              @include SP {
                display: none;
              }
            }
          }
          th {
            background: #feefb7;
            @include font-size(2.2);
            color: #67391a;
            padding: 28px 14px;
            @include SP {
              position: relative;
              @include font-size(1.6);
              text-align: left;
              padding: 14px 14px;
              display: flex;
              display: -ms-flexbox; /* IE10 */
              display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
              display: -webkit-flex; /* Safari6.1以降 */
              justify-content: flex-start;
              -ms-flex-pack: flex-start; /*IE10*/
              -webkit-box-pack: flex-start; /* Android4.3以下、Safari3.1〜6.0 */
              -webkit-justify-content: flex-start; /* Safari6.1以降 */
              align-items: center;
              -ms-flex-align: center; /*IE10*/
              -webkit-box-align: center; /* Android4.3以下、Safari3.1〜6.0 */
              -webkit-align-items: center; /* Safari6.1以降 */
            }
            &::before {
              display: block;
              content: "";
              width: 50px;
              height: 58px;
              margin: auto auto 14px;
              background: url("/c/assets/image/kidslycare/function-icon-trouble.png") center / cover no-repeat, rgba(0,0,0,0);
              @include SP {
                width: 46px;
                height: 42px;
                margin: 0;
                background: url("/c/assets/image/kidslycare/function-icon-trouble.png") center left / 36px 42px no-repeat, rgba(0,0,0,0);
              }
            }
            &::after {
              left: 14px;
            }
          }
          td {
            padding: 28px 40px;
            @include SP {
              padding: 20px;
            }
            &::after {
              right: 14px;
            }
            ul {
              li {
                text-indent: -1.1em;
                padding-left: 1.1em;
                @include SP {
                  margin: 10px 0 0;
                  &:first-child {
                    margin: 0;
                  }
                }
                &::before {
                  display: inline-block;
                  border-radius: .8em;
                  width: .8em;
                  height: .8em;
                  vertical-align: middle;
                  margin: -.1em 4px 0 0;
                  content: "";
                  background: #d9cbbc;
                }
              }
            }
          }
          &:first-child {
            th, td {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
		p:first-of-type {
			margin-bottom: 40px;
			@include SP{
				margin-bottom: 20px;
				text-align: left;
				font-size: 15px;
				img{
					max-width: 100%;
				}
			}
		}
		dl {
			width: 980px;
			clear: both;
			display: block;
			overflow: hidden;
			background: url("/c/assets/image/kidslycare/function_img_smile.png");
			background-position: -30px left;
			background-repeat: no-repeat;
			@include SP{
				background: none;
				width: 100%;
			}
			&:first-of-type {
				margin-bottom: 50px;
			}
		}
		dt {
			width: 880px;
			padding-left: 90px;
			margin-bottom: 10px;
			@include SP{
				width: 100%;
				height: 33px;
				padding: 8px 0 0;
				background: url("/c/assets/image/kidslycare/function_img_smile.png") no-repeat top left;
				padding-left: 40px;
				background-size: 28px auto;
				img{
					height: 22px;
				}
			}
		}
		dd {
			padding: 10px 0 0 90px;
			@include SP{
				padding: 0;
			}
			span {
				font-weight: bold;
				color: #67391a;
			}
		}
	}





	/* --------------------------
		保育者の声
	 * -------------------------- */

	section.voice {
		background: #fff;
		ul{
			overflow: auto;
			display: block;
			margin: 0 auto;
			li{
				width: 450px;
				float: left;
				margin: 0 75px 30px 0;
				@include SP{
					width: 100%;
					float: none !important;
					margin: 30px 0 0 0;
				}
				&:nth-child(2n) {
					float: right;
					margin-right: 0;
				}
				&:nth-child(5) {
					margin-bottom: 0;
				}
				&:nth-child(6) {
					margin-bottom: 0;
				}
			}
		}
		dl{
			@include SP{
				position: relative;
			}
			dt {
				width: 85px;
				height: 125px;
				float: left;
				@include SP{
					float: none;
					position: absolute;
					left: 0;
					top: 0;
					width: 60px;
					height: auto;
					img{
						width: 50px;
					}
				}
			}
			dd {
				width: 320px;
				float: right;
				background: #fef6d6;
				padding: 15px;
				font-size: 15px;
				border-radius: 10px;
				line-height: 1.8;
				@include SP{
					font-size: 13px;
					float: none;
					margin-left: 60px;
					width: auto;
				}
			}
		}
		span {
			color: #67391a;
		}
		.movie {
			clear: both;
			text-align: center;
			margin: 0 auto 50px auto;
			padding-bottom: 60px;
			width: 980px;
			background: url("/c/assets/image/kidslycare/flow_line.png");
			background-repeat: repeat-x;
			background-position: bottom;
			@include SP{
				margin: auto;
				width: 100%;
				margin-bottom: 60px;
				padding-bottom: 28px;
				padding-top: 55.474453%;
				position: relative;
				background: none;
			}
			&::after{
				@include SP{
					content: "";
					width: 100%;
					height: 6px;
					display: block;
					position: absolute;
					bottom: -30px;
					background: url("/c/assets/image/kidslycare/flow_line.png");
					background-repeat: repeat-x;
					background-position: bottom;
				}
			}
			iframe {
				padding: 10px;
				background: #fff6ca;
				width: 700px;
				height: 394px;
				margin: 0 auto;
				@include SP{
					width: 100%;
					height: 100%;
					box-sizing: border-box;
					position: absolute;
					left: 0;
					top: 0;
				}
			}
		}
		.button{
			text-align: center;
			clear: both;
			margin: 40px 0 0;
		}
	}



	/* --------------------------
	よくあるご質問
	* -------------------------- */
	section.faq {
		background: #fff6ca;
		ul{
			li{
				background: #fff;
				padding: 15px 30px 30px 30px;
				border-radius: 10px;
				line-height: 1.8;
				margin-bottom: 30px;
				@include SP{
					width: 100%;
					padding: 20px;
					box-sizing: border-box;
				}
			}
		}
		dl{
			 dt{
		 		color: #67391a;
		 		font-weight: bold;
		 		margin-bottom: 10px;
				@include SP{
					position: relative;
					font-size: 14px;
					line-height: 24px;
					min-height: 48px;
					padding: 6px 0 14px 60px;
					border-bottom: 2px dotted #ddd;
				}
				&::before {
					content: url("/c/assets/image/kidslycare/faq_qimg.png");
					position: relative;
					top: 15px;
					width: 60px;
					margin-right: 20px;
					@include SP{
						position: absolute;
						margin: 0;
						left: 0;
						top: 0;
						width: 45px;
					}
				}
				&::after {
					content: url("/c/assets/image/kidslycare/faq_line.png");
					position: relative;
					top: 15px;
					@include SP{
						display: none;
					}
				}
			 }
			 dd {
		 		padding-left: 65px;
		 		text-indent: -65px;
				@include SP{
					position: relative;
					padding-left: 60px;
					text-indent: 0;
					font-size: 14px;
					.br{
						display: none;
					}
				}
				&::before {
					content: url("/c/assets/image/kidslycare/faq_aimg.png");
					position: relative;
					top: 15px;
					width: 60px;
					height: 100px;
					margin-right: 20px;
					@include SP{
						position: absolute;
						left: 0;
						top: 0;
						width: 45px;
						margin: 0;
					}
				}
		 	}
		}
		.button{
			text-align: center;
			margin: 40px 0 0;
			@include SP{
				margin: 20px 0 0;
			}
			a{
				min-width: 350px;
				box-sizing: border-box;
			}
			@include SP{
				min-width: 100%;
			}
		}
	}


	/* --------------------------
		ご利用の流れ
	 * -------------------------- */

	section.flow {
		background: #fff;
		.about__text {
			color: #67391a;
			font-weight: bold;
			line-height: 1.8;
			font-size: 15px;
			span {
				color: #d9cbbc;
			}
		}
		p {
			img {
				@include SP{
					width: 100%;
				}
			}
		}
		.flow-caution {
			margin-top: 10px;
			padding-bottom: 15px;
			text-align: left;
			line-height: 1.6;
			font-size: 14px;
			color: #333333;
			@include SP{
				font-size: 12px;
				margin: 13px 0 0;
				padding-bottom: 0;
			}
		}
	}


	/* --------------------------
	ご利用料金
	* -------------------------- */

	section.payment {
		background: #fff6ca;
		.center {
			text-align: center;
			margin-bottom: 30px;
			@include SP{
				img{
					height: 54px;
				}
			}
		}
		.payment-text {
			display: block;
			text-align: center;
			font-size: 28px;
			color: #67391a;
			font-weight: bold;
			margin-bottom: 40px;
			@include SP{
				font-size: 15px;
				line-height: 28px;
			}
			span {
				font-weight: normal;
				font-size: 15px;
				@include SP{
					font-size: 12px;
					line-height: 28px;
				}
			}
		}
		.payment-details {
			background: #fff;
			border-radius: 10px;
			padding: 30px;
			margin: 0 auto;
			overflow: hidden;
			@include SP{
				width: auto;
				padding: 45px 30px;
			}
			.Right {
				width: 200px;
				@include SP{
					width: 100%;
					text-align: center;
					img{
						width: 145px;
					}
				}
			}
			.Left {
				width: 700px;
				@include SP{
					width: 100%;
				}
				> ul {
					width: 700px;
					@include SP{
						width: 100%;
					}
					> li {
						margin-top: 10px;
						margin-bottom: 30px;
						padding-bottom: 30px;
						color: #67391b;
						line-height: 1.8;
						font-size: 16px;
						background: url("/c/assets/image/kidslycare/faq_line.png");
						background-position: bottom;
						background-repeat: no-repeat;
						@include SP{
							font-size: 13px;
							line-height: 23px;
							padding-bottom: 20px;
							margin: 0 0 20px;
						}
						> ul > li{
							text-indent: -1em;
							padding-left: 1em;
						}
					}
				}
			}
			.payment-details-point {
				clear: both;
				@include SP{
					padding: 32px 0 0;
				}
				.Left {
					dt {
						padding: 0 0 0 0px;
						color: #67391b;
						font-weight: bold;
						margin-bottom: 15px;
						@include SP{
							font-size: 15px;
							line-height: 26px;
							margin-bottom: 10px;
						}
						&::before {
							content: url("/c/assets/image/kidslycare/flow_img_point.png");
							position: relative;
							top: 5px;
							width: 70px;
							margin-right: 10px;
							@include SP{
								content: url("/c/assets/image/kidslycare/flow_img_point.png");
								display: block;
								top: auto;
								width: 60px;
								margin: 0 0 10px;
								line-height: 1;
							}
						}
					}
					dd {
						font-size: 15px;
						line-height: 1.6;
						width: 700px;
						@include SP{
							width: 100%;
							font-size: 13px;
						}
					}
				}
				.Right {
					padding: 20px 0 0 30px;
					@include SP{
						width: 100%;
						text-align: center;
						padding: 0;
						margin: 32px 0 0;
					}
				}
			}
		}
	}
	.check {
		background: #f9f9f9;
		@include PC {
			padding: 25px 0;
		}
		@include SP {
			padding: 15px;
		}
	}
	.news__check {
		border: 2px solid #eaeaea;
		@include PC {
			display: table;
			margin: 0 auto;
			width: 940px;
			line-height: 1.6;
		}
		dt {
			background-color: #ff9999;
			text-align: center;
			color: #fff;
			padding: 12px 20px;
			line-height: 1;
			@include PC {
				display: table-cell;
			}
			@include SP {
				padding: 7px 15px;
			}
		}
		dd {
			background-color: #fff;
			padding: 10px 16px;
			@include PC {
				display: table-cell;
				span::after {
					content: " ";
				}
			}
			@include SP {
				padding: 10px 15px;
				span {
					display: block;
				}
			}
			a {
				color: #9b6135;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}




	/* --------------------------
	フッター
	* -------------------------- */

	.footer{
		padding: 30px 0 40px;
		background-color: #dbdbdb;
		@include SP{
			background-color: #956134;
			padding: 24px 24px 36px;
			box-sizing: border-box;
		}
		.inner {
			padding: 0;
			display: table;
			@include SP{
				display: block;
			}
			> * {
				display: table-cell;
				vertical-align: middle;
				@include SP{
					display: block;
				}
			}
		}
		a {
			color: #333333;
			font-size: 12px;
			line-height: 12px;
			@include SP{
				color: #fff;
			}
			&:hover {
				text-decoration: underline;
			}
			&.logo {
				display: block;
				margin-bottom: 15px;
				border: none;
				@include SP{
					margin-bottom: 6px;
				}
				img{
					width: 294px;
					@include SP{
						width: 265px;
					}
				}
			}
		}

		.links {
			text-align: right;
			letter-spacing: -4px;
			@include SP {
				margin: 10px 0 0;
				text-align: left;
			}

			li {
				display: inline-block;
				padding-right: 10px;
				margin-right: 10px;
				letter-spacing: .6px;
				border-right: 1px solid #333333;
				line-height: 12px;
				@include SP {
					border-color: #fff;
				}
			}
		}
	}

}
