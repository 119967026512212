@import "bootstrap/mixins";
@import "core/setting";
@import "core/utility";



.kidslyprint {


	/* COMMON
	======================================================================*/
  article {
    z-index: 1;
    .inner {
      @include clearfix();
      @include SP {
        padding: 0 25px;
      }
    }
  }
  img {
    max-width: 100%;
  }

  .pc {
    @include SP {
      display: none !important;
    }
  }
  .sp {
    @include PC {
      display: none !important;
    }
  }

  .title01 {
    color: #956134;
    @include font-size(2.4);
    @include line-height(3.6);
    letter-spacing: .1em;
    @include SP {
      text-align: center;
      @include font-size(1.8);
      @include line-height(2.7);
    }
  }
  p {
    @include font-size(1.4);
    @include line-height(2.1);
    letter-spacing: .05em;
    b {
      color: #FF9999;
      font-weight: 600;
    }
  }

  .button-box {
    margin: 64px 0 0;
    text-align: center;
    p {
      @include font-size(1.4);
      white-space: nowrap;
      margin: 0 0 8px;
      color: #333;
      @include SP {
        @include font-size(1.2);
      }
    }
  }

  .alert-box{
    background: #d9534f;
    padding: 5px;
    color: white;
    text-align: center;
  }

	/* VISUAL
	======================================================================*/
  .visual {
    min-height: 320px;
    padding: 46px 0 32px;
    position: relative;
    z-index: 1;
    background: url(../image/kidslyprint/visual-image.png) center top 30% / cover no-repeat;
    @include SP {
      background: url(../image/kidslyprint/visual-image-sp.png) center top 50% / cover no-repeat;
      min-height: 360px;
      padding: 40px 0 24px;
    }
    &__text-box {
      text-align: center;
      display: inline-block;
      color: #956134;
      z-index: 1;
      @include SP {
        width: 100%;
        padding: 0;
      }
    }
    &__title {
      @include font-size(3.2);
      letter-spacing: .1em;
      margin: 0 0 34px;
      line-height: 1.6;
      font-weight: bold;
      @include SP {
        @include font-size(1.6);
        margin: 0 0 20px;
      }
    }
    &__logo {
      margin: 0 0 40px;
      @include SP {
        margin: 0 0 20px;
      }
      img {
        height: 110px;
        @include SP {
          height: 100px;
        }
      }
    }
    &__apps {
      margin: 30px 0 0;
      width: 100%;
      display: table;
      border-radius: 6px;
      overflow: hidden;
      @include SP {
        border-radius: 3px;
        margin: 20px auto 0;
      }
      dt, dd {
        height: 80px;
        border: 3px solid #946034;
        vertical-align: middle;
        @include SP {
          border-width: 2px;
          height: 56px;
        }
      }
      dt {
        display: table-cell;
        background: #946034;
        color: #fff;
        @include font-size(1.7);
        padding: 0 0 0 16px;
        font-weight: 600;
        line-height: 1.17647059em;
        white-space: nowrap;
        @include SP {
          @include font-size(.9);
          padding: 0 0 0 8px;
        }
      }
      dd {
        background: #fff;
        display: table-cell;
        position: relative;
        padding: 0 0 0 36px;
        @include SP {
          display: flex;
          display: -ms-flexbox; /* IE10 */
          display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
          display: -webkit-flex; /* Safari6.1以降 */

          justify-content: center;
          -ms-flex-pack: center; /*IE10*/
          -webkit-box-pack: center; /* Android4.3以下、Safari3.1〜6.0 */
          -webkit-justify-content: center; /* Safari6.1以降 */
          padding: 0 0 0 16px;
        }
        &::before {
          position: absolute;
          left: 0;
          top: 0;
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 37px 0 37px 16px;
          border-color: transparent transparent transparent #946134;
          @include SP {
            border-width: 28px 0 28px 8px;
          }
        }
        a {
          margin: 0 16px 0 0;
          @include SP {
            margin: auto 8px auto 0;
          }
          &:hover {
            opacity: .75;
          }
          img {
            @include PC {
              height: 40px;
            }
            @include SP {
              max-height: 36px;
            }
          }
        }
      }
    }
  }





  .about {
    padding: 75px 0 58px;
    @include SP {
      padding: 46px 0 50px;
    }
    .inner {
      display: flex;
      display: -ms-flexbox; /* IE10 */
      display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
      display: -webkit-flex; /* Safari6.1以降 */

      justify-content: space-between;
      -ms-flex-pack: justify; /*IE10*/
      -webkit-box-pack: justify; /* Android4.3以下、Safari3.1〜6.0 */
      -webkit-justify-content: space-between; /* Safari6.1以降 */

      @include SP {
        flex-wrap: wrap;
        -ms-flex-wrap: wrap; /*IE10*/
        -webkit-flex-wrap: wrap; /* Safari6.1以降 */
      }
    }
    &__text-box {
      text-align: center;
      width: 44.489796%;
      margin: 0 auto;
      @include SP {
        width: 100%;
        order: 0;
      }
    }
    &__title {
      @include font-size(2.8);
      color: #946034;
      margin: 0 0 58px;
      @include SP {
        @include font-size(2);
        margin: 0 0 14px;
      }
    }
    &__text {
      @include font-size(1.4);
      line-height: 1.8em;
      @include SP {
        margin: 0 0 20px;
      }
    }

    &__image-box01 {
      width: 26.020408%;
      img {
        height: auto;
      }
      @include SP {
        width: 46.774194%;
        order: 1;
        img {
          width: auto;
        }
      }
    }
    &__image-box02 {
      width: 24.897959%;
      img {
        height: auto;
      }
      @include SP {
        width: 45.483871%;
        order: 2;
        img {
          height: auto;
        }
      }
    }
  }





  .feature {
    background: #fff6ca;
    padding: 78px 0;
    @include SP {
      padding: 50px 0;
    }
    &__title {
      @include font-size(2.8);
      color: #946034;
      text-align: center;
      @include font-size(2);
    }
    &__list {
      @include PC {
        margin: 78px 0 0;
        display: flex;
        display: -ms-flexbox; /* IE10 */
        display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
        display: -webkit-flex; /* Safari6.1以降 */

        justify-content: space-between;
        -ms-flex-pack: justify; /*IE10*/
        -webkit-box-pack: justify; /* Android4.3以下、Safari3.1〜6.0 */
        -webkit-justify-content: space-between; /* Safari6.1以降 */
      }
      &__box {
        position: relative;
        background: #fff;
        border-radius: 8px;
        @include PC {
          padding: 20px 30px 214px;
          width: 48%;
        }
        @include SP {
          margin: 24px 0 0;
          padding: 20px 16px 38px;

        }
        &:nth-of-type(1) {
          .feature__list__image-top {
            img {
              width: auto;
              height: 78px;
            }
          }
          .feature__list__image-bottom {
            img {
              width: auto;
              height: 140px;
            }
          }
        }
        &:nth-of-type(2) {
          .feature__list__image-top {
            img {
              width: auto;
              height: 98px;
            }
          }
          .feature__list__image-bottom {
            img {
              width: auto;
              height: 136px;
            }
          }
        }
        &:nth-of-type(3) {
          .feature__list__image-top {
            img {
              width: auto;
              height: 52px;
            }
          }
          .feature__list__image-bottom {
            img {
              width: auto;
              height: 128px;
            }
          }
        }
      }

      &__title {
        @include font-size(1.7);
        text-align: center;
        color: #946034;
        font-weight: bold;
        margin: 0 0 26px;
      }

      &__text {
        margin: 0 0 auto;
        @include font-size(1.4);
        line-height: 1.71428571em;
      }

      &__image-top {
        margin: 0 0 10px;
        width: 100%;
        text-align: center;
        @include PC {
          height: 116px;
          display: flex;
          display: -ms-flexbox; /* IE10 */
          display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
          display: -webkit-flex; /* Safari6.1以降 */
          align-items: center;
          -ms-flex-align: center; /*IE10*/
          -webkit-box-align: center; /* Android4.3以下、Safari3.1〜6.0 */
          -webkit-align-items: center; /* Safari6.1以降 */
          justify-content: center;
          -ms-flex-pack: center; /*IE10*/
          -webkit-box-pack: center; /* Android4.3以下、Safari3.1〜6.0 */
          -webkit-justify-content: center; /* Safari6.1以降 */
        }
        @include SP {
          margin: 0 0 16px;
        }
      }
      &__image-bottom {
        text-align: center;
        @include PC {
          position: absolute;
          bottom: 30px;
          left: 0;
          width: 100%;
        }
        @include SP {
          margin: 38px 0 0;
        }
      }
    }
  }





  .flow {
    padding: 80px 0 76px;
    @include SP {
      padding: 50px 0;
    }
    &__title {
      @include font-size(2.8);
      text-align: center;
      color: #946034;
      font-weight: bold;
      @include SP {
        @include font-size(2);
      }
    }
    &__list {
      position: relative;
      margin: 50px 0 0;
      display: flex;
      display: -ms-flexbox; /* IE10 */
      display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
      display: -webkit-flex; /* Safari6.1以降 */

      justify-content: space-between;
      -ms-flex-pack: justify; /*IE10*/
      -webkit-box-pack: justify; /* Android4.3以下、Safari3.1〜6.0 */
      -webkit-justify-content: space-between; /* Safari6.1以降 */
      @include SP {
        flex-wrap: wrap;
        -ms-flex-wrap: wrap; /*IE10*/
        -webkit-flex-wrap: wrap; /* Safari6.1以降 */
      }
      &::before {
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        display: block;
        width: 70%;
        height: 5px;
        background: #946034;
        z-index: -1;
        @include SP {
          top: 0;
          left: 19px;
          width: 4px;
          height: 72%;
        }
      }
      li {
        width: 27.755102%;
        padding: 0 40px;
        text-align: center;
        counter-increment: flow-list;
        position: relative;
        @include PC {
          &:nth-of-type(2) {
            width: 44.489796%;
          }
        }
        @include SP {
          width: 100%;
          padding: 18px 0 20px 50px;
        }
        &::before {
          display: inline-block;
          @include font-size(2.8);
          content: counter(flow-list);
          font-family: 'Ropa Sans', sans-serif;
          color: #946034;
          border: 5px solid #946034;
          border-radius: 56px;
          width: 56px;
          height: 56px;
          line-height: 44px;
          font-weight: bold;
          text-align: center;
          background: #fff;
          margin: 0 0 40px;
          @include SP {
            position: absolute;
            top: -16px;
            left: 0;
            border-width: 2px;
            width: 38px;
            height: 38px;
            @include font-size(1.8);
            line-height: 34px;
          }
        }
        &::after {
          @include SP {
            position: absolute;
            top: 0;
            left: 50px;
            right: 0;
            content: "";
            display: block;
            width: auto;
            height: 0;
            border-top: 2px dotted #613b21;
          }
        }
        h2 {
          @include font-size(1.5);
          line-height: 1.8em;
          @include SP {
            @include font-size(1.3);
            text-align: left;
            font-weight: normal;
          }
        }
        .image-box {
          display: flex;
          display: -ms-flexbox; /* IE10 */
          display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
          display: -webkit-flex; /* Safari6.1以降 */

          justify-content: center;
          -ms-flex-pack: center; /*IE10*/
          -webkit-box-pack: center; /* Android4.3以下、Safari3.1〜6.0 */
          -webkit-justify-content: center; /* Safari6.1以降 */
        }
        figure {
          margin: 26px 4px 0;
          figcaption {
            margin: 12px 0 0;
            @include font-size(1.2);
            white-space: nowrap;
            @include SP {
              @include font-size(.8);
            }
          }
        }
        &:nth-of-type(1) {
          @include SP {
            h2 {
              margin-top: -8px;
            }
            padding-top: 0;
            &::after {
              display: none;
            }
          }
        }
        &:nth-of-type(2) {
          @include SP {
            h2 {
              margin-top: -8px;
            }
          }
        }
        &:nth-of-type(3) {
          @include SP {
            figure {
              img {
                height: 86px;
              }
            }
          }
        }
      }
    }
  }





  .voice {
    background: #fff6ca;
    padding: 76px 0 42px;
    @include SP {
      padding: 50px 0;
    }
    &__title {
      @include font-size(2.8);
      text-align: center;
      color: #946034;
      font-weight: bold;
      @include SP {
        @include font-size(2);
      }
    }
    &__image {
      text-align: center;
      margin: 62px 0 0;
      @include SP {
        margin: 24px 0 0;
      }
      img {
        @include PC {
          width: auto;
          height: 272px;
        }
      }
    }
    &__list {
      margin: 50px 0 0;
      display: flex;
      display: -ms-flexbox; /* IE10 */
      display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
      display: -webkit-flex; /* Safari6.1以降 */

      justify-content: space-between;
      -ms-flex-pack: justify; /*IE10*/
      -webkit-box-pack: justify; /* Android4.3以下、Safari3.1〜6.0 */
      -webkit-justify-content: space-between; /* Safari6.1以降 */

      flex-wrap: wrap;
      -ms-flex-wrap: wrap; /*IE10*/
      -webkit-flex-wrap: wrap; /* Safari6.1以降 */

      @include SP {
        margin: 40px 0 -20px;
      }
      li {
        position: relative;
        padding: 28px;
        margin: 0 0 35px;
        border: 2px solid #946034;
        background: #fff;
        border-radius: 6px;
        @include font-size(1.4);
        z-index: 1;
        @include SP {
          padding: 12px;
          margin: 0 0 30px;
        }
        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 100%;
          right: 34px;
          z-index: -1;
          @include SP {
            right: 24px;
          }
        }
        &::before {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 12px 12px 0;
          border-color: transparent #946134 transparent transparent;
          @include SP {
            border-width: 0 14px 14px 0;
          }
        }
        &::after {
          margin-top: -5px;
          right: 36px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 12px 12px 0;
          border-color: transparent #fff transparent transparent;
          @include SP {
            border-width: 0 14px 14px 0;
            right: 26px;
          }
        }
        span {
          border-bottom: 2px solid #fecc0a;
        }
        img {
          vertical-align: middle;
          position: absolute;
          width: 46px;
          height: auto;
          bottom: -22px;
          right: -24px;
          @include SP {
            width: 28px;
            right: -14px;
            bottom: -14px;
          }
        }
        @include PC {
          &:nth-of-type(1),
          &:nth-of-type(2),
          &:nth-of-type(3),
          &:nth-of-type(5) {
            width: 30.816327%;
          }
          &:nth-of-type(4) {
            width: 65.510204%;
          }
          &:nth-of-type(6),
          &:nth-of-type(7) {
            width: 47.959184%;
          }
        }
      }
    }
  }





  .qa {
		@include PC {
			padding: 80px 0 76px;
		}
		@include SP {
			padding: 50px 0;
		}
    &__title {
      @include font-size(2.8);
      text-align: center;
      color: #946034;
      font-weight: bold;
      @include SP {
        @include font-size(2);
      }
    }
		h4 {
			text-align: center;
			color: #753c15;
			@include PC {
				margin-bottom: 40px;
				@include font-size(3.2);
				font-weight: normal;
			}
			@include SP {
				margin-bottom: 30px;
				@include font-size(1.8);
				font-weight: bold;
			}

			span {
				display: block;
				@include PC {
					 @include font-size(2);
				}
				@include SP {
					 @include font-size(1.4);
				}
			}
		}
		&__rows {
			margin: 64px 0 0;
			@include SP {
				margin: 20px 0 0;
			}
		}
		&__row {
			border-radius: 10px;
			overflow: hidden;
			background: #fff6cc;
			margin: 0 0 40px;
			@include SP{
				margin: 0 0 15px;
			}

			&__question {
				position: relative;
				color: $color-brown;
				@include PC {
					padding: 28px 25px 25px 65px;
					@include font-size(1.8);
				}
				@include SP {
					padding: 18px 10px 10px 40px;
					font-weight: bold;
					@include font-size(1.4);
				}

				&::after {
					content: "";
					position: absolute;
					display: block;
					background-image: url("../image/top/qa_icon_q@2x.png");
					background-repeat: no-repeat;
					@include PC {
						top: 20px;
						left: 20px;
						width: 34px;
						height: 34px;
						background-size: 34px;
					}
					@include SP {
						top: 14px;
						left: 10px;
						width: 22px;
						height: 22px;
						background-size: 22px;
					}
				}
			}

			&__answer {
				position: relative;
				color: $color-maintext;
				@include PC {
					padding: 28px 25px 25px 65px;
				}
				@include SP {
					padding: 20px 10px 15px 40px;
				}
				&::before{
					position: absolute;
					top: 0;
					left: 20px;
					right: 20px;
					content: "";
					width: auto;
					height: 6px;
					display: block;
					background: url("../image/common/border2@2x.png") repeat-x center top;
					background-size: auto 6px;
					@include SP {
						left: 10px;
						right: 10px;
					}
				}
				&::after {
					content: "";
					position: absolute;
					display: block;
					background-image: url("../image/top/qa_icon_a@2x.png");
					background-repeat: no-repeat;
					@include PC {
						top: 24px;
						left: 20px;
						width: 34px;
						height: 34px;
						background-size: 34px;
					}
					@include SP {
						top: 18px;
						left: 10px;
						width: 22px;
						height: 22px;
						background-size: 22px;
					}
				}
				a{
					color: #9b6236;
					text-decoration: underline;
					&:hover{
						text-decoration: none;
					}
				}
			}
		}
		&__button {
			text-align: center;
      margin: 80px 0 0;
			@include SP{
				margin: 20px 0 0;
			}
			a{
				min-width: 350px;
				@include SP{
					min-width: 190px;
				}
			}
		}
	}





  .specification {
    background: #fecc0a;
    padding: 80px 0;
    @include SP {
      padding: 50px 0;
    }
    &__title {
      @include font-size(2.8);
      text-align: center;
      color: #946034;
      font-weight: bold;
      @include SP {
        @include font-size(2);
      }
    }
    table {
      margin: 50px auto 0;
      max-width: 780px;
      border-collapse: collapse;
      border-radius: 10px;
      overflow: hidden;
      @include SP {
        display: block;
        width: 100%;
        margin: 30px auto;
      }
      tbody {
        @include SP {
          display: block;
          width: 100%;
        }
      }
      tr {
        @include PC {
          border-top: 3px solid #fecc0a;
        }
        @include SP {
          display: block;
          width: 100%;
        }
        &:first-child {
          border-top: 0;
        }
        th, td {
          @include font-size(1.4);
          line-height: 1.71428571em;
          padding: 30px;
          text-align: left;
          @include SP {
            display: block;
            width: 100%;
            padding: 14px;
          }
          small {
            @include font-size(1);
          }
        }
        th {
          color: #946034;
          background: #ffeeae;
          white-space: nowrap;
        }
        td {
          background: #fff;
          .detail-box {
            border-top: 1px solid #333;
            margin: 16px 0 0;
            padding: 18px 0 0;
            dl {
              dt {
                border: 1px solid #946034;
                color: #946034;
                padding: 4px;
                display: inline-block;
                line-height: 1em;
                margin: 20px 0 10px;
              }
              dd {
                display: block;
                ul {
                  li {
                    position: relative;
                    padding-left: 1em;
                    &::before {
                      display: block;
                      content: "";
                      width: 10px;
                      height: 10px;
                      border-radius: 10px;
                      background: #946034;
                      position: absolute;
                      top: .5em;
                      left: 0;
                    }
                  }
                }
              }
            }
            p {
              margin: 10px 0 0;
            }
          }
        }
      }
    }
  }





  .banner-box {
    padding: 80px 0;
    text-align: center;
    .banner {
      img {
        height: auto;
      }
    }
    .fb-page {
      display: none;
      margin: 30px auto 0;
    }
    @include SP {
      padding: 30px 0;
      .inner {
        padding: 0 10px;
      }
      .fb-page {
        display: block;
      }
    }
  }
}
