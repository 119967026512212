.posts{
  &__mainvisual{
    background-color: rgba($color-brown,0.3);
    background-image: url("../image/posts/posts_title_background.jpg");
    background-size: cover;
    background-position: center center;
    @include PC {
      height: 320px;
      padding: 70px 0;
    }
    @include SP {
      height: 180px;
      padding: 40px 0;
    }
    &__text{
      @include PC {
        @include retinaBg("../image/posts/posts_title_text@2x.png",288,55);
        margin: 40px auto 55px;
      }
      @include SP {
        @include retinaBg("../image/posts/posts_title_text_sp@2x.png",172,32);
        margin: 20px auto 20px;
      }
    }
    &__btn{
      margin: 0 auto;
      @include PC {
        width: 320px;
        height: 55px;
        line-height: 55px;
        font-size: 17px;
      }
      @include SP {
      }
    }
  }
}
