.footer-about{
  overflow: hidden;
  position: relative;
  padding: 50px 0px;
  width: 100%;
  background: #fff6ca;
}
.footer-about__content{
  @include PC {
    width: 620px;
  }
  @include SP {
    width: 100%;
  };
}
.footer-about__title{
  @include PC {
    margin-bottom: 30px;
    font-size: 24px;
  }
  @include SP {
    margin-bottom: 40px;
    text-align: center;
    font-size: 18px;
  }
  font-weight: bold;
  color: $color-brown;
}
.footer-about__text{
  @include PC {
    margin-bottom: 30px;
    font-size: 14px;
  }
  @include SP {
    margin: 310px 20px 40px;
    border-top: 1px solid $color-brown;
    padding-top: 20px;
    text-align: left;
    line-height: 26px;
    font-size: 15px;
  }
  margin-bottom: 40px;
  line-height: 28px;
  font-size: 14px;
  color: $color-maintext;
}
.footer-about__specs{
  @include SP {
    margin: 0 20px;
  }
}
.footer-about__spec{
  margin-bottom: 15px;
  color: $color-maintext;
  &:nth-last-of-type(1){
    margin-bottom: 0;
  }
  &:nth-of-type(1) .footer-about__spec__head{
    background-color: #f89496;
  }
  &:nth-of-type(2) .footer-about__spec__head{
    background-color: #8dceca;
  }
  &:nth-of-type(3) .footer-about__spec__head{
    background-color: #84b879;
  }
}
.footer-about__spec__head{
  @include PC {
    width: 140px;
    font-size: 14px;
    padding: 12px;
  }
  @include SP {
    width: 100%;
    font-size: 15px;
    padding: 6px;
  }
  vertical-align: top;
  display: inline-block;
  border-radius: 5px;
  text-align: center;
  color: #FFF;
}
.footer-about__spec__text{
  @include PC {
    width: 470px;
    padding-left: 20px;
  }
  @include SP {
    width: 100%;
    padding: 5px;
    font-size: 15px;
    line-height: 26px;
  }
  vertical-align: top;
  display: inline-block;
  font-size: 12px;
  span.large{
    @include SP {
      margin: 0 auto;
      text-align: center;
    }
    display: block;
    margin-top: 10px;
    font-size: 18px;
  }
  a{
    color: $color-brown;
    text-decoration: underline;
    &:hover{
      color: $color-brown+#333;
      text-decoration: none;
    }
  }
}


.footer-about__app{
  position: absolute;
  left: 50%;
  z-index: 0;
  font-size: 0;
  @include PC {
    bottom: 0px;
    margin-left: 257px;
  }
  @include SP {
    top: 149px;
    left: 50%;
    margin-left: -65px;
  }
}
.footer-about__frame{
  position: absolute;
  bottom:0;
  z-index: 1;
  @include PC {
    left: -87px;
    width: 300px;
  }
  @include SP {
    left: -54px;
    width: 186px;
  }
}
.footer-about__screen-mask{
  overflow: hidden;
  @include PC {
    width: 197px;
    height: 350px;
    margin-bottom: 22px;
  }
  @include SP {
    width: 125px;
    height: 218px;
    margin-bottom: 13px;
  }
}
.footer-about__screens{
  overflow: hidden;
  display: block;
  white-space: nowrap;
  @include PC {
    width: 197 * 3 + px;
  }
  @include SP {
    width: 125 * 3 + px;
  }
}
.footer-about__screen{
  display: inline-block;
  @include transition(0.8);
  @include PC {
    width: 197px;
    &.screen-out{
      margin-left: -197px;
    }
  }
  @include SP {
    width: 125px;
    &.screen-out{
      margin-left: -125px;
    }
  }
}
.footer-about__sns{
  margin-top: 30px;
  padding: 20px;
  width: 100%;
  border-radius: 4px;
  background-color: rgba(255,255,255,0.5);
  @include SP{
    margin: 30px auto 0;
    max-width: 340px;
  }
  dt{
    @include PC {
      display: inline-block;
      width: 200px;
    }
    @include SP {
      text-align: center;
      margin-bottom: 10px;
    }
    font-weight: bold;
    color: $color-brown;
  }
  dd{
    @include PC {
      display: inline-block;
    }
    @include SP {
      text-align: center;
    }
    >*{
      margin-left: 10px;
      vertical-align: middle;
    }
  }
}

.footer-about__conversion{
  background: #fff6ca;
  .inner{
    margin-top: -30px;
    padding: 40px 0 60px;
    border-top: 1px solid $color-brown;
    background: #fff6ca;
    position: relative;
    z-index: 2;
  }
  .btn--conversion{
    margin: 0 auto;
  }
}
