@mixin font-size($sizeValue: 1.3){
  font-size: ($sizeValue * 10) + px; // rem未対応ブラウザのfallback
  font-size: $sizeValue + rem;
}
@mixin line-height($sizeValue: 1.3){
  line-height: ($sizeValue * 10) + px; // rem未対応ブラウザのfallback
  line-height: $sizeValue + rem;
}
@mixin letter-spacing($sizeValue: 1.3){
  letter-spacing: ($sizeValue * 10) + px; // rem未対応ブラウザのfallback
  letter-spacing: $sizeValue + rem;
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -o-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

/*===== レスポンシブル =====*/
$TBPoint:    1024px;
$SPPoint:    768px;

@mixin PC { // SPPointより大きい時 = PC用
  @media only screen and (min-width: $SPPoint + 1) {
    @content;
  }
}

@mixin TB { // TB用のレイアウトを書きたい時
  @media only (min-width: $SPPoint) and (max-width: $TBPoint) {
    @content;
  }
}

@mixin SP { // SP用
  @media only screen and (max-width: $SPPoint) {
    @content;
  }
}


// Animation

@mixin transition($time:1.3, $element:all, $easing:ease-in-out, $delay:0) {
  transition: #{$element} #{$time}s #{$easing} #{$delay};
  transition-property: #{$element};
  transition-duration:#{$time}s;
  transition-timing-function:#{$easing};
}

// Retina Bg Image
@mixin retinaBg($path,$width,$height) {
  width: $width/2+px;
  height: $height/2+px;
  background-image: url($path);
  background-size: $width/2+px $height/2+px;
  background-repeat: no-repeat;
  background-position: center center;
}
