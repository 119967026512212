.cf:before, .cf:after {
  content: " ";
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  *zoom: 1;
}

@include SP {
  // SPで非表示
  .media-sp-hide{
    display: none !important;
  }
}
@include PC {
  // PCで非表示
  .media-pc-hide{
    display: none !important;
  }
}

/* margin, padding */
.ma {
  display: block;
  margin: 0 auto;
}
@for $i from 0 through 12 {
  .m#{$i * 5} {
    margin: $i * 5px !important;
  }
  .mt#{$i * 5} {
    margin-top: $i * 5px !important;
  }
  .mr#{$i * 5} {
    margin-right: $i * 5px !important;
  }
  .mb#{$i * 5} {
    margin-bottom: $i * 5px !important;
  }
  .ml#{$i * 5} {
    margin-left: $i * 5px !important;
  }
  .mt#{$i * -5} {
  margin-top: $i * -5px !important;
  }
  .mr#{$i * -5} {
    margin-right: $i * -5px !important;
  }
  .mb#{$i * -5} {
    margin-bottom: $i * -5px !important;
  }
  .ml#{$i * -5} {
    margin-left: $i * -5px !important;
  }
  .p#{$i * 5} {
    padding: $i * 5px !important;
  }
  .pt#{$i * 5} {
    padding-top: $i * 5px !important;
  }
  .pr#{$i * 5} {
    padding-right: $i * 5px !important;
  }
  .pb#{$i * 5} {
    padding-bottom: $i * 5px !important;
  }
  .pl#{$i * 5} {
    padding-left: $i * 5px !important;
  }
}
