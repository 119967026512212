@charset "UTF-8";
@import "bootstrap/mixins";
@import "core/setting";
@import "core/utility";

body.dl {
  font-family: 'ヒラギノ角ゴ ProN W3','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Hiragino Kaku Gothic ProN','Helvetica Neue','游ゴシック Medium', meiryo, sans-serif;
  background: #fff;
  background-size: contain;
  font-weight: 100;
  margin: 0 auto;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  display: block;
	min-width: 0;
	max-width: 767px;
	img {
		max-width: 100%;
	}
	header {
	  height: 48px;
		padding: 0;
	  border-bottom: 1px solid #956134;
	  text-align: center;
	  h1 {
	    margin: 0;
	    padding: 0;
		}
	  img {
	    height: 30px;
	    margin: 8px;
	    padding: 0;
		}
	}

	.mainwrapper {
	  margin: 0;
	}

	#mainimg {
	  width: 100%;
	  padding: 0;
	  margin: 0;
	  background: #fef6c7;
		img {
		  width: 100%;
		  margin: 0;
		}
	}



	.appbtn{
	  background: #fef6c7;
	  margin: 0;
	  padding: 20px 14px;
	  text-align: center;
		h1{
		  font-size: 1.5em;
		  line-height: 1.6;
		  font-weight: normal;
		  margin: 0 auto;
		}
		ul{
		  padding: 6px 0 25px 0;
		  margin: 0;
			li{
			  list-style: none;
			  display: block;
			  text-align: center;
				&:first-child{
				  margin-bottom: 10px;
				}
				img{
				  width: 60%;
				}
			}
		}
		p {
		  width: 100%;
		  line-height:1.6;
		  margin: 0 auto;
		  padding: 0;
		}
		&__banner {
			figure {
				display: block;
				img {
					width: 100%;
				}
			}
			p {
				margin: 6px 0 0;
				@include font-size(1);
				text-align: left;
				em {
					font-style: normal;
					font-weight: bold;
				}
			}
      dl {
        margin: 35px 0 0;
        dt {
          font-size: 20px;
          color: #67391a;
          font-weight: bold;
          border-bottom: 3px solid #efe39d;
          padding: 0 0 15px;
        }
        dd {
          margin: 15px 0 0;
          text-align: left;
          font-size: 12px;
          line-height: 1.8em;
          em {
            font-weight: bold;
            font-style: normal;
            span {
              color: #fd625f;
            }
          }
        }
      }
		}
	}

	.red {
	  color: #d20707;
	}


	footer {
	  margin: 0px;
	  padding: 10px 0 10px;
	  background-color: #956134;
	  font-size: 10px;
	  line-height: 16px;
		a.logo {
		  display: block;
		  margin-bottom: 15px;
		  border: none;
			img {
			  width: 60%;
		  }
		}
		.links {
		  text-align: left;
		  letter-spacing: -4px;
		  display: block;
			li {
			  display: inline-block;
			  padding-right: 10px;
			  margin-right: 10px;
			  letter-spacing: .6px;
			  border-right: 1px solid #fff;
			}
		}
		a {
		  color: #fff;
		  text-decoration: underline;
		}
	}

	.inner {
	  width: 95%;
	  margin: 0 auto;
	}






	.kidslyprint-content {
		.inner {
			width: 100%;
			padding: 0 25px;
		}
		/* VISUAL
		======================================================================*/
	  .visual {
	    &__apps {
	      width: 100%;
	      display: table;
	      overflow: hidden;
        border-radius: 3px;
        margin: 20px auto 0;
	      dt, dd {
					height: 56px;
	        border: 2px solid #946034;
	        vertical-align: middle;
	      }
	      dt {
	        display: table-cell;
	        background: #946034;
	        color: #fff;
          @include font-size(.9);
          padding: 0 0 0 8px;
	        font-weight: 600;
	        line-height: 1.17647059em;
	        white-space: nowrap;
	      }
	      dd {
	        background: #fff;
	        display: table-cell;
	        position: relative;
	        padding: 0 0 0 36px;
          display: flex;
          display: -ms-flexbox; /* IE10 */
          display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
          display: -webkit-flex; /* Safari6.1以降 */

          justify-content: center;
          -ms-flex-pack: center; /*IE10*/
          -webkit-box-pack: center; /* Android4.3以下、Safari3.1〜6.0 */
          -webkit-justify-content: center; /* Safari6.1以降 */
          padding: 0 0 0 16px;
	        &::before {
	          position: absolute;
	          left: 0;
	          top: 0;
	          content: "";
	          display: block;
	          width: 0;
	          height: 0;
	          border-style: solid;
            border-width: 28px 0 28px 8px;
	          border-color: transparent transparent transparent #946134;
	        }
	        a {
						margin: auto 8px auto 0;
	          &:hover {
	            opacity: .75;
	          }
	          img {
							max-height: 36px;
	          }
	        }
	      }
	    }
	  }


		.about {
			padding: 46px 0 50px;
	    .inner {
	      display: flex;
	      display: -ms-flexbox; /* IE10 */
	      display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
	      display: -webkit-flex; /* Safari6.1以降 */

	      justify-content: space-between;
	      -ms-flex-pack: justify; /*IE10*/
	      -webkit-box-pack: justify; /* Android4.3以下、Safari3.1〜6.0 */
	      -webkit-justify-content: space-between; /* Safari6.1以降 */

        flex-wrap: wrap;
        -ms-flex-wrap: wrap; /*IE10*/
        -webkit-flex-wrap: wrap; /* Safari6.1以降 */
	    }
	    &__text-box {
	      text-align: center;
        width: 100%;
        order: 0;
	      margin: 0 auto;
	    }
	    &__title {
	      color: #946034;
        @include font-size(2);
        margin: 0 0 14px;
	      @include SP {
	      }
	    }
	    &__text {
	      @include font-size(1.4);
	      line-height: 1.8em;
        margin: 0 0 20px;
	    }

	    &__image-box01 {
        width: 46.774194%;
        order: 1;
        img {
          width: 100%;
	        height: auto;
        }
	    }
	    &__image-box02 {
        width: 45.483871%;
        order: 2;
        img {
					width: 100%;
          height: auto;
        }
	    }
	  }





	  .feature {
	    background: #fff6ca;
      padding: 50px 0;
	    &__title {
				@include font-size(2);
	      color: #946034;
	      text-align: center;
	    }
	    &__list {
	      &__box {
	        position: relative;
	        background: #fff;
	        border-radius: 8px;
          margin: 24px 0 0;
          padding: 20px 16px 38px;
	        &:nth-of-type(1) {
	          .feature__list__image-top {
	            img {
	              width: auto;
	              height: 78px;
	            }
	          }
	          .feature__list__image-bottom {
	            img {
	              width: auto;
	              height: 140px;
	            }
	          }
	        }
	        &:nth-of-type(2) {
	          .feature__list__image-top {
	            img {
	              width: auto;
	              height: 98px;
	            }
	          }
	          .feature__list__image-bottom {
	            img {
	              width: auto;
	              height: 136px;
	            }
	          }
	        }
	        &:nth-of-type(3) {
	          .feature__list__image-top {
	            img {
	              width: auto;
	              height: 52px;
	            }
	          }
	          .feature__list__image-bottom {
	            img {
	              width: auto;
	              height: 128px;
	            }
	          }
	        }
	      }

	      &__title {
	        @include font-size(1.7);
	        text-align: center;
	        color: #946034;
	        font-weight: bold;
	        margin: 0 0 26px;
	      }

	      &__text {
	        margin: 0 0 auto;
	        @include font-size(1.4);
	        line-height: 1.71428571em;
	      }

	      &__image-top {
					margin: 0 0 16px;
	        width: 100%;
	        text-align: center;
	      }
	      &__image-bottom {
					margin: 38px 0 0;
	        text-align: center;
	      }
	    }
	  }





	  .flow {
			padding: 50px 0;
	    &__title {
				@include font-size(2);
	      text-align: center;
	      color: #946034;
	      font-weight: bold;
	    }
	    &__list {
	      position: relative;
	      margin: 50px 0 0;
	      display: flex;
	      display: -ms-flexbox; /* IE10 */
	      display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
	      display: -webkit-flex; /* Safari6.1以降 */

	      justify-content: space-between;
	      -ms-flex-pack: justify; /*IE10*/
	      -webkit-box-pack: justify; /* Android4.3以下、Safari3.1〜6.0 */
	      -webkit-justify-content: space-between; /* Safari6.1以降 */

        flex-wrap: wrap;
        -ms-flex-wrap: wrap; /*IE10*/
        -webkit-flex-wrap: wrap; /* Safari6.1以降 */
	      &::before {
	        position: absolute;
	        transform: translateX(-50%);
	        content: "";
	        display: block;
	        background: #946034;
	        z-index: -1;
          top: 0;
          left: 19px;
          width: 4px;
          height: 72%;
	      }
	      li {
					width: 100%;
					padding: 18px 0 20px 50px;
	        text-align: center;
	        counter-increment: flow-list;
	        position: relative;
	        &::before {
	          display: inline-block;
            @include font-size(1.8);
	          content: counter(flow-list);
	          font-family: 'Ropa Sans', sans-serif;
	          color: #946034;
	          border: 2px solid #946034;
	          border-radius: 56px;
            width: 38px;
            height: 38px;
            line-height: 34px;
	          font-weight: bold;
	          text-align: center;
	          background: #fff;
	          margin: 0 0 40px;
            position: absolute;
            top: -16px;
            left: 0;
	        }
	        &::after {
						position: absolute;
						top: 0;
						left: 50px;
						right: 0;
						content: "";
						display: block;
						width: auto;
						height: 0;
						border-top: 2px dotted #613b21;
	        }
	        h2 {
	          line-height: 1.8em;
            @include font-size(1.3);
            text-align: left;
            font-weight: normal;
	        }
	        .image-box {
	          display: flex;
	          display: -ms-flexbox; /* IE10 */
	          display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
	          display: -webkit-flex; /* Safari6.1以降 */

	          justify-content: center;
	          -ms-flex-pack: center; /*IE10*/
	          -webkit-box-pack: center; /* Android4.3以下、Safari3.1〜6.0 */
	          -webkit-justify-content: center; /* Safari6.1以降 */
	        }
	        figure {
	          margin: 26px 4px 0;
	          figcaption {
	            margin: 12px 0 0;
              @include font-size(.8);
	            white-space: nowrap;
	          }
	        }
	        &:nth-of-type(1) {
						h2 {
							margin-top: -8px;
						}
						padding-top: 0;
						&::after {
							display: none;
						}
	        }
	        &:nth-of-type(2) {
						h2 {
							margin-top: -8px;
						}
	        }
	        &:nth-of-type(3) {
						figure {
							img {
								height: 86px;
							}
						}
	        }
	      }
	    }
	  }





	  .voice {
	    background: #fff6ca;
      padding: 50px 0;
	    &__title {
				@include font-size(2);
	      text-align: center;
	      color: #946034;
	      font-weight: bold;
	    }
	    &__image {
	      text-align: center;
        margin: 24px 0 0;
				img {
					max-width: 100%;
					height: auto;
				}
	    }
	    &__list {
	      margin: 50px 0 0;
	      display: flex;
	      display: -ms-flexbox; /* IE10 */
	      display: -webkit-box; /* Android4.3以下、Safari3.1〜6.0 */
	      display: -webkit-flex; /* Safari6.1以降 */

	      justify-content: space-between;
	      -ms-flex-pack: justify; /*IE10*/
	      -webkit-box-pack: justify; /* Android4.3以下、Safari3.1〜6.0 */
	      -webkit-justify-content: space-between; /* Safari6.1以降 */

	      flex-wrap: wrap;
	      -ms-flex-wrap: wrap; /*IE10*/
	      -webkit-flex-wrap: wrap; /* Safari6.1以降 */
        margin: 40px 0 -20px;

	      li {
	        position: relative;
          padding: 12px;
          margin: 0 0 30px;
	        border: 2px solid #946034;
	        background: #fff;
	        border-radius: 6px;
	        @include font-size(1.4);
	        z-index: 1;
	        &::before,
	        &::after {
	          content: "";
	          display: block;
	          position: absolute;
	          top: 100%;
            right: 24px;
	          z-index: -1;
	        }
	        &::before {
	          width: 0;
	          height: 0;
	          border-style: solid;
            border-width: 0 14px 14px 0;
	          border-color: transparent #946134 transparent transparent;
	        }
	        &::after {
	          margin-top: -5px;
	          width: 0;
	          height: 0;
	          border-style: solid;
            border-width: 0 14px 14px 0;
            right: 26px;
	          border-color: transparent #fff transparent transparent;
	        }
	        span {
	          border-bottom: 2px solid #fecc0a;
	        }
	        img {
	          vertical-align: middle;
	          position: absolute;
	          height: auto;
            width: 28px;
            right: -14px;
            bottom: -14px;
	        }
	      }
	    }
	  }





	  .qa {
			padding: 50px 0;
	    &__title {
				@include font-size(2);
	      text-align: center;
	      color: #946034;
	      font-weight: bold;
	    }
			h4 {
				text-align: center;
				color: #753c15;
				margin-bottom: 30px;
				@include font-size(1.8);
				font-weight: bold;

				span {
					display: block;
					@include font-size(1.4);
				}
			}
			&__rows {
				margin: 20px 0 0;
			}
			&__row {
				border-radius: 10px;
				overflow: hidden;
				background: #fff6cc;
				margin: 0 0 15px;

				&__question {
					position: relative;
					color: $color-brown;
					padding: 18px 10px 10px 40px;
					font-weight: bold;
					@include font-size(1.4);

					&::after {
						content: "";
						position: absolute;
						display: block;
						background-image: url("../image/top/qa_icon_q@2x.png");
						background-repeat: no-repeat;
						top: 14px;
						left: 10px;
						width: 22px;
						height: 22px;
						background-size: 22px;
					}
				}

				&__answer {
					position: relative;
					color: $color-maintext;
					padding: 20px 10px 15px 40px;
					&::before{
						position: absolute;
						top: 0;
						left: 10px;
						right: 10px;
						content: "";
						width: auto;
						height: 6px;
						display: block;
						background: url("../image/common/border2@2x.png") repeat-x center top;
						background-size: auto 6px;
					}
					&::after {
						content: "";
						position: absolute;
						display: block;
						background-image: url("../image/top/qa_icon_a@2x.png");
						background-repeat: no-repeat;
						top: 18px;
						left: 10px;
						width: 22px;
						height: 22px;
						background-size: 22px;
					}
					a{
						color: #9b6236;
						text-decoration: underline;
						&:hover{
							text-decoration: none;
						}
					}
				}
			}
			&__button {
				text-align: center;
				margin: 20px 0 0;
				a{
					min-width: 190px;
				}
			}
		}





	  .specification {
	    background: #fecc0a;
      padding: 50px 0;
	    &__title {
				@include font-size(2);
	      text-align: center;
	      color: #946034;
	      font-weight: bold;
	    }
	    table {
	      max-width: 780px;
	      border-collapse: collapse;
	      border-radius: 10px;
	      overflow: hidden;
        display: block;
        width: 100%;
        margin: 30px auto;
	      tbody {
					display: block;
					width: 100%;
	      }
	      tr {
					display: block;
					width: 100%;
	        &:first-child {
	          border-top: 0;
	        }
	        th, td {
	          @include font-size(1.4);
	          line-height: 1.71428571em;
	          text-align: left;
            display: block;
            width: 100%;
            padding: 14px;
	          small {
	            @include font-size(1);
	          }
	        }
	        th {
	          color: #946034;
	          background: #ffeeae;
	          white-space: nowrap;
	        }
	        td {
	          background: #fff;
	          .detail-box {
	            border-top: 1px solid #333;
	            margin: 16px 0 0;
	            padding: 18px 0 0;
	            dl {
	              dt {
	                border: 1px solid #946034;
	                color: #946034;
	                padding: 4px;
	                display: inline-block;
	                line-height: 1em;
	                margin: 20px 0 10px;
	              }
	              dd {
	                display: block;
	                ul {
	                  li {
	                    position: relative;
	                    padding-left: 1em;
	                    &::before {
	                      display: block;
	                      content: "";
	                      width: 10px;
	                      height: 10px;
	                      border-radius: 10px;
	                      background: #946034;
	                      position: absolute;
	                      top: .5em;
	                      left: 0;
	                    }
	                  }
	                }
	              }
	            }
	            p {
	              margin: 10px 0 0;
	            }
	          }
	        }
	      }
	    }
	  }
	}
}
