.single__wrap{
  @include SP {
    width: 100%;
    padding: 30px 15px;
  }
  @include PC {
    width: 960px;
    margin: 60px auto;
  }
}
.single__title{
  margin-bottom: 16px;
  line-height: 30px;
  font-weight: bold;
  color: $color-brown;
  @include SP {
    font-size: 16px;
  }
  @include PC {
    font-size: 20px;
  }
}
.single__label{
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.single__label__date{
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
  font-size: 15px;
  color: #959595;
}
.single__label__category{
  display: inline-block;
  vertical-align: middle;
  height: 25px;
  width: 130px;
  border-radius: 2px;
  text-align: center;
  line-height: 25px;
  font-size: 12px;
  color: #FFF;
  &[key='information']{
    background-color: #ff9999;
    &::after{
      content:"お知らせ・リリース";
    }
  }
  &[key='media']{
    background-color: #8ed0cc;
    &::after{
      content:"メディア";
    }
  }
  &[key='support']{
    background-color: #92cb86;
    &::after{
      content:"サポート情報";
    }
  }
}
.single__body{
  margin: 40px 0;
  color: $color-maintext;
  font-family:"游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  @include PC {
    line-height: 1.9;
    font-size: 15px;
  }
  @include SP {
    line-height: 1.7;
    font-size: 14px;
  }
  h4{
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 16px;
    color: $color-brown;
  }
  a{
    font-size: 14px;
    text-decoration: underline;
    color: $color-brown;
    &:hover{
      color: $color-brown + #333;
      text-decoration: none;
    }
  }
  ul{
    margin: 10px 0;
    padding-left: 25px;
    li{
      list-style: disc;
    }
  }
  ol{
    margin: 10px 0;
    padding-left: 25px;
    li{
      list-style: decimal;
    }
  }
  a[target='_blank'], a.tab{
    &::after{
      padding-left: 5px;
      @extend .kf;
      @extend .kf-open_tab:before;
    }
  }
  a[href$='.pdf'], a.pdf,
  a[href$='.xls'], a[href$='.xlsx'], a.xls, a.xlsx,
  a[href$='.doc'], a[href$='.docx'], a.doc, a.docx,
  a[href$='.doc'], a[href$='.docx'], a.doc, a.docx,
  a[href$='.ppt'], a[href$='.pptx'], a.ppt, a.pptx{
    color: $color-maintext;
    text-decoration: underline;
    &::before{
      padding-right: 5px;
      @extend .fa;
    }
    &:hover{
      opacity: 0.8;
    }
  }
  a[href$='.pdf'], a.pdf{
    &::before{
      color: #de3636;
      @extend .fa-file-pdf-o:before;
    }
  }
  a[href$='.xls'], a[href$='.xlsx'], a.xls, a.xlsx{
    &::before{
      color: #3fac4d;
      @extend .fa-file-excel-o:before;
    }
  }
  a[href$='.doc'], a[href$='.docx'], a.doc, a.docx{
    &::before{
      color: #3172c8;
      @extend .fa-file-word-o:before;
    }
  }
  a[href$='.ppt'], a[href$='.pptx'], a.ppt, a.pptx{
    &::before{
      color: #db6b34;
      @extend .fa-file-powerpoint-o:before;
    }
  }
  .aligncenter{
    margin: 0 auto;
    display: block;
  }
  .left, .alignleft{float: left;margin-left: 0px;margin-right: 20px;}
  .right, .alignright{float: right;margin-right: 0px;margin-left: 20px;}

  .clear{clear: both;}
  .clearfix.divider{clear: both;margin-bottom: 25px;} // TinyMCEのclearfixプラグインで自動挿入される
  &::after {
    content: "";
    clear: both;
    display: block;
  }

  @include SP {
    img{
      width: 100%;
    }
  }
}
.single__footer{
  position: relative;
  text-align: center;
}
.single__footer__prev{
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: $color-brown;
  &:hover{
    text-decoration: underline;
  }
}
.single__footer__index{
  display: inline-block;
  height: 36px;
  width: 140px;
  border-radius: 18px;
  border: 1px solid $color-brown;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
  color: $color-brown;
  cursor: pointer;
  @include transition(0.1);
  &:hover{
    background-color: $color-brown;
    color: #FFF;
  }
}
.single__footer__next{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: $color-brown;
  &:hover{
    text-decoration: underline;
  }
}
